.layout {
  @include l-up {
    padding-top: 140px;
  }

  @include xxxl-up {
    padding-top: 197px;
  }

  &--embed {
    padding-top: 0px;
  }

  &--search {
    position: relative;
  }
}
