.u-container {
  max-width: calc(100% - 50px);
  margin: 0 25px;
  width: 100%;

  @include xxs-down {
    max-width: calc(100% - 30px);
    margin: 0 15px;
  }

  @include l-up {
    max-width: 85%;
    width: 100%;
    margin: 0 auto;
  }

  @include xl-up {
    width: 100%;
    //max-width: 900px;
    margin: 0 auto;
  }

  @include xxl-up {
    max-width: 1000px;
    //max-width: 1150px;
  }

  @include xxxl-up {
    max-width: 1100px;
  }

  @include xxxxl-up {
    width: 100%;
    max-width: 1500px;
  }
}

.u-mw-100-mobile {
  @include l-down {
    max-width: 100%!important;
    margin: 0!important;
  }
}

.u-relative {
  position: relative;
}

.u-container.u-container--xs {
  @include l-up {
    max-width: 660px;
    margin: 0 auto;
  }
}

.u-screen-reader-text {
  position: absolute !important;
  display: block;
  visibility: visible;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0px, 0px, 0px, 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

.u-fadereveal, [data-fadereveal] {
  visibility: hidden;
}

.u-hidden {
  opacity: 0!important;
  visibility: hidden!important;
}


.u-hidden-desktop {
  @include l-up {
    display: none!important;
  }
}

.u-hidden-mobile {
  @include l-down {
    display: none!important;
  }
}

.u-no-margin {
  margin: 0!important;
}

.u-no-pb-desktop {
  @include l-up {
    padding-bottom: 0!important;
  }
}

.u-no-pt-desktop {
  @include l-up {
    padding-top: 0!important;
  }
}

.u-no-pb-mobile {
  @include l-down {
    padding-bottom: 0!important;
  }
}

.u-no-pt-mobile {
  @include l-down {
    padding-top: 0!important;
  }
}

.u-no-mb-mobile {
  @include l-down {
    margin-bottom: 0!important;
  }
}

.u-mb-tablet {
  @include m-up {
    margin-bottom: 20px!important;
  }

  @include l-up {
    margin-bottom: 0px!important;
  }
}

.u-mt-mobile {
  @include l-down {
    margin-top: 30px!important;
  }
}


.u-mt-desktop {
  @include l-up {
    margin-top: 50px!important;
  }

  &--small {
    @include l-up {
      margin-top: 30px!important;
    }
  }
}

.u-mb-desktop {
  @include l-up {
    margin-bottom: 50px!important;
  }

  &--small {
    @include l-up {
      margin-top: 30px!important;
    }
  }
}


.u-cover-title {
  @include AdelleSansExtraBold();
  font-size: 30px;
  letter-spacing: 0.3px;
  line-height: 30px;
  color: #FFFFFF;

  @include l-up {
    font-size: 42px;
    line-height: 50px;
  }

  @include xxxl-up {
    font-size: 50px;
    line-height: 58px;
  }
}

.u-cover-serie {
  @include AdelleSansSemiBold();
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;

  @include l-up {
    font-size: 15px;
    margin-bottom: 15px;
  }

  a {
    border-bottom: 1px solid #FFF;
    color: #FFF;
    transition: all .2s $easeOutQuad;
    text-decoration: none;

    @include l-up {
      border-bottom: 2px solid #FFF;
    }

    &:hover {
      color: #FF2F00;
      border-color: #FF2F00;
    }
  }
}

.u-cover-authors {
  @include AdelleSansSemiBold();
  color: #FFFFFF;
  font-size: 14px;
  line-height: 24px;
  position: relative;

  @include l-up {
    font-size: 15px;
    line-height: 25px;
  }

  @include xxxl-up {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    text-decoration: none !important;
    border-bottom: 1px solid #FFF;
    color: #FFF !important;
    transition: all .2s $easeOutQuad;

    @include l-up {
      border-bottom: 2px solid #FFF;
    }

    &:hover {
      color: #FF2F00 !important;
      border-color: #FF2F00;
    }
  }
}


.u-cover-separator {
  display: block;
  background: #FF2F00;
  width: 113px;
  height: 2px;

  @include l-up {
    width: 164px;
  }

  &--large {
    @include l-up {
      width: 260px;
    }
  }
}

.u-cover-buttons {
  display: flex;
  margin-top: 30px;

  .btn {
    &--play {
      margin-right: 15px;

      @include l-up {
        width: 280px;
        height: 50px;

        .btn__icon-container {
          &:before {
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%)
          }
        }
      }

      .btn__label,
      .btn__duration {
        @include l-up {
          font-size: 18px;
        }
      }
    }

    &--subscribe {
      width: 150px;
      border-color: #949494;

      @include m-up {
        display: none;
      }
    }

    &--playlist {
      margin-right: 15px;

      @include l-up {
        width: 120px;
        margin-right: 0px;
      }

      &:hover {
        background: #FFFFFF;
        border-color: #FFFFFF;

        .btn__icon-container {
          &:before {
            background-image: url('../static/svg/utils/add-to-playlist-active.svg');
          }
        }
      }
    }

    &--share {
      @include l-up {
        display: none;
      }
    }
  }
}

.u-cover-chapo {
  @include AdelleSansSemiBold();
  font-size: 15px;
  line-height: 26px;
  text-shadow: 0 1px 11px rgba(0,0,0,0.50);
  color: #FFFFFF;

  @include xxs-up {
    font-size: 17px;
    line-height: 28px;
  }

  @include l-up {
    font-size: 16px;
    line-height: 28px;
  }

  @include xxxl-up {
    font-size: 20px;
    line-height: 32px;
  }
}


.is-online {
  position: relative;
  background: #FF2F00;
  font-family: AdelleSansBold;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  border-radius: 3px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 14px;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    margin-right: 10px;
    border-radius: 50%;
  }
}

.u-hide-mobile {
  @include l-down {
    display: none!important;
  }
}

.u-full-width {
  width: 100%;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-space-evenly {
  justify-content: space-evenly;
}

.u-flex-container {
  position: relative;
  display: flex;

  @include l-up {
    max-width: calc(100% - 60px);
    margin: 30px auto;
  }

  &--desktop {
    @include l-down {
      display: block;
    }
  }
}

.u-flex-column-mobile {
  @include l-down {
    flex-direction: column;
  }
}

.u-bold {
  font-family: AdelleSansBold;
}
