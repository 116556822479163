.sharings-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;

  @include l-up {
    width: 85%;
  }

  @include xl-up {
    width: 900px;
  }

  @include xxl-up {
    width: 1000px;
  }

  @include xxxl-up {
    width: 1100px;
  }

  @include xxxxl-up {
    width: 1500px;
  }

  .sharings {
    position: absolute;
    background: #FF2F00;
    border-radius: 36px;
    padding: 20px 15px;
    overflow: hidden;
    display: none;
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.20);

    @include l-up {
      top: 100px;
      left: calc(85% + 30px);
    }

    @include xl-up {
      top: 300px;
      padding: 30px 20px;
      left: calc(900px + 30px);
    }

    @include xxl-up {
      left: calc(1000px + 30px);
    }

    @include xxxl-up {
      left: calc(1100px + 30px);
    }

    @include xxxxl-up {
      left: calc(1500px + 30px);
    }

    &.is-fixed {
      position: fixed;
      right: auto;
    }

    &.is-expanded {
      .sharings__list__item {
        &:not(:last-child) {
          padding-bottom: 25px;
          border-bottom: solid 1px white;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__item {
        width: 100%;
        text-align: center;
        transition: all 0.2s $easeOutQuad;
        cursor: pointer;

        &:hover {
          opacity: 0.7
        }

        &:not(:first-child) {
          padding-top: 25px;
        }

        &:not(:last-child) {
          padding-bottom: 25px;
          border-bottom: solid 1px white;
        }

        &.to-hide {
          opacity: 0;
          visibility: hidden;
          height: 0;
          padding: 0;
        }
      }

      .cross {
        transition: transform 0.2s $easeOutQuad;
        &.is-active {
          transform: rotate(45deg)
        }
      }
    }

    &__link {
      &.twitter {
        width: 17px;
      }
      &.facebook {
        width: 10px;
      }
    }
  }
}
