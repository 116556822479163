.subscribe-container {
  display: none;

  @include m-up {
    @include flexCentered();
    position: relative;
    border: solid 1px rgba(246, 247, 249, 0.2);
    border-top: 0;
    border-radius: 5px;
    padding: 40px 5px;
    margin: 50px auto 0px;
    max-width: 80%;
  }

  &--no-border {
    @include m-up {
      border: none;
      padding: 5px 0px;
      justify-content: space-between;
    }
  }

  @include l-up {
    max-width: 100%;
  }

  .border-top {
    position: absolute;
    height: 0px;
    width: calc(50% - 220px/2 - 1px);
    top: 0;
    border-top: solid 1px rgba(246,247,249, 0.2);

    &--right {
      left: 2px;
    }

    &--left {
      right: 2px;
    }
  }


  p {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    height: 35px;
    text-transform: uppercase;
    @include AdelleSansBold();
    font-size: 15px;
    color: #FFFFFF;
    display: flex;
    align-items:center;
    justify-content: center;
  }

  .btn {
    &:not(:last-child) {
      margin-right: 10px;

      @include xxl-up {
        margin-right: 20px;
      }
    }
  }
}
