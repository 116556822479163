.main-footer {
  background-color: $color-white;
  margin-top: 35px;
  padding-top: 10px;
  padding-bottom: 65px;

  @include l-up {
    display: block;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 83px;
  }

  &__top,
  &__bottom {

    @include m-up {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__links {
    @include m-up {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__link,
  &__lang {
    color: $color-black-2;
    transition: all 0.3s $easeOutExpo;

    &:hover {
      color: #FF2F00;
    }
  }

  &__top {
    padding: 30px 0px 20px;

    border-bottom: solid 1px $color-grey;

    @include l-down {
      padding-bottom: 15px;
    }
  }

  &__store {
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {      
      font-size: 16px;

      @include m-down{
        font-size: 12px;
      }
      
      &.headline__title {
        margin-top: 25px;
        padding: 0;

        @include AdelleSansBold();
        font-size: 24px;

        @include m-down{
          font-size: 15px;
        }
      }
    }

    a {
      text-decoration: none !important;
    }

    .specific-store {
      @include m-down{
        width: 45%;
      }
    }

  }

  &__bottom {
    padding: 35px 0px;

    .main-footer__links {
      .main-footer__link {
        @include AdelleSansSemiBold();
        line-height: 26px;
        margin-bottom: 10px;
        text-decoration: none;
        font-size: 14px;
        display: block;
        text-align: center;

        @include m-up {
          text-align: left;
          font-size: 12px;
          margin-right: 15px;
          margin-bottom: 0;
        }

        @include l-up {
          font-size: 14px;
          margin-right: 20px;
        }

        @include xl-up {
          font-size: 16px;
        }

        &:last-child {
          margin-right: 0px;
        }

        .icon-arte-radio {
          width: 85px;
          height: auto;

          @include l-up {
            margin-bottom: 0;
            width: 90px;
          }
        }

      }
    }

    .main-footer__langs {
      text-align: center;
      @include m-up {
        text-align: left;
      }

      @include l-up {
        align-self: flex-start;
      }
      .main-footer__lang {
        @include AdelleSansSemiBold();
        line-height: 26px;
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          &:before {
            color: $color-black-2;
          }
        }

        &:before {
          content: ' - ';
        }

        &:first-child {
          &:before {
            content: '';
          }
        }

        @include m-up {
          font-size: 12px;
        }

        @include l-up {
          font-size: 14px;
        }

        @include xl-up {
          font-size: 16px
        }
      }
    }
  } 
}
