.player-extend {
  background-color: #F6F7F9;
  position: fixed;
  z-index: 301;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: none;

  @include l-up {
    height: calc(100vh - 83px);
    z-index: 200;
  }

  &__close {
    @include l-down {
      display: none;
    }

    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 16px;
    height: 16px;
    z-index: 3;
  }

  &.extended {
    display: block;
  }

  .player-extend__scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-top: 30px;

    @include l-up {
      height: calc(100% - 210px);
      padding-bottom: 50px;
    }
  }

  .player-extend__current-podcast {
    @include AdelleSansSemiBold();
    margin-bottom: 35px;

    @include l-up {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #171717;
      z-index: 21;
      max-width: calc(100% - 15px);
      margin-bottom: 0;
      padding: 25px 0px;
    }

    .current-podcast__wrapper {
      @include l-up {
        display: flex;
      }
    }

    .current-podcast__artwork {
      @include l-down {
        display: none;
      }

      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 24%;
      width: 24%;
      margin-right: 30px;


      &:before {
        content: "";
        display: block;
        height: 0;
        padding-bottom: 77.50%;
      }

      img {
        @include object-fit();

        @include l-up {
          border-radius: 5px;
        }
      }

      .is-online {
        position: absolute;
        top: 18px;
        left: 18px;

        @include l-up {
          top: 18px;
          left: 18px;
        }

        @include xxxl-up {
          top: 10px;
          left: 10px;
        }
      }
    }

    .current-podcast__infos {
      @include l-up {
        padding-top: 15px;
      }

      .current-podcast__serie {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;

        @include l-up {
          font-size: 16px;
        }

        .separator {
          @include l-up {
            color: #FFF;
          }
        }

        a {
          color: #FF2F00;
          margin-right: 5px;
        }

        .serie__episode {
          margin-left: 5px;

          @include l-up {
            color: #FFF;
          }
        }
      }

      .current-podcast__title {
        @include AdelleSansExtraBold();
        font-size: 24px;
        color: #353535;
        letter-spacing: 0.3px;
        line-height: 30px;
        margin: 15px 0px;

        @include l-up {
          font-size: 24px;
          color: #FFF;
        }

        @include xxxl-up {
          font-size: 26px;
        }
      }

      .current-podcast__description {
        font-size: 17px;
        color: #353535;
        letter-spacing: 0;
        line-height: 28px;
        margin-bottom: 5px;

        @include l-up {
          max-width: 500px;
          color: #FFF;
          margin-bottom: 20px
        }
      }

      .current-podcast__link {
        color: #FF2F00;

        @include l-up {
          text-decoration: none;
          display: flex;
          &:before {
            content: '';
            background-image: url('../static/svg/utils/arrow-oranger.svg');
            background-size: 16px 10px;
            width: 16px;
            height: 10px;
            margin-top: 2px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .player-extend__playlist {
    .playlist__label {
      @include AdelleSansBold();
      font-size: 20px;
      color: #FF2F00;
      line-height: 20px;
      border-top: 1px solid #DADADA;
      border-bottom: 1px solid #DADADA;
      padding: 15px 0px;

      @include l-up {
        border-top: none;
        font-size: 24px;
        line-height: 35px;
        padding: 2px 0px;
        padding-bottom: 30px;
      }

      @include xxxl-up {
        padding: 20px 0px;
      }
    }

    .podcast-list__inner {
      @include l-up {
        padding: 25px 0px;
      }

      @include xxxl-up {
        padding: 40px 0px
      }
    }
  }
}
