.selected-playlist {
  position: relative;

  .selected-playlist__header {
    height: 60vh;
    display: flex;
    align-items: center;
    text-align: center;

    @include l-up {
      height: 70vh;
      position: relative;
    }
  }

  .selected-playlist__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28,28,28,0.00) 0%, #1C1C1C 100%);
      height: 70%;
      width: 100%;

      @include l-up {
        top: 100%;
        height: 70%;
        transform: translateY(-100%);
      }
    }

    .layer-dark {
      display: none;
      @include l-down {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: #1c1c1c;
        z-index: 0;
      }
    }
  }

  .selected-playlist__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    @include object-fit();

    @include l-down {
      height: 70%;
    }

  }

  .selected-playlist__content {
    position: relative;
    // flex: 1;

    @include l-up {
      margin-top: -50px;
    }
  }

  .selected-playlist__label {
    @include AdelleSansBold();
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 0.3px;
    line-height: 30px;
    text-shadow: 0 0 11px rgba(0,0,0,0.50);
    text-transform: uppercase;

    @include l-up {
      font-size: 17px;
    }

    @include xxxl-up {
      font-size: 19px;
      letter-spacing: 1.27px;
    }
  }

  .selected-playlist__title {
    @include AdelleSansExtraBold();
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin-top: 10px;
    text-shadow: 0 0 11px rgba(0,0,0,0.50);

    @include l-up {
      font-size: 42px;
      line-height: 50px;
      letter-spacing: 0.63px;
      margin: 20px 0;
    }

    @include xxxl-up {
      font-size: 50px;
      line-height: 58px;
      margin: 40px 0;
    }
  }

  .selected-playlist__description {
    @include l-down {
      display: none;
    }

    font-size: 20px;
    line-height: 32px;
    @include AdelleSansSemiBold();
    color: #F6F7F9;
    text-shadow: 0 1px 11px rgba(0,0,0,0.50);

    @include l-up {
      max-width: 95%;
      margin: auto;
    }

    @include xxxl-up {
      max-width: 100%;
      font-size: 22px;
      line-height: 34px;
    }
  }

  .selected-playlist__btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include l-up {
      margin-top: 25px;
    }

    @include xxxl-up {
      margin-top: 45px;
    }
  }

  .selected-playlist__podcasts {
    position: relative;

    @include l-up {
      margin-top: -50px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        max-width: calc(100% - 50px);
        width: 100%;
        height: 100px;
        background-color:#F6F7F9;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }

    @include xxl-up {
      &:before {
        max-width: 1290px;
      }
    }

    @include xxxxl-up {
      &:before {
        max-width: 1630px;
      }
    }
  }
}
