.quote {
  min-height: 60vh;
  height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow:hidden;

  @include l-up {
    min-height: 100vh;
    height: 100vh;
    margin: -200px 0px;
    position: relative;
    z-index: -1;
  }

  .quote__cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    @include l-down {
      background: #1C1C1C;
    }

    &:before {
      content: "";
      background-image: linear-gradient(0deg, rgba(246,247,249,0.00) 0%, #F6F7F9 100%);
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: 40%;
      z-index: 1;

      @include l-down {
        background-image: linear-gradient(0deg, rgba(#1C1C1C,0.00) 0%, #1C1C1C 100%);
      }
    }

    &:after {
      content: "";
      background-image: linear-gradient(-180deg, rgba(246,247,249,0.00) 0%, #F6F7F9 100%);
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 20%;
      z-index: 1;

      @include l-down {
        background-image: linear-gradient(-180deg, rgba(#1C1C1C,0.00) 0%, #1C1C1C 100%);
      }
    }

    img {
      opacity: 0.2;
      @include object-fit();
    }
  }

  .quote__inner {
    position: relative;
    text-align: center;
    @include AdelleSansBold();
    z-index: 1;
    width: 100%;

    @include l-up {
      width: 65%;
    }

  }

  .quote__label {
    position: relative;
    font-size: 17px;
    color: #F6F7F9;
    line-height: 24px;

    @include l-up {
      color: #353535;
      font-size: 18px;
      line-height: 24px;
    }

    &:after {
      content: "";
      display: block;
      height: 2px;
      margin: 0 auto;
      background-color: #949494;
      margin-top: 25px;
      width: 80%;

      @include l-up {
        width: 350px;
        background-color: #353535;
      }
    }
  }

  .quote__title {
    font-size: 36px;
    line-height: 44px;
    color: #F6F7F9;
    letter-spacing: 0.3px;
    margin-top: 30px;

    &--small {
      font-size: 24px;
      line-height: 30px;

      @include l-up {
        font-size: 40px;
        line-height: 46px;
      }
    }

    @include l-down {
      text-shadow: 0 0 24px rgba(0,0,0,0.63);
    }

    @include l-up {
      font-size: 56px;
      color: #FF2F00;
      letter-spacing: 0.47px;
      line-height: 60px;
      margin-top: 50px;
    }
  }

  .quote__button {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }
}
