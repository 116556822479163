.podcast-full-width {
  position: relative;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 0px;
  background-color: #1C1C1C;
  overflow: hidden;

  @include l-up {
    height: 65vh;
    padding-top: 75px;
    padding-bottom: 50px;
  }

  @include xxxl-up {
    height: 70vh;
    padding-bottom: 100px;
  }

  &--expanded {
    height: 100vh;
    padding: 0px;
    @include flexCentered();

    @include m-up {
      height: calc(65vh - 85px);
    }

    @include l-up {
      display: block;
      padding-top: 175px;
      height: calc(100vh - 85px);
      min-height: 750px;
    }

    .podcast__title {
      font-size: 28px;
      line-height: 32px;

      @include l-up {
        font-size: 50px;
        line-height: 50px;
      }
    }

    .podcast__buttons {
      justify-content: center;

      @include m-up {
        justify-content: flex-start;
      }
    }

    .podcast__image-container {
      filter: blur(50px);

      @include m-up {
        filter: none;
      }
    }

    .podcast__content {
      .podcast__separator {
        margin: 20px auto;

        @include m-up {
          margin: 20px 0;
        }
      }
    }

    .podcast__information {
      width: calc(100% - 80px);

      @include m-up {
        width: 100%;
      }

      .podcast__serie {
        @include m-down {
          font-size: 12px;
        }
      }
    }
  }

  .podcast__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .podcast__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;


    &:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);


      @include l-up {
        top: 100%;
        height: 100%;
        background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%);
      }
    }

    .podcast__image-wrapper {
      height: 80%;
      overflow:hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include l-up {
        height: 100%;
      }
    }
  }

  .podcast__image {
    opacity: 0.6;
    @include object-fit();

    @include l-up {
      height: 100%;
    }
  }

  .podcast__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &--wide {
      display: block;
      @include s-up {
        max-width: 60%;
      }

      @include l-up {
        max-width: 100%;
        margin-left: 100px;
      }
    }
  }

  .podcast__illustration {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 110px);
    margin: auto;

    @include m-up {
      margin: 0;
      width: calc(45% - 50px);
    }
  }

  .podcast__information {
    text-align: center;
    margin: 20px auto 0;

    @include m-up {
      text-align: left;
      margin-left: 50px;
      max-width: 40%;
      align-self: flex-start;
    }

    .podcast__chapo {
      margin-top: 5px;
    }
  }

  .podcast__serie {
    @include AdelleSansSemiBold();
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
    margin-bottom: 10px;

    @include l-up {
      font-size: 15px;
      margin-bottom: 15px;
    }

    a {
      text-decoration: underline;
      color: #FFFFFF;
      margin-right: 5px;
    }

    .serie__episode {
      margin-left: 5px;
    }
  }

  .podcast__title {
    @include AdelleSansExtraBold();
    font-size: 30px;
    color: #F6F7F9;
    letter-spacing: 0.3px;
    line-height: 30px;

    @include l-up {
      font-size: 42px;
      line-height: 50px;
      text-shadow: 0 1px 11px rgba(0,0,0,0.50);
      max-width: 500px;
    }

    @include xxxl-up {
      font-size: 50px;
      line-height: 58px;
      max-width: 670px;
    }

    &--small {
      font-size: 26px;

      @include l-up {
        font-size: 40px;
        line-height: 47px;
      }
    }
  }

  .podcast__chapo {
    @include AdelleSansSemiBold();
    font-size: 14px;
    color: #F6F7F9;
    line-height: 24px;
    text-shadow: 0 1px 11px rgba(0,0,0,0.50);
    margin-top: 5px;

    @include l-up {
      font-size: 15px;
      margin-top: 10px;
    }

    @include xxxl-up {
      font-size: 18px;
      margin-top: 15px;
    }
  }

  .podcast__description {
    @include AdelleSansSemiBold();
    font-size: 15px;
    color: #F6F7F9;
    line-height: 26px;

    @include xxs-up {
      font-size: 17px;
      line-height: 28px;
    }

    @include l-up {
      font-size: 16px;
      line-height: 28px;
      text-shadow: 0 1px 11px rgba(0,0,0,0.50);
      max-width: 450px;
    }

    @include xxxl-up {
      font-size: 22px;
      line-height: 34px;
      max-width: 670px;
    }

    &--small {
      font-size: 15px;
      line-height: 24px;

      @include l-up {
        font-size: 18px;
      }

      @include xxxl-up {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .podcast__separator {
    display: block;
    background: #FF2F00;
    width: 113px;
    height: 2px;
    margin: 20px 0px 20px;

    @include l-up {
      width: 260px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    @include xxxl-up {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .podcast__buttons {
    margin-top: 30px;
    display: flex;

    .btn {

      &--play {
        margin-right: 15px;

        @include l-up {
          width: 280px;
          height: 50px;

          .btn__icon-container {
            &:before {
              position: absolute;
              left: 25px;
              top: 50%;
              transform: translateY(-50%)
            }
          }
        }
      }


      &--playlist {

        margin-right: 15px;

        @include l-up {
          width: 120px;
          margin-right: 0px;
        }
      }

      &--share {
        @include l-up {
          display: none;
        }
      }


      &__label,
      &__duration {
        @include l-up {
          font-size: 18px;
        }
      }
    }
  }
}
