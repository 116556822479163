.player-btn {
  width: 30px;
  height: 40px;
  position: relative;

  @include l-up {
    height: 60px;
    width: 40px;
  }

  &:before, &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 20px;
    height: 19px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: all 0.7s $easeOutQuart;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include l-up {
      height: 30px;
      width: 20px;
    }
  }

  &:after {
    opacity: 0;

  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  &.player-btn--play {
    margin: 0 15px;

    @include l-up {
      margin: 0 25px;
    }

    &:before, &:after {
      width: 16px;

      @include l-up {
        width: 16px;
      }
    }

    &:before {
      background-image: url("../static/svg/utils/play.svg");
    }

    &:after {
      background-image: url("../static/svg/utils/play-active.svg");
    }
  }

  &.player-btn--play.playing {

    &:before {
      background-image: url("../static/svg/utils/pause.svg");
    }

    &:after {
      background-image: url("../static/svg/utils/pause-active.svg");
    }
  }

  &.player-btn--prev {
    &:before {
      background-image: url("../static/svg/utils/previous.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/previous-active.svg")
    }
  }

  &.player-btn--next {
    &:before {
      background-image: url("../static/svg/utils/next.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/next-active.svg")
    }
  }

  &.player-btn--random {

    &:before {
      background-image: url("../static/svg/utils/random.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/random-active.svg")
    }
  }

  &.player-btn--share {

    &:before, &:after {
      width: 16px;
    }

    &:before {
      background-image: url("../static/svg/utils/share.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/share-active.svg")
    }
  }

  &.player-btn--extend {
    &:before {
      background-image: url("../static/svg/utils/hamburger.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/hamburger-active.svg")
    }
  }

  &.player-btn--extend.extended {
    &:before, &:after {
      width: 12px;
    }

    &:before {
      background-image: url("../static/svg/utils/cross-white.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/cross.svg")
    }
  }

  &.player-btn--add-playlist {
    &:before {
      background-image: url("../static/svg/utils/add-to-playlist-white.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/add-to-playlist-active.svg")
    }
  }

  &.player-btn--back {
    &:before {
      background-image: url("../static/svg/utils/back.svg")
    }

    &:after {
      background-image: url("../static/svg/utils/back-active.svg")
    }
  }





  &.player-btn--xl {
    &:before, &:after {
      width: 25px;
      height: 30px;
    }

    &.player-btn--play {
      margin: 0 50px;
    }
  }
}
