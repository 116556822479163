.filters {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;

  &__item {
    position: relative;
    width: 100%;
    text-align: center;
  }
}

.filters-close {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 60px;
  margin:auto;

  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background-image: url("../static/svg/utils/close-icon-orange.svg");
    background-size: cover;
  }
}

// ON filters OPEN

.filters-open {
  .filters {
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:before {
      position: absolute;
      top: 20px;
      font-size: 18px;
    }

    &__item {
      padding: 20px 0px 20px 0px;
      background: transparent;
      border: none;
      font-size: 18px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .filters-close {
    display: flex;
    opacity: 1;
    visibility: visible;
  }

}


.filters-order {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: solid 2px #353535;
  padding: 32px 0px;

  li{
    width: 33%;
    text-align: center;

    &:nth-of-type(2) {
      border-left: solid 1px #DADADA;
      border-right: solid 1px #DADADA;
    }
  }

  &__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333333%;
    text-align: center;
    @include AdelleSansBold();
    color: #353535;
    transition: color .4s $easeOutQuad;
    cursor: pointer;

    &:hover {
      color: #FF2F00;
    }

    &--active {
      color: #FF2F00;
    }
  }
}

.filters-letter {
  z-index: 100;

  &#filters-absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  &#filters-fixed {
    position: fixed;
    //top: 0;
    //right: 0;
  }

  &.is-fixed {
    position: fixed;
    right: auto;
  }

  &__item {
    a {
      @include AdelleSansSemiBold();
      font-size: 16px;
      line-height: 23px;
      text-transform: uppercase;
      color: #484848;
      cursor: pointer;
      text-decoration: none;
      transition: color .4s $easeOutQuad;

      @include l-down {
        font-size: 14px;
      }
    }

    &:hover {
      a {
        color: #FF2F00
      }
    }
  }
}


.filters-categories {
  @include l-up {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 302;

  .image {
    filter: blur(0px);
    transition: filter 1s ease-out;
  }

  &.is-open {
    height: 100%;

    .image {
      filter: blur(50px);
    }
  }

  &__current {
    position: absolute;
    opacity: 0;
    padding-top: 30px;
    z-index: 301;
    top: 0;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
    @include AdelleSansBold();
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    pointer-events: none;
    cursor: pointer;

    &:after {
      content: '';
      background-image: url('../static/svg/utils/back.svg');
      margin-left: 10px;
      width: 13px;
      height: 13px;
      background-size: 13px 13px;
      display: inline-block;
      position: absolute;
      right: -20px;
      margin-top: 2px;
    }
  }

  &__container {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: -1;
      background-color: #1c1c1c;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #1c1c1c;
        opacity: 0.6;
      }

      .image {
        @include object-fit()
      }
    }
  }

  &__title {
    @include AdelleSansBold();
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 30px;
  }

  &__list {
    @include AdelleSansSemiBold();
    font-size: 18px;
    width: 90%;
    max-height: 50%;
    overflow: scroll;

    .header & {
      overflow-x: unset;
    }

    &:-webkit-scrollbar {
      display: none;
    }

    .list__item {
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      p {
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 15px;
        @include AdelleSansBold();
      }

      a, span, p {
        color: #BBBBBB;
        text-decoration: none;
      }

      &--active {
        a {
          color: #FFFFFF;
        }
        span, p {
          color: #FF2F00;
        }
      }
    }
  }

  .js-filters-close {
    width: 60px;
    height: 60px;
    margin-bottom: 60px;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    position: relative;

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      background-image: url("../static/svg/utils/close-icon-orange.svg");
      background-size: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%)
    }
  }
}
