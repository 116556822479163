.podcast-card {
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 31px 36px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.6s $easeOutExpo;

  .podcast-card__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .podcast-card__image-container {
    position: relative;
    overflow: hidden;

    .podcast-card__buttons {
      position: absolute;
      left: 15px;
      bottom: 15px;

      @include xxs-up {
        left: 20px;
        bottom: 20px;
      }

      .btn--play {
        @include l-up {
          display: flex;
        }
      }

      .btn--playlist {
        @include xxxl-up {
          width: 65px;
        }
      }
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 77.5%;
      height: 0;
    }

    .podcast-card__image {
      transition: all 1.6s $easeOutExpo;
      @include object-fit();
    }

    .btn--play {
      @include l-up {
        display: none;
      }
    }
  }

  .podcast-card__content {
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    @include xxs-down {
      padding: 15px;
    }

    @include l-up {
      padding: 30px 20px;
    }

    a {
      position: relative;
      z-index: 15;
    }
  }

  .podcast-card__serie {
    a {
      text-decoration: underline;
    }
  }

  .podcast-card__serie,
  .podcast-card__authors,
  .podcast-card__number {
    @include AdelleSansSemiBold();
    font-size: 14px;
    color: #353535;
    line-height: 24px;

    @include l-up {
      font-size: 15px;
    }

    a {
      color: #ff2f00;
      margin-right: 5px;
    }

    .serie__episode {
      margin-left: 5px;

      @include l-down {
        &:before {
          content: attr(data-mobile)
        }
      }

      @include l-up {
        &:after {
          content: attr(data-desktop)
        }
      }
    }
  }



  .podcast-card__title {
    @include AdelleSansExtraBold();
    font-size: 24px;
    color: #353535;
    line-height: 30px;
    margin: 10px 0;

    @include xxs-down {
      font-size: 22px;
      line-height: 27px;
      margin: 7px 0;
    }

    @include l-up {
      font-size: 24px;
      margin: 10px 0;
    }

    @include xxxl-up {
      font-size: 26px;
    }
  }

  .podcast-card__description {
    @include AdelleSansSemiBold();
    font-size: 16px;
    color: #353535;
    line-height: 26px;
    margin: 15px 0 23px 0;

    @include xxs-down {
      margin-top: 7px;
      font-size: 14px;
      line-height: 23px;
    }

    @include l-up {
      // margin-bottom: 25px;
    }

    @include xxxl-up {
      font-size: 17px;
    }
  }

  .podcast-card__footer {
    margin: auto 0 0 0;
    a {
      color: gray;
      text-decoration: underline #ff2f00;

      &:hover {
        color: #ff2f00;
      }
    }
  }

  .podcast-card__buttons {
    display: flex;
    margin-top: auto;
    margin-bottom: 0px;
    flex-wrap: wrap;

    .btn--playlist {
      margin-left: 15px;
    }

    .btn--subscribe {
      margin-left: 15px;
    }
  }

  &:hover {
    box-shadow: 0 0px 17px 0 rgba(0, 0, 0, 0.1);
    transform: scale3d(0.99, 0.99, 0.99);
  }
}

.podcast-card.podcast-card--full {
  @include m-up {
    flex-direction: row-reverse;

    &:hover {
      transform: scale(1);
      box-shadow: 0 31px 36px 0 rgba(0, 0, 0, 0.1);
    }

    .podcast-card__image-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:before {
        display: none;
        content: none;
      }

      .podcast-card__image {
        position: static;
        width: 200px;
        height: calc(100% - 80px);
        margin-right: 40px;
        border-radius: 5px;

        @include l-up {
          width: 300px;
        }
      }
    }

    .podcast-card__content {
      padding: 40px;
      max-width: 65%;

      @include l-up {
        max-width: 57%;
      }
    }
  }
}

.podcast-card.podcast-card--background-full {
  .podcast-card__buttons {
    display: flex;
    margin-top: 0;
    margin-bottom: 0px;
    flex-wrap: wrap;

    .btn--playlist {
      margin-left: 15px;
    }

    .btn--subscribe {
      margin-left: 15px;
    }
  }
  .podcast-card__image-container {
    position: relative;

    &:before {
      background-color: #1c1c1c;
      padding-bottom: 140%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 6%,
        #000000 100%
      );
    }

    .podcast-card__image {
      opacity: 0.6;
    }
  }

  .podcast-card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    justify-content: flex-end;
    flex-grow: 1;
  }

  .podcast-card__title {
    color: #ffffff;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
    margin-bottom: 15px;

    @include xxs-up {
      margin-bottom: 5px;
    }

    @include l-up {
      font-size: 24px;
    }

    @include xxxl-up {
      font-size: 26px;
    }
  }

  .podcast-card__description {
    color: #f6f7f9;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    display: none;

    @include xxs-up {
      display: block;
    }

    @include xxxl-up {
      font-size: 17px;
    }
  }

  .podcast-card__chapo {
    @include AdelleSansSemiBold();
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    margin-bottom: 25px;

    @include xxs-down {
      font-size: 14px;
      line-height: 23px;
    }

    @include xxxl-up {
      font-size: 17px;
    }

    &:before {
      content: '';
      background-color: #ff2f00;
      width: 120px;
      height: 2px;
      display: block;
      margin-bottom: 15px;
    }
  }  

  &.podcast-card-playlist .podcast-card__title {
    margin-bottom: 20px;
  }

  &:hover {
    transform: scale(1);
    .podcast-card__image {
      transform: scale(1.05);
      opacity: 0.8;
    }
  }
}

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl {
  @include m-down {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }

  @include m-up {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px);
  }

  @include l-down {
    border-radius: 0;
    box-shadow: none;
  }

  .podcast-card__image-container {
    position: relative;

    &:before {
      padding-bottom: 85%;

      @include l-up {
        padding-bottom: 77.5%;
      }
    }
  }

  .podcast-card__serie,
  .podcast-card__number {
    @include AdelleSansSemiBold();
    font-size: 14px;
    color: #ffffff;
    line-height: 24px;

    @include l-up {
      font-size: 15px;
    }

    a {
      text-decoration: underline;
      color: #ffffff;
      margin-right: 5px;
    }
  }

  .podcast-card__content {
    max-width: 85%;

    @include xxs-up {
      max-width: 100%;
    }
  }

  .btn--playlist {
    &:hover {
      background-color: #ffffff;
      border: solid 2px #ffffff;

      .btn__icon-container {
        &:before {
          background-image: url('../static/svg/utils/add-to-playlist-active.svg');
        }
      }
    }
  }
}

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl.podcast-card--background-full--wide {
  .podcast-card__content {
    max-width: 100%;

    .podcast-card__authors {
      color: #FFFFFF;
      margin-bottom: 10px;

      a {
        color: #FFFFFF;
      }
    }
  }
}
