.sidebar-block {
  @include AdelleSansBold();
  color: #FFFFFF;
  // border-bottom: solid 2px #FFFFFF;
  font-size: 15px;
  position: relative;

  @include l-up {
    @include AdelleSansSemiBold();
    color: #353535;
    padding: 10px 0px;
    border-bottom: solid 2px #353535;
  }

  @include xl-up {
    font-size: 17px;
  }

  &:last-of-type {
    border: none;
  }

  &__filter {
    @include AdelleSansBold();
    font-size: 12px;
    text-transform: uppercase;
    margin: 35px 0px 8px;

    @include l-up {
      margin: 15px 0px 8px;
    }
  }

  &__list {
    .list__item {
      padding: 15px 0px;
      border-bottom:solid 1px rgba(255, 255, 255, 0.2);

      @include l-up {
        border-bottom: solid 1px #DADADA;

        &:last-child {
          border: none;
        }
      }

      .item__image-container {
        @include l-up {
          display: none;
        }
      }

      &.has-sublist {
        .item__link--category {
          &:before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 8px;
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../static/svg/utils/dropdown.svg');
            transition: transform 0.4s $easeOutExpo
          }
        }

        .item__link--active {
          &:before {
            transform: rotate(-180deg)
          }
        }

        .sublist {
          display: none;
          padding: 25px 0px 15px 22px;

          &.is-active {
            display: block;
          }
        }

        .sublist__item {
          font-size: 15px;
          margin-bottom: 15px;

          &:last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }

    .item__link {
      color: #FFFFFF;
      text-decoration: none;
      transition: color .4s $easeOutExpo;
      cursor: pointer;

      @include l-up {
        color: #353535;
      }

      &:hover {
        color: #FF2F00;
      }
    }

    .item__length {
      color: #FF2F00;

      &:before {
        content: ' ('
      }

      &:after {
        content: ')'
      }
    }

    &--filters {
      @include l-down {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:after {
          content: '';
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 48%;
        }

        .list__item {
          border-bottom: none;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 31.454545%;

          @include s-down {
            flex-basis: 48%;
          }

          &.has-sublist {
            position: relative;

            .item__link--category {
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              text-align: center;
              width:80%;
              margin: auto;

              &:hover {
                color: #FFFFFF;
              }

              &:before {
                content: none;
              }
            }

            .item__length {
              color: #FFFFFF;
            }

            .item__image-container {
              position: relative;
              overflow: hidden;
              border-radius: 5px;
              background: #000000;
              box-shadow: 0 6px 12px 0px rgba(#000000, 0.2);

              &:before {
                content: '';
                display: block;
                padding-bottom: 77.5%;
                height: 0;
              }

              .item__image {
                width: 100%;
                filter: blur(17px);
                transform: scale(1.3);
                opacity: 0.7;
                @include object-fit();
              }

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
              }
            }
          }
        }
      }
    }
  }
}
