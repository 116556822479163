/**************************\
  Basic Modal Styles
\**************************/

.modal, .micromodal-slide {
  position: relative;
  z-index: 301;
}

.modal {
  &--no-padding .modal__container{
    @include l-down {
      padding: 0px;
    }
  }

  &--no-cross .modal__close {
    @include l-down {
      display: none;
    }
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  @include flexCentered();

  @include m-down {
    align-items: flex-end;
  }
}

.modal__container {
  padding: 50px 45px;
  max-width: 910px;
  width: 100%;
  max-height: 100vh;
  border-radius: 5px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  background: #F6F7F9;
  box-shadow: 0 0 104px 0 rgba(0,0,0,0.80);

  @include m-down {
    padding: 40px 30px;
    border-radius: 0px;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
}

.modal__close:before {
  content: "\2715";
  font-size: 20px;
}

.modal__content {
  line-height: 1.5;
  color: rgba(0,0,0,.8);

  @include l-down {
    padding: 15px;
  }
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}




/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(15%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.micromodal-slide .modal__sharings {

  @include l-up {
    display: none;
  }

  .sharings__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #DADADA;
    padding: 10px 25px;

    @include xxs-down {
      padding: 10px 15px;
    }

    .header__title {
      @include AdelleSansBold();
      font-size: 20px;
      color: #353535;
    }

    .header__close {
      @include AdelleSansSemiBold();
      font-size: 15px;
      line-height: 28px;
      text-decoration: underline;
      color: #FF2F00;
    }
  }

  .sharings__buttons {
    padding: 35px 25px;
    display: flex;
    overflow-x: scroll;

    @include xxs-down {
      padding: 25px 15px;
    }

    @include s-up {
      justify-content: center;
    }
  }
}

.micromodal-slide .modal__current-podcast {
  display: flex;
  width: 100%;
  @include AdelleSansSemiBold();

  .current-podcast__artwork {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 95px;
    height: 95px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: #000;
    margin-right: 30px;

    img {
      opacity: 0.75;
      @include object-fit();
    }
  }

  .current-podcast__serie {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;

    a {
      color: #FF2F00;
      margin-right: 5px;
    }

    .serie__episode {
      margin-left: 5px;
    }
  }

  .current-podcast__title {
    @include AdelleSansExtraBold();
    font-size: 24px;
    color: #353535;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin: 5px 0px;
  }

  .current-podcast__description {
    font-size: 17px;
    color: #353535;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 25px;

    ul {      
      padding: 10px 17px;
      list-style: square;
    }

    p {
      margin: 10px 0;
    }
  }
}

.micromodal-slide .modal__subscribe .subscribe {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  .subscribe__title {
    @include AdelleSansBold();
    font-size: 12px;
    color:#FF2F00;
    margin-top: 65px;
    text-transform: uppercase;

    @include m-down {
      margin-top: 5px;
    }
  }

  .current-podcast__title {
    @include AdelleSansBold();
    font-size: 42px;
    line-height: 46px;
    color: #353535;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin: 20px 0px 50px;

    @include m-down {
      font-size: 24px;
      line-height: 28px;
      margin: 10px 0px 35px;
    }
  }

  .buttons {
    width: calc(100% - 100px);
    justify-content: space-between;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 50px;
      justify-content: center;

      @include m-down {
        margin-bottom: 0px;
      }
    }

    @include m-down {
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;
    }

    .btn {
      width: 100%;

      @include m-down {
        margin-bottom: 20px;
      }

      &:last-child {
        @include m-down {
          margin-bottom: 0px;
        }
      }

      &--google {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 32%;
      }

      &--stitcher {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 28%;
      }
    }
  }
}

.micromodal-slide .modal__tabs {
  @include l-down {
    display: none;
  }
}

.micromodal-slide .modal__subscribe,
.micromodal-slide .modal__tabs {

  .buttons {
    margin-bottom: 40px;

    @include l-up {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: 769px) {
    .btn {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  .authorization-checkbox {
    text-align: center;
    .authorization-checkbox__desc {
      @include AdelleSansSemiBold();
      font-size: 15px;
      color: #353535;
      letter-spacing: 0;
      line-height: 24px;

      a {
        color:#FF2F00;
      }
    }
  }

  .links {}
  .link {
    margin-top: 40px;
    padding-bottom: 7px;
    border-bottom: 2px solid #000;

    .link__label {
      @include AdelleSansBold();
      text-transform: uppercase;
      font-size: 12px;
      color: #FF2F00;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 7px;
    }

    .link__wrapper {
      display: flex;
      justify-content: space-between;

      .link__input {
        @include AdelleSansSemiBold();
        font-size: 17px;
        color: #353535;
        line-height: 24px;
        flex-grow: 1;
      }

      .link__copy {
        cursor: pointer;
        @include AdelleSansBold();
        font-size: 17px;
        color: #353535;
        text-align: right;
        line-height: 24px;
        transition: all 0.3s $easeOutExpo;

        &:hover {
          color: #FF2F00;
        }
      }
    }
  }
}

#preexistent-music-modal{
  .modal__overlay {
    align-items: center;
  }

  .modal__container {
    width: 500px;
    height: 90%;
    padding: 40px;
  
    @include s-down {
      width:90%;
    }
  
    & > header {
      display: flex;
      justify-content: space-between;
      color: #FF2F00;
      font-size: 35px;
      font-weight: bold;
      align-items: center;
    }
  
    .modal__close {
      position: static;
      font-weight: normal;
      color: #FF2F00;
  
      &:before {
        font-size: 35px;
      }
    }
  
    .modal__content {
      padding: 30px 15px 0 15px;
    }
  }
}

.modal__container__separator {  
  width: 100%;
  position: absolute;
  left: 0;
  border: none; 
  border-bottom: 1px solid #FF2F00;
  color: #FF2F00;
  margin: 30px 0;
}

.modal__preexistent-music header {
  font-size: 25px;
  @include AdelleSansBold();
  line-height: 60px;
}

.modal__preexistent-music__infos {
  line-height: 25px;
}
  
.modal__preexistent-music__infos__title {
  @include AdelleSansBold();
  margin-top: 10px;
}

.modal__preexistent-music__infos__content {
  @include AdelleSans();
}

.modal__preexistent-music__separator { 
  border: none; 
  border-bottom: 1px solid #FF2F00;
  color: #FF2F00;
  margin: 30px 15% 10px 15%;
}
