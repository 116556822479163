.search-popular {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;

  @include l-up {
    display: flex;
    margin-top: 40px;
    align-items: center;
    //flex-direction: row;
  }

  &__title {
    margin: 0 0 20px 0;
    @include AdelleSansBold();
    font-size: 22px;
    line-height: 34px;
    color: white;
    text-align: center;

    @include m-up {
      //margin: auto;
    }

    @include l-up {
      //margin: 0;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include l-up {
      //width: auto;
      flex-direction: row;
    }

    .btn {
      @include l-down {
        transition: none;
        width: 100%;
        justify-content: flex-start;
        padding: 18px 0px;
        border-radius: 0px;

        &:not(:first-of-type) {
          border-top: solid 1px rgba(255, 255, 255, 0.2);
        }

        &:hover {
          background: none;
        }
      }

      @include l-up {
        //margin-left: 15px;
        padding: 7px 28px;

        &:hover {
          background: #FFFFFF;
          color: #FF2F00;
        }
      }

      @include xxxl-up {    
        padding: 7px 50px;
      }

      &__label {
        font-size: 18px;

        @include m-up {
          margin: auto;
        }

        @include l-up {
          font-size: 15px;
        }
      }

      &--orange {
        background-color: transparent;
        box-shadow: none;

        @include l-up {
          background-color: #FF2F00;
        }
      }

      &--borders {
        border-color: transparent;

        @include l-up {
          border-color: #FFFFFF;
        }

        &.btn--white {
          @include l-down {
            &:hover {
              background-color: transparent;
              border-color: transparent;
              border-top-color: rgba(255, 255, 255, 0.2);
              border-top-width: 1px;

              .btn__label {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }

  &--wide {
    margin-top: 0px;

    .search-popular__title {
      color: #353535;
    }

    @include l-down {
      .search-popular__buttons {
        .btn__label {
          margin: auto;
          color: #353535 !important;
        }

        .btn {
          &:hover {
            border-color: transparent;
            background: none;

            span {
              color: #353535;
            }
          }
          &:last-of-type {
            padding-bottom: 0px;
          }
        }
      }
    }

    @include l-up {
      flex-direction: column;

      .search-popular__title {
        @include AdelleSansSemiBold();
        font-size: 30px;
        line-height: 43px;
        color: #353535;
        margin-bottom: 20px;
      }

      .search-popular__buttons {
        .btn--orange {
          &:hover {
            background: #000;

            span {
              color: #FFFFFF;
            }
          }
        }
        .btn--borders {
          border-color: #BBBBBB;

          span {
            color:#1f1717 !important;
          }

          &:hover {
            background: rgba(236, 236, 236, 0.6);
            border-color: rgba(236, 236, 236, 0.6);

            span {
              color: #353535;
            }
          }
        }
      }
    }
  }
}
