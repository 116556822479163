.layout--search.results-displayed {
  @include l-down {
    background-color: #333333;
  }
}

.search-results {
  min-height: 100px !important;
  padding-top: 100px;
  position: relative;

  @include l-up {
    padding-top: 40px;
  }

  .mainloader {
    top: auto;
    width: 30px;
    height: 30px;
    left: calc(50% - 15px);

    @include l-down {
      top: 30px;
    }
  }
  
  .content__search {
    color: #FFFFFF;

    @include l-up {
      color: #FF2F00;
    }

    &:before, &:after {
      color: #FFFFFF;

      @include l-up {
        color: #353535;
      }
    }

    &:before {
      content: ' « ';
    }

    &:after {
      content: ' »';
    }
  }

  &__content {
    @include AdelleSansBold();
    color: #FFFFFF;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 22px;

    @include xs-up {
      font-size: 18px;
      line-height: 24px;
    }

    @include l-up {
      font-size: 36px;
      line-height: 43px;
      color: #353535;
    }

    span {
      color: #FF2F00;
    }
  }

  &__list {
    @include l-down {
      display: none;
    }
    .list__item:not(.hidden) {
      @include AdelleSansSemiBold();
      font-size: 26px;
      line-height: 43px;
      color: #353535;
      display: inline-block;

      &:after {
        content: ', ';
        color: #353535;
      }

      &.only-item {
        &:before {
          content: none;
        }
        &:after {
          content: none;
        }
      }

      &.second-to-last-item {
        &:after {
          content: none;
        }
      }

      &.last-item {
        &:before {
          content: ' et ';
        }
        &:after {
          content: none;
        }
      }

      .item__link {
        text-decoration: none;
        border-bottom: solid 2px #FF2F00;
        color: #FF2F00;
        padding-bottom: 5px;

        &:hover {
          span {
            color: #FF2F00;
          }
        }

        span {
          color: #353535;
          transition: color .4s $easeOutExpo;

          &.item__length{
            color: #FF2F00;
          }
        }
      }
    }
  }

  &__details {
    padding-top: 60px;

    @include l-down {
      padding-top: 0;
    }

    .selected-podcasts__load-more {
      position: relative;
    }

    .loader.mainloader {
      top: -50px;
      left: calc(50% - 0.5em);
      font-size: 20px;
    }
  }

  &--empty {
    margin-top: 91px !important;
    padding: 30px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include l-down {
      display: block;
    }

    @include l-up {
      margin-top: 50px;
      padding: 60px 0px 80px;
    }

    .search-results__content {
      color:#353535;
      margin-bottom: 0px;
      text-align: center;

      @include l-down {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .separator {
      width: 120px;
      border: none;
      border-bottom: solid 2px #FF2F00;
      margin: 25px auto;

      @include l-up {
        margin: 35px 0px
      }
    }
  }
}

#search-results__content {
  @include AdelleSansBold();
  color: #353535;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;

  @include xs-up {
    font-size: 18px;
    line-height: 24px;
  }

  @include l-up {
    font-size: 36px;
    line-height: 43px;
  }

  @include l-down {
    color:white;
  }

  span {
    &.content__length, &.content__search {
      color: #FF2F00;
    }
    &.content__search {
      @include l-down {
        color:white;
      }
    }
  }
}

.search-results__tabs {
  display: none;

  @include l-down {
    display: block;
  }
}

.podcast-card--pattern, .list__item--pattern {
  display : none;
}
