.cover-all {
  position: relative;
  background-color: #1C1C1C;
  overflow: hidden;
  height: 60vh;
  display: flex;
  align-items: center;

  @include l-down {
    display: none;
  }

  .cover-all__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);
    }

    .cover-all__image-wrapper {
      width: 100%;
      height: 100%;
      overflow:hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .cover-all__image {
    opacity: 0.6;
    height: 100%;
    @include object-fit();
  }

  .cover-all__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
    max-width: 50%;

    .cover-all__surtitle {
      @include AdelleSansSemiBold();
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    .cover-all__title::first-letter {
      text-transform: capitalize;
    }

    .cover-all__separator {
      margin: 30px auto;
    }

    .cover-all__description {
      margin-top: 15px;
    }

    .cover-all__description,
    .cover-all__sounds {
      @include AdelleSansSemiBold();
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px;

      @include l-up {
        font-size: 15px;
        line-height: 25px;
      }

      @include xxxl-up {
        font-size: 18px;
        line-height: 28px;
      }

      .btn--play {
        margin: 45px auto 0 auto;
      }
    }

    .cover-all__sounds .u-bold {
      &:before, &:after {
        content: ' '
      }
    }
  }
}
