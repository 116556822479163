.search-form {
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @include l-down {
    width: auto;
    margin: 0 25px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 45px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    flex-wrap: wrap;
    transition: border-radius .4s $easeOutQuad;

    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      background-image: url("../static/svg/utils/search-icon.svg");
      background-size: cover;
    }

    @include l-up {
      flex-basis: calc(100% - 200px);
      height: 70px;
      flex-wrap: nowrap;

      &:before {
        width: 20px;
        height: 20px;
        left: 30px;
      }

      &:after {
        display: none;
      }
    }

    &.input-active {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0px;
      transition: border-radius .25s $easeOutQuad;

      @include l-up {
        border-top-left-radius: 5px;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 25px;
      }

      .search-bar__list {
        display: block;
      }
    }


    &__input {
      width: 100%;
      @include AdelleSansBold();
      color: #353535;
      flex-grow: 0;
      flex-shrink: 0;
      //flex-basis: calc(100% - 80px);
      outline: none;
      font-size: 14px;
      padding-left: 40px;
      padding-right: 20px;
      height: 100%;

      &::placeholder {
        color: #949494;
        transition: opacity .4s $easeOutQuad;
      }

      &::-ms-clear {
        display: none;
      }

      &::-webkit-input-placeholder {
        line-height: 24px;

        @include l-up {
          line-height: 28px;
        }
      }

      &:focus {
        &::placeholder {
          opacity: 0.5
        }
      }

      @include l-up {
        padding-left: 70px;
        //padding-right: 0px;
        font-size: 17px;
        flex-grow: 0;
        flex-shrink: 0;
        //flex-basis: calc(100% - 270px);
        //border-right: 1px solid #DADADA !important;

        &::placeholder {
          height: 70px;
        }

      }

      @include xxl-up {
        font-size: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        //flex-basis: calc(100% - 320px);
      }
    }

    &__list {
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 10;
      width: 100%;
      background-color: #FFFFFF;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      display: none;

      @include l-up {
        top: 70px;
        width: calc(65% + 70px);
      }


      .list__item {
        padding: 0px 40px;
        height: 40px;
        border-top: solid 1px #D8D8D8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @include l-up {
          padding: 25px 30px 25px 70px;
          height: auto;
        }
      }

      .item__link {
        color: #353535;
        @include AdelleSansBold();
        font-size: 14px;
        text-decoration: none;

        @include l-up {
          font-size: 20px;
        }

        .highlight {
          color: #FF2F00;
        }
      }

      .item__duration {
        @include AdelleSansSemiBold();
        font-size: 16px;
        color: #BBBBBB;
        display: none;

        @include l-up {
          display: block;
        }
      }
    }

    &__filters {
      height: 100%;
      position: relative;
      z-index: 3;
      @include AdelleSansBold();
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
      margin-top: 15px;

      @include l-up {
        width: 100%;
        font-size: 17px;
        color: #353535;
        margin-top: 0px;
      }

      @include xxl-up {
        font-size: 20px;
      }

      .current-filter {
        padding: 11px 28px 8px;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        border-top: none;
        background-color: transparent;
        border: solid 1px #FFFFFF;
        border-radius: 25px;

        &:hover {
          background-color: transparent;
        }

        &:before {
          font-size: 11px;
          color: #949494;
          content: 'DURÉE';
          //margin-bottom: 7.5px;
          display: none;
        }

        &.has-been-selected {
          @include l-down {
            background-color: #FFFFFF;
            color: #FF2F00;
          }
        }

        @include l-up {
          padding: 0px 0px 0px 20px;
          border: none;
          display: flex;
          border-radius: 0px;

          &:before {
            display: block;
          }
        }

        @include xxl-up {
          padding: 0px 0px 0px 30px;
        }
      }

      .filters__list {
        @include l-up {
          left: -80px;
        }

        .list__item {
          border-top: solid 1px #D8D8D8;
          background-color: #FFFFFF;
          transition: background-color .5s $easeOutQuad;
          cursor: pointer;
          position: relative;
          width: 100%;
          text-align: center;

          @include l-up {
            width: auto;
            text-align: left;
            padding: 15px 0px 15px 30px;

            &:hover {
              background-color: transparent;
            }
          }

          &:hover {
            background-color: #F6F7F9;
          }

          &:last-of-type {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-bottom: 60px;
          }

          &--current {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-top-right-radius: 5px;
            border-top-color: transparent;
            border-left: 1px solid #DADADA;

            &:before {
              font-size: 11px;
              color: #949494;
              content: 'DURÉE';
              margin-bottom: 7.5px;
            }
          }

          &.is-selected {
            width: auto;

            &:after {
              content: '';
              width: 20px;
              height: 15px;
              position: absolute;
              top: 50%;
              right: 25px;
              transform: translateY(-50%);
              background-size: cover;
              background-image: url("../static/svg/utils/tick-icon.svg");
            }
          }
        }
      }
    }
  }

  .btn--search {
    border-radius: 45px;

    @include l-down {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      box-shadow: none;

      .btn__icon-container {
        &:before {
          width: 20px;
          height: 13px;
        }
      }
    }

    @include l-up {
      display: flex;
      width: 160px;
      height: 70px;
    }

    &:hover {
      background: #FFFFFF;

      .btn__icon-container {
        @include l-up {
          &:before {
            background-image: url("../static/svg/utils/search-icon-orange.svg")
          }
        }
      }
    }
  }
}
