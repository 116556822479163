@import "search-bar";
@import "search-popular";
@import "search-results";

.mobile-bgd {
  display: none;
  width: 100%;
  height: 70vh;
  opacity: 0.08;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}

.u-container.u-container--specific{
  @include xxxxl-up {    
    max-width: 1400px;
  }
}

.global-wrapper {
  &.image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1c1c1c;

    @include l-down {
      margin: 0px;
      max-width: 100%;
      overflow: hidden;
    }

    @include l-up {
      background: transparent;
      position: relative;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(35,35,35,0.00) 0%, #353535 100%);
      height: 70vh;
      width: 100%;

      @include l-up {
        content: none
      }
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 70vh;
      opacity: 0.08;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';

      @include l-up {
        display: none;
      }
    }

    .layer-dark {
      display: none;
      @include l-down {
        display: block;
        position: absolute;
        top: 70vh;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #353535;
        z-index: 0;
      }
    }
  }
  &.search-wrapper {
    height: 40vh;
    align-content: center;
    background-color: #000000;
    position: relative;
    padding-top: 95px;
    padding-bottom: 40px;

    @include l-down {
      height: auto;
      background-color: #1c1c1c;
    }

    @include l-up {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      //height: calc(100vh - 140px);
      padding-top: 0px;
      padding-bottom: 0px;
    }

    @include xxxl-up {
      //height: calc(100vh - 197px);
    }

    &.filters-open {
      z-index: 301;

      .search-form {
        position: static;
      }

      .search-bar {
        position: static;
        background: none;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      .search-bar__input, .search-popular, .separator, .btn--search {
        visibility: hidden;
        opacity: 0;
      }

      .search-bar__filters {
        display: flex;
        align-items: center;
        position: static;
      }

      .image {
        filter: blur(50px);
      }

      .current-filter {
        display: none;
      }

      .filters__list {
        &:before {
          content: 'Durée';
        }

        .list__item {
          background: transparent;
          border: none;

          &:hover {
            background-color: transparent;
          }

          &--current {
            height: auto;

            &:before {
              display: none;
            }
          }

          &.is-selected {
            display: flex;
            align-items: center;
            justify-content: center;

            &:after {
              right: -30px;
              width: 15px;
              height: 11px;
            }
          }
        }
      }
    }

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;


      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
        height: 100%;
        width: 100%;
        transform: translateY(-100%);
      }

      .image-wrapper {
        height: 100%;
        overflow:hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .image {
      opacity: 0.6;
      filter: blur(0px);
      transition: filter .3s $easeOutQuad;
      animation: scaling 15s infinite alternate;
      @include object-fit();

      @include l-up {
        height: 100%;
      }
    }

    .separator {
      margin-top: 85px;
      margin-left: -25px;
      margin-right: -25px;
      border-bottom-color: rgba(255, 255, 255, 0.2);

      @include l-up {
        display: none;
      }
    }
  }


  @keyframes scaling {
    from {
      -webkit-transform: scale(1.0);
    }
    to {
      -webkit-transform: scale(1.06);
    }
  }

}
