$color-grey: #DADADA;
$color-grey-dark: #686868;
$color-grey-light: #F6F7F9;
$color-red: #FF2F00;
$color-white: #ffffff;
$color-black: #000000;
$color-black-2: #484848;
$color-black-light: #1c1c1c;
$color-black-lighter: #353535;
$color-fb: #3B5998;
$color-tw: #6FAEDC;
$color-wh: #45CC55;