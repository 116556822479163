// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
    	font-weight: $weight;
    	font-display: swap;
		src: url($path);
	}
}


@mixin AdelleSansLight(){
	font-family: "adelle-sans", sans-serif;
	font-weight: 300;
	font-style: normal;
}

@mixin AdelleSans(){
	font-family: "adelle-sans", sans-serif;
	font-weight: 400;
	font-style: normal;
}

@mixin AdelleSansSemiBold(){
	font-family: "adelle-sans", sans-serif;
	font-weight: 600;
	font-style: normal;
}

@mixin AdelleSansBold(){
	font-family: "adelle-sans", sans-serif;
	font-weight: 700;
	font-style: normal;
}

@mixin AdelleSansExtraBold(){
	font-family: "adelle-sans", sans-serif;
	font-weight: 800;
	font-style: normal;
}

@mixin object-fit() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}

@mixin flexCentered() {
  display: flex;
  align-items: center;
  justify-content: center;
}


@mixin newsletter-input-border-background() {
	border: 1px solid #A6A6A6;
	background-color: #E3E3E3;
  }
