.embed-player {
  position: relative;
  display: flex;
  padding: 15px;

  &__artwork {
    width: 96px;
    height: 96px;
    position: relative;
    margin-right: 15px;
    background-color: #000000;
    border-radius: 5px;

    @include xxs-down {
      width: 70px;
      height: 70px;
    }

    .artwork__img {
      @include object-fit();
      width: 100%;
      border-radius: 5px;
      opacity: 0.8;
    }

    .artwork__player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      z-index: 2;
      cursor: pointer;

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 25px;
        transition: opacity .4s $easeOutCubic;
      }

      &:before {
        background-image: url("../static/svg/utils/play.svg");
      }

      &:after {
        background-image: url("../static/svg/utils/play-active.svg");
        opacity: 0;
      }

      &.playing {
        &:before, &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 14px;
          height: 25px;
          transition: opacity .4s $easeOutCubic;
        }

        &:before {
          background-image: url("../static/svg/utils/pause.svg");
        }

        &:after {
          background-image: url("../static/svg/utils/pause-active.svg");
        }
      }
    }
  }

  &__titles-and-logos {
    display: flex;
    justify-content: space-between;
  }

  &__logos {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .logo-partner {
    //width: 70px;
    height: 40px;

    @include s-down {
      //margin-right: 10px;
      //width: 55px;
      height: 30px;
    }
  }

  .logo-arte {
    width: 60px;

    @include s-down {
      width: 45px;
    }
  }

  &__content {
    color: #353535;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .content__serie {
      @include AdelleSansBold();
      font-size: 11px;
      margin-bottom: 10px;

      .serie__title {
        color: #FF2F00;
        text-decoration: none;
        margin-right: 5px;
      }

      span {
        margin-left: 5px;

        @include l-down {
          &:before {
            content: attr(data-mobile)
          }
        }

        @include l-up {
          &:after {
            content: attr(data-desktop)
          }
        }
      }
    }

    .content__title {
      @include AdelleSansBold();
      font-size: 17px;
      color: #FF2F00;
      text-decoration: none;
      margin-bottom: 5px;
      display: block;
      max-width: 365px; //calc(100% - 55px)
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .content__chapo {
      @include AdelleSansSemiBold();
      font-size: 13px;
      line-height: 16px;
      // max-width: calc(100% - 55px)
    }

    .content__player {
      display: flex;
      align-items: center;
      margin-top: auto;
      margin-bottom: 0;

      @include xxs-down {
        margin-top: 10px;
      }

      .player__navigation {
        .navigation__btn {
          width: 12px;
          height: 15px;
          position: relative;

          &:before, &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
            transition: opacity .4s $easeOutCubic;
          }

          &--previous {
            margin-right: 10px;

            &:hover {
              &:before {
                opacity: 0;
              }

              &:after {
                opacity: 1
              }
            }


            &:before {
              background-image: url("../static/svg/utils/previous-embed.svg");
            }

            &:after {
              background-image: url("../static/svg/utils/previous-embed-active.svg");
              opacity: 0;
            }
          }

          &--next {
            margin-right: 15px;

            &:hover {
              &:before {
                opacity: 0;
              }

              &:after {
                opacity: 1
              }
            }

            &:before {
              background-image: url("../static/svg/utils/next-embed.svg");
            }

            &:after {
              background-image: url("../static/svg/utils/next-embed-active.svg");
              opacity: 0;
            }
          }
        }
      }

      .player__timeline {
        flex-grow: 1;
        position: relative;
        cursor: pointer;

        .timeline__progress-bar,
        .timeline__progress-current {
          position: absolute;
          top: 0;
          left: 0;
          height: 3px;
          transition: all 0.4s $easeOutCubic;
          transform-origin: bottom center;
          border-radius: 2px;
        }

        .timeline__progress-bar {
          width: 100%;
          background-color: #D8D8D8;
        }

        .timeline__progress-current {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #FF2F00;
          pointer-events: none;
        }

        .timeline__progress-handle {
          opacity: 0;
          position: absolute;
          top: -8px;
          left: 0;
          width: 16px;
          height: 16px;
          transform: translate(-50%, 0%) scale(0.8);
          transition: all 0.4s $easeOutCubic;
          border-radius: 50%;
          background-color: #FF2F00;
          box-shadow: 0 0px 7px 0 rgba(0,0,0,0.20);
        }

        &:hover {
          .timeline__progress-handle {
            opacity: 1;
            transform: translate(-50%, 0%) scale(1);
          }
        }
      }

      .player__time-infos {
        display: flex;
        align-items: center;
        @include AdelleSansBold();
        font-size: 12px;
        color: #353535;
        margin: 5px 15px 0px;
      }

      .time-infos__current {
        color: #FF2F00;
      }

      .time-infos__separator {
        margin: 0 5px;
        color: #FF2F00
      }

      .player__btn {
        &--share {
          position: relative;
          width: 16px;
          height: 13px;

          &:hover {
            &:after {
              opacity: 1;
            }
            &:before {
              opacity: 0;
            }
          }

          &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 13px;
            transition: opacity .4s $easeOutCubic;
          }

          &:before {
            background-image: url("../static/svg/utils/share-embed.svg");
          }

          &:after {
            background-image: url("../static/svg/utils/share-active.svg");
            opacity: 0
          }
        }
      }
    }
  }
}
