@import "embed-list";
@import "embed-player";
@import "embed-sharings";

.embed-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.embed-container {
  background-color:#F6F7F9;
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
}

.embed-small {
  margin-bottom: 30px;
}
