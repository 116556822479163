.cover-home {
  position: relative;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 0px;
  background-color: #1C1C1C;
  overflow: hidden;

  @include l-up {
    height: 65vh;
    padding-top: 75px;
    padding-bottom: 50px;
  }

  @include xxxl-up {
    height: 70vh;
    padding-bottom: 100px;
  }

  .cover-home__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .cover-home__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;


    &:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);


      @include l-up {
        top: 100%;
        height: 100%;
        background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%);
      }
    }

    .cover-home__image-wrapper {
      height: 80%;
      overflow:hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include l-up {
        height: 100%;
      }
    }
  }

  .cover-home__image {
    opacity: 0.6;
    @include object-fit();

    @include l-up {
      height: 100%;
    }
  }

  .cover-home__content {
    position: relative;
    display: block;

    @include s-up {
      max-width: 60%;
    }

    @include l-up {
      max-width: 100%;
      margin-left: 100px;
    }
  }

  .cover-home__illustration {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 110px);
    margin: auto;

    @include m-up {
      margin: 0;
      width: calc(45% - 50px);
    }
  }

  .cover-home__information {
    text-align: center;
    margin: 20px auto 0;

    @include m-up {
      text-align: left;
      margin-left: 50px;
      max-width: 40%;
      align-self: flex-start;
    }

    .cover-home__authors {
      margin-top: 5px;
    }
  }

  .cover-home__serie {
    .serie__episode {
      margin-left: 5px;
    }
  }

  .cover-home__title {
    letter-spacing: 0.3px;

    @include l-up {
      max-width: 700px;
    }

    @include xxxl-up {
      max-width: 900px;
    }
  }

  .cover-home__authors {
    text-shadow: 0 1px 11px rgba(0,0,0,0.50);
    margin-top: 5px;

    @include l-up {
      margin-top: 10px;
    }

    @include xxxl-up {
      margin-top: 15px;
    }
  }

  .cover-home__chapo {
    @include l-up {
      max-width: 450px;
    }

    @include xxxl-up {
      max-width: 670px;
    }
  }

  .cover-home__separator {
    margin: 20px 0px 20px;

    @include l-up {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    @include xxxl-up {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .cover-home__buttons {
    margin-top: 30px;
    display: flex;
  }
}
