.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  
  @include l-down {
    position: fixed;
    z-index: 300;
  }
}

.header .header__navigation {
  height: 100vh;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  position: absolute;
  top: 0;

  background-color: #F6F7F9;

  @include l-down {
    overflow: auto;
    display: none;
    position: fixed;
  }

  @include l-up {
    display: block;
    transform: none;
    height: auto;
    background-color: #FFFFFF;
  }

  .navigation__top {
    display: flex;
    flex-direction: column;

    @include l-down {
      padding: 60px 20px;
      box-shadow: 0 1px 0 0 #DADADA;
      position: relative;
      background-color: #FFFFFF;
    }

    @include l-up {
      padding: 15px 30px;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #DADADA;
    }

    @include xl-up {
      padding: 15px 60px;
    }

    @include xxxl-up {
      padding: 30px 60px;
    }

    .navigation__close {
      position: fixed;
      top: 50px;
      left: 25px;
      width: 12px;
      height: 14px;
      @include flexCentered();

      @include xxs-down {
        left: 15px;
      }

      @include l-up {
        display: none;
      }
    }

    .navigation__baseline {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include l-up {
        flex-direction: row;
      }

      .baseline__link {
        width: 90px;
        height: 44px;
        display: block;
        margin-bottom: 15px;

        @include l-down {
          width: 110px;
          margin: 0;
        }

        @include l-up {
          width: 90px;
          height: 44px;
          margin-bottom: 0;
        }

        @include xxxl-up {
          width: 110px;
          height: 56px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .baseline__description {
        @include AdelleSansSemiBold();
        font-size: 15px;
        color: #353535;
        line-height: 19px;
        margin-left: 30px;

        @include l-down {
          display: none;
        }

        @include xxxl-up {
          font-size: 17px;
          line-height: 24px;
        }
      }
    }

    .navigation__socials {
      display: flex;
      align-items: center;
      
      @include l-down {
        display: none;
      }

      .socials__link {
        width: 40px;
        height: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 40px;
        @include flexCentered();
        transition: all 0.3s $easeOutExpo;

        &:not(:last-child) {
          margin-right: 15px;
        }

        &:hover {
          opacity: 0.7;
        }

        &:nth-last-child(2) {
          position: relative;
          @include l-up {
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              right: -20px;
              transform: translateY(-50%);
              margin-left: 25px;
              display: block;
              height: 16px;
              width: 1px;
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: 1px;
              background-color: #DADADA;
            }
          }
        }

        &--wide {
          @include AdelleSansBold();
          width: auto;
          height: 30px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
          text-decoration: none;
          color: #353535;
          position: relative;
          transition: all 0.3s $easeOutExpo;
          font-size: 15px;
          text-decoration: none;
          margin-left: 25px;

          @include l-down {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            display: inline-flex;
            margin-top: 20px;
            margin-left: 0px;

            &:after {
              content: none;
              display: none;
            }
          }

          &:after {
            content:'';
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #FF2F00;
            transition: all 0.3s $easeOutExpo;
            transform-origin: left;
            pointer-events: none;
          }

          @include xxxl-up {
            font-size: 16px;
          }

          &:hover,
          &.is-active {
            opacity: 1;
            color: #FF2F00;

            &:after {
              transform: scaleX(0.5)
            }
          }
        }

        .link__logo {

          &.facebook {
            width: 10px;
            height: 19px;
          }

          &.twitter {
            width: 19px;
            height: 16px;
          }

          &.youtube {
            width: 24px;
            height: 17px;
          }
          
          &.instagram {
            width: 22px;
          }

          &.itunes {
            width: 20px;
            height: 21px;
          }

          &.deezer {
            height: 16px;
          }
        }
      }
    }

  }

  .navigation__wrapper-menu {
    display: flex;
    flex-direction: column;

    @include l-down {
      background-color: #F6F7F9;
    }

    @include l-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
    }
    @include xl-up {
      padding: 0 60px;
    }
  }

  .navigation__menu {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;

    @include xxxl-down {
      position: relative;
    }
    
    @include l-down {
      padding: 20px 0 0 0;
    }

    @include l-up {
      padding: 0;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      height: 65px;
    }

    @include xxxl-up {
      height: 80px;
    }

    .menu__item {
      line-height: 55px;

      @include l-up {
        line-height: 74px;
        align-items: center;
        text-align: center;
      }

      .menu__link {
        text-decoration: none;
        color: #353535;
        position: relative;
        transition: all 0.3s $easeOutExpo;
        @include AdelleSansExtraBold();
        font-size: 22px;
        line-height: 30px;

        @include l-down {
          padding: 0 20px;
        }

        @include l-up {
          @include AdelleSansBold();
          text-decoration: none;
          font-size: 15px;
          line-height: 30px;


          &:not(.link--no-underline):before {
            content:'';
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #FF2F00;
            transition: all 0.3s $easeOutExpo;
            transform-origin: left;
            pointer-events: none;
          }
        }

        @include xxxl-up {
          font-size: 16px;
        }

        &:hover,
        &.is-active {
          color: #FF2F00;

          &:before {
            transform: scaleX(0.5)
          }
        }

        &.link--search {
          @include l-down {
            display: none;
          }
          display: flex;
          margin-left: 25px;

          &:before  {
            content: '';
            display: block;
            height: 16px;
            width: 1px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1px;
            margin-right: 35px;
            background-color: #DADADA;
          }
          img {
            vertical-align: middle;
          }

          &:hover {
            opacity: 0.7;
          }
        }

        &.btn.btn--borders {

          padding-bottom: 5px;
          padding-left: 35px;
          padding-right: 35px;
          line-height: 20px;

          @include l-down {
            margin-top: 30px;
          }
        }
      }

      &.logo-arte {
        display: none;
        opacity: 0;
        transition: all 0.6s $easeOutExpo;

        @include l-up {
          display: block;
        }

        .icon-arte-radio {
          width: 85px;
          height: auto;
          vertical-align: middle;
        }
      }
    }

    .menu__submenu {
      display: flex;
      flex-wrap:wrap;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #FF2F00;
      top: 100%;
      padding: 0 60px;
      opacity: 0;
      visibility: hidden;
      transition-duration: 200ms, 200ms;
      transition-property: opacity, visibility;
      transition-delay: 0, 200ms;

      @include xxxl-down {
        flex-direction: column;
        width: fit-content;
        text-align: left;
      }
      
      @include l-down {
        height: 0;
        width: 100%;
        overflow: hidden;
        position: static;
      }

      &.is-active {
        @include l-down{        
          opacity: 1;
          visibility: visible;
          transition-delay: 0
        }
      }

      .submenu__item {

        @include l-down {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        @include l-up {
          padding-right: 50px;
        }        

        @include xxxl-down {
          line-height: 55px;
        }

        .submenu__link {
          @include AdelleSansBold();
          font-size: 16px;
          color: #F6F7F9;
          line-height: 20px;
          text-decoration: none;
          position: relative;

          @include l-down {
            @include AdelleSansSemiBold();
            font-size: 20px;
            line-height: 52px;
          }

          &:before {
            content:'';
            position: absolute;
            bottom: -3px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #FFF;
            transition: transform 0.3s $easeOutExpo;
            transform-origin: right;
            transform: scaleX(0);
            pointer-events: none;
          }

          &:hover,
          &.is-active {
            &:before {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }
      }
    }

    .menu__item.has-submenu {
      .menu__link {
        &:after {
          content:'';
          display: inline-block;
          width: 12px;
          height: 7px;
          background-image: url('../static/svg/utils/dropdown.svg');
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: 15px;
          vertical-align: middle;
        }
      }

      &:hover {
        .menu__link {
          color: #FF2F00;

          &:before {
            transform: scaleX(0.5)
          }
        }
        @include l-up{
          & > .menu__link + .menu__submenu {            
            opacity: 1;
            visibility: visible;
            transition-delay: 0;
          }
          
          .menu__submenu {
            opacity: 1;
            visibility: visible;
            transition-delay: 0;
          }
        }
      }

      .is-active {
        @include l-down {
          height: auto;
        }
      }
    }

    @include l-up {

      &.navigation__menu--secondary {
        justify-content: flex-end;
      }

      &.navigation__menu--main {
        justify-content: space-between;
        flex-grow: 20;
        max-width: 1400px;

        .menu__item {
          flex-grow: 1;
          display: flex;
          padding: 0;
          height: 100%;

          &:nth-child(2) {
            text-align: left;
            justify-content: space-between;
            flex-grow: 0.7;
          }

          &.logo-arte {
            flex-grow: 0;
            width: 0;
          }

          &:last-child {
            text-align: right;

            &:after {
              background: transparent!important;
            }
          }

          &:not(.logo-arte):after {
            content: '';
            display: block;
            height: 16px;
            width: 1px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1px;
            background-color: #DADADA;
          }

          &:not(:nth-child(2)):after {
            margin-left: auto;
          }


          &:not(:nth-child(2)) .menu__link {
            margin-left: auto;
          }
        }
      }
    }
  }

}

/************************
**** Minified styles ****
*************************/

.header.is-minified {
  position: fixed;

  .header__navigation {
    @include l-up {
      transform: translate3d(0,-75px,0);
    }

    @include xxxl-up {
      transform: translate3d(0,-117px,0);
    }
  }

  .navigation__menu {
    &.navigation__menu--main {
      .menu__item.logo-arte {

        @include l-up {
          width: 75px;
          opacity: 1;
          display: block;
          margin-right: 50px;
        }
        @include xxxl-up {
          width: 85px;
        }

        .icon-arte-radio {
          width: 85px;
        }
      }
    }
  }

}
