@charset "UTF-8";
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .glide__slides {
        backface-visibility: visible; } }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-perspective: 2000;
    -webkit-backface-visibility: hidden; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

*,
*:after,
*:before {
  box-sizing: border-box; }

body {
  background: #F6F7F9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.no-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%; }

.wrapper {
  overflow-x: hidden; }

.inner-wrapper {
  transition: filter .4s ease-out; }
  .inner-wrapper--blurred {
    filter: blur(50px);
    position: relative; }
    .inner-wrapper--blurred:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background: #000000;
      opacity: 0.3; }

img:not([src]) {
  visibility: hidden; }

.js-focus-visible :focus:not(.focus-visible) {
  outline: none; }

*:focus, #cke_message:focus {
  border: 1px solid #FF2F00 !important; }

a:focus {
  border: 1px solid #FF2F00 !important; }

/*
*   Easing Equations ported to CSS by @matthewlein - http://matthewlein.com/ceaser/
*   Converted to SCSS vars by @pettpett
*
*   sample usage:
*   a {
*     color: #ff0000;
*     transition: color 0.5s $easeOutQuint;
*     &:hover,
*     &:focus {
*       color: #ff9900;
*     }
*   }
*/
/* Default Equations */
/* Penner Equations (approximated) originally created by @robpenner */
.u-container {
  max-width: calc(100% - 50px);
  margin: 0 25px;
  width: 100%; }
  @media (max-width: 374px) {
    .u-container {
      max-width: calc(100% - 30px);
      margin: 0 15px; } }
  @media (min-width: 1024px) {
    .u-container {
      max-width: 85%;
      width: 100%;
      margin: 0 auto; } }
  @media (min-width: 1280px) {
    .u-container {
      width: 100%;
      margin: 0 auto; } }
  @media (min-width: 1440px) {
    .u-container {
      max-width: 1000px; } }
  @media (min-width: 1660px) {
    .u-container {
      max-width: 1100px; } }
  @media (min-width: 1921px) {
    .u-container {
      width: 100%;
      max-width: 1500px; } }

@media (max-width: 1023px) {
  .u-mw-100-mobile {
    max-width: 100% !important;
    margin: 0 !important; } }

.u-relative {
  position: relative; }

@media (min-width: 1024px) {
  .u-container.u-container--xs {
    max-width: 660px;
    margin: 0 auto; } }

.u-screen-reader-text {
  position: absolute !important;
  display: block;
  visibility: visible;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0px, 0px, 0px, 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px); }

.u-fadereveal, [data-fadereveal] {
  visibility: hidden; }

.u-hidden {
  opacity: 0 !important;
  visibility: hidden !important; }

@media (min-width: 1024px) {
  .u-hidden-desktop {
    display: none !important; } }

@media (max-width: 1023px) {
  .u-hidden-mobile {
    display: none !important; } }

.u-no-margin {
  margin: 0 !important; }

@media (min-width: 1024px) {
  .u-no-pb-desktop {
    padding-bottom: 0 !important; } }

@media (min-width: 1024px) {
  .u-no-pt-desktop {
    padding-top: 0 !important; } }

@media (max-width: 1023px) {
  .u-no-pb-mobile {
    padding-bottom: 0 !important; } }

@media (max-width: 1023px) {
  .u-no-pt-mobile {
    padding-top: 0 !important; } }

@media (max-width: 1023px) {
  .u-no-mb-mobile {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .u-mb-tablet {
    margin-bottom: 20px !important; } }

@media (min-width: 1024px) {
  .u-mb-tablet {
    margin-bottom: 0px !important; } }

@media (max-width: 1023px) {
  .u-mt-mobile {
    margin-top: 30px !important; } }

@media (min-width: 1024px) {
  .u-mt-desktop {
    margin-top: 50px !important; } }

@media (min-width: 1024px) {
  .u-mt-desktop--small {
    margin-top: 30px !important; } }

@media (min-width: 1024px) {
  .u-mb-desktop {
    margin-bottom: 50px !important; } }

@media (min-width: 1024px) {
  .u-mb-desktop--small {
    margin-top: 30px !important; } }

.u-cover-title {
  font-family: "adelle-sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  letter-spacing: 0.3px;
  line-height: 30px;
  color: #FFFFFF; }
  @media (min-width: 1024px) {
    .u-cover-title {
      font-size: 42px;
      line-height: 50px; } }
  @media (min-width: 1660px) {
    .u-cover-title {
      font-size: 50px;
      line-height: 58px; } }

.u-cover-serie {
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2; }
  @media (min-width: 1024px) {
    .u-cover-serie {
      font-size: 15px;
      margin-bottom: 15px; } }
  .u-cover-serie a {
    border-bottom: 1px solid #FFF;
    color: #FFF;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    text-decoration: none; }
    @media (min-width: 1024px) {
      .u-cover-serie a {
        border-bottom: 2px solid #FFF; } }
    .u-cover-serie a:hover {
      color: #FF2F00;
      border-color: #FF2F00; }

.u-cover-authors {
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 24px;
  position: relative; }
  @media (min-width: 1024px) {
    .u-cover-authors {
      font-size: 15px;
      line-height: 25px; } }
  @media (min-width: 1660px) {
    .u-cover-authors {
      font-size: 18px;
      line-height: 28px; } }
  .u-cover-authors a {
    text-decoration: none !important;
    border-bottom: 1px solid #FFF;
    color: #FFF !important;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    @media (min-width: 1024px) {
      .u-cover-authors a {
        border-bottom: 2px solid #FFF; } }
    .u-cover-authors a:hover {
      color: #FF2F00 !important;
      border-color: #FF2F00; }

.u-cover-separator {
  display: block;
  background: #FF2F00;
  width: 113px;
  height: 2px; }
  @media (min-width: 1024px) {
    .u-cover-separator {
      width: 164px; } }
  @media (min-width: 1024px) {
    .u-cover-separator--large {
      width: 260px; } }

.u-cover-buttons {
  display: flex;
  margin-top: 30px; }
  .u-cover-buttons .btn--play {
    margin-right: 15px; }
    @media (min-width: 1024px) {
      .u-cover-buttons .btn--play {
        width: 280px;
        height: 50px; }
        .u-cover-buttons .btn--play .btn__icon-container:before {
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translateY(-50%); } }
    @media (min-width: 1024px) {
      .u-cover-buttons .btn--play .btn__label,
      .u-cover-buttons .btn--play .btn__duration {
        font-size: 18px; } }
  .u-cover-buttons .btn--subscribe {
    width: 150px;
    border-color: #949494; }
    @media (min-width: 768px) {
      .u-cover-buttons .btn--subscribe {
        display: none; } }
  .u-cover-buttons .btn--playlist {
    margin-right: 15px; }
    @media (min-width: 1024px) {
      .u-cover-buttons .btn--playlist {
        width: 120px;
        margin-right: 0px; } }
    .u-cover-buttons .btn--playlist:hover {
      background: #FFFFFF;
      border-color: #FFFFFF; }
      .u-cover-buttons .btn--playlist:hover .btn__icon-container:before {
        background-image: url("../static/svg/utils/add-to-playlist-active.svg"); }
  @media (min-width: 1024px) {
    .u-cover-buttons .btn--share {
      display: none; } }

.u-cover-chapo {
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5);
  color: #FFFFFF; }
  @media (min-width: 375px) {
    .u-cover-chapo {
      font-size: 17px;
      line-height: 28px; } }
  @media (min-width: 1024px) {
    .u-cover-chapo {
      font-size: 16px;
      line-height: 28px; } }
  @media (min-width: 1660px) {
    .u-cover-chapo {
      font-size: 20px;
      line-height: 32px; } }

.is-online {
  position: relative;
  background: #FF2F00;
  font-family: AdelleSansBold;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  border-radius: 3px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 14px; }
  .is-online:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    margin-right: 10px;
    border-radius: 50%; }

@media (max-width: 1023px) {
  .u-hide-mobile {
    display: none !important; } }

.u-full-width {
  width: 100%; }

.u-flex-wrap {
  flex-wrap: wrap; }

.u-flex-space-evenly {
  justify-content: space-evenly; }

.u-flex-container {
  position: relative;
  display: flex; }
  @media (min-width: 1024px) {
    .u-flex-container {
      max-width: calc(100% - 60px);
      margin: 30px auto; } }
  @media (max-width: 1023px) {
    .u-flex-container--desktop {
      display: block; } }

@media (max-width: 1023px) {
  .u-flex-column-mobile {
    flex-direction: column; } }

.u-bold {
  font-family: AdelleSansBold; }

.arrow-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .arrow-navigation {
      display: none; } }
  .arrow-navigation .arrow {
    position: relative;
    display: flex;
    border: solid 2px #DADADA;
    border-radius: 50px;
    width: 50px;
    max-width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .3s ease-out;
    text-decoration: none; }
    .arrow-navigation .arrow:hover {
      width: auto;
      max-width: 350px;
      background: white;
      border: solid 2px #FFFFFF; }
      .arrow-navigation .arrow:hover .arrow__content {
        opacity: 1;
        visibility: visible; }
    .arrow-navigation .arrow--prev {
      position: absolute;
      left: 30px; }
      .arrow-navigation .arrow--prev .arrow__img-container {
        left: -2px; }
      .arrow-navigation .arrow--prev .arrow__content {
        margin: 0 25px 0 50px; }
    .arrow-navigation .arrow--next {
      position: absolute;
      right: 30px; }
      .arrow-navigation .arrow--next .arrow__img-container {
        right: -2px; }
      .arrow-navigation .arrow--next .arrow__content {
        margin: 0 50px 0 25px; }
    .arrow-navigation .arrow .arrow__img-container {
      position: absolute;
      top: -2px;
      bottom: 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .arrow-navigation .arrow__content {
      opacity: 0;
      visibility: hidden;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: opacity .5s ease-out;
      transition-delay: 200ms; }
    .arrow-navigation .arrow .content__episode {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      color: #484848;
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .arrow-navigation .arrow .content__title {
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #FF2F00;
      font-size: 16px; }

.header .header__mobile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px;
  position: relative; }
  @media (max-width: 374px) {
    .header .header__mobile {
      padding: 25px 15px; } }
  @media (min-width: 1024px) {
    .header .header__mobile {
      display: none; } }
  .header .header__mobile .header__layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 120px;
    background-image: linear-gradient(#1c1c1c, rgba(28, 28, 28, 0));
    opacity: 0.5;
    pointer-events: none; }
  .header .header__mobile .mobile__home {
    margin-right: auto; }
  .header .header__mobile .mobile__home,
  .header .header__mobile .mobile__menu,
  .header .header__mobile .mobile__search {
    position: relative; }
  .header .header__mobile .mobile__menu {
    width: 20px;
    height: 14px;
    margin-top: 5px;
    margin-right: auto; }
    @media (min-width: 1024px) {
      .header .header__mobile .mobile__menu {
        height: 60px;
        width: 40px; } }
    .header .header__mobile .mobile__menu:before, .header .header__mobile .mobile__menu:after {
      content: "";
      display: block;
      margin: 0 auto;
      width: 20px;
      height: 14px;
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url("../static/svg/utils/hamburger-mobile.svg");
      transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (min-width: 1024px) {
        .header .header__mobile .mobile__menu:before, .header .header__mobile .mobile__menu:after {
          height: 30px;
          width: 20px; } }
  .header .header__mobile .icon-arte-radio {
    width: 75px;
    height: auto; }

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100; }
  @media (max-width: 1023px) {
    .header {
      position: fixed;
      z-index: 300; } }

.header .header__navigation {
  height: 100vh;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  position: absolute;
  top: 0;
  background-color: #F6F7F9; }
  @media (max-width: 1023px) {
    .header .header__navigation {
      overflow: auto;
      display: none;
      position: fixed; } }
  @media (min-width: 1024px) {
    .header .header__navigation {
      display: block;
      transform: none;
      height: auto;
      background-color: #FFFFFF; } }
  .header .header__navigation .navigation__top {
    display: flex;
    flex-direction: column; }
    @media (max-width: 1023px) {
      .header .header__navigation .navigation__top {
        padding: 60px 20px;
        box-shadow: 0 1px 0 0 #DADADA;
        position: relative;
        background-color: #FFFFFF; } }
    @media (min-width: 1024px) {
      .header .header__navigation .navigation__top {
        padding: 15px 30px;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #DADADA; } }
    @media (min-width: 1280px) {
      .header .header__navigation .navigation__top {
        padding: 15px 60px; } }
    @media (min-width: 1660px) {
      .header .header__navigation .navigation__top {
        padding: 30px 60px; } }
    .header .header__navigation .navigation__top .navigation__close {
      position: fixed;
      top: 50px;
      left: 25px;
      width: 12px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 374px) {
        .header .header__navigation .navigation__top .navigation__close {
          left: 15px; } }
      @media (min-width: 1024px) {
        .header .header__navigation .navigation__top .navigation__close {
          display: none; } }
    .header .header__navigation .navigation__top .navigation__baseline {
      display: flex;
      align-items: center;
      flex-direction: column; }
      @media (min-width: 1024px) {
        .header .header__navigation .navigation__top .navigation__baseline {
          flex-direction: row; } }
      .header .header__navigation .navigation__top .navigation__baseline .baseline__link {
        width: 90px;
        height: 44px;
        display: block;
        margin-bottom: 15px; }
        @media (max-width: 1023px) {
          .header .header__navigation .navigation__top .navigation__baseline .baseline__link {
            width: 110px;
            margin: 0; } }
        @media (min-width: 1024px) {
          .header .header__navigation .navigation__top .navigation__baseline .baseline__link {
            width: 90px;
            height: 44px;
            margin-bottom: 0; } }
        @media (min-width: 1660px) {
          .header .header__navigation .navigation__top .navigation__baseline .baseline__link {
            width: 110px;
            height: 56px; } }
        .header .header__navigation .navigation__top .navigation__baseline .baseline__link img {
          width: 100%;
          height: auto; }
      .header .header__navigation .navigation__top .navigation__baseline .baseline__description {
        font-family: "adelle-sans", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: #353535;
        line-height: 19px;
        margin-left: 30px; }
        @media (max-width: 1023px) {
          .header .header__navigation .navigation__top .navigation__baseline .baseline__description {
            display: none; } }
        @media (min-width: 1660px) {
          .header .header__navigation .navigation__top .navigation__baseline .baseline__description {
            font-size: 17px;
            line-height: 24px; } }
    .header .header__navigation .navigation__top .navigation__socials {
      display: flex;
      align-items: center; }
      @media (max-width: 1023px) {
        .header .header__navigation .navigation__top .navigation__socials {
          display: none; } }
      .header .header__navigation .navigation__top .navigation__socials .socials__link {
        width: 40px;
        height: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
        .header .header__navigation .navigation__top .navigation__socials .socials__link:not(:last-child) {
          margin-right: 15px; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link:hover {
          opacity: 0.7; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link:nth-last-child(2) {
          position: relative; }
          @media (min-width: 1024px) {
            .header .header__navigation .navigation__top .navigation__socials .socials__link:nth-last-child(2):after {
              content: '';
              position: absolute;
              top: 50%;
              right: -20px;
              transform: translateY(-50%);
              margin-left: 25px;
              display: block;
              height: 16px;
              width: 1px;
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: 1px;
              background-color: #DADADA; } }
        .header .header__navigation .navigation__top .navigation__socials .socials__link--wide {
          font-family: "adelle-sans", sans-serif;
          font-weight: 700;
          font-style: normal;
          width: auto;
          height: 30px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
          text-decoration: none;
          color: #353535;
          position: relative;
          transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          font-size: 15px;
          text-decoration: none;
          margin-left: 25px; }
          @media (max-width: 1023px) {
            .header .header__navigation .navigation__top .navigation__socials .socials__link--wide {
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: 100%;
              display: inline-flex;
              margin-top: 20px;
              margin-left: 0px; }
              .header .header__navigation .navigation__top .navigation__socials .socials__link--wide:after {
                content: none;
                display: none; } }
          .header .header__navigation .navigation__top .navigation__socials .socials__link--wide:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #FF2F00;
            transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            transform-origin: left;
            pointer-events: none; }
          @media (min-width: 1660px) {
            .header .header__navigation .navigation__top .navigation__socials .socials__link--wide {
              font-size: 16px; } }
          .header .header__navigation .navigation__top .navigation__socials .socials__link--wide:hover, .header .header__navigation .navigation__top .navigation__socials .socials__link--wide.is-active {
            opacity: 1;
            color: #FF2F00; }
            .header .header__navigation .navigation__top .navigation__socials .socials__link--wide:hover:after, .header .header__navigation .navigation__top .navigation__socials .socials__link--wide.is-active:after {
              transform: scaleX(0.5); }
        .header .header__navigation .navigation__top .navigation__socials .socials__link .link__logo.facebook {
          width: 10px;
          height: 19px; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link .link__logo.twitter {
          width: 19px;
          height: 16px; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link .link__logo.youtube {
          width: 24px;
          height: 17px; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link .link__logo.instagram {
          width: 22px; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link .link__logo.itunes {
          width: 20px;
          height: 21px; }
        .header .header__navigation .navigation__top .navigation__socials .socials__link .link__logo.deezer {
          height: 16px; }
  .header .header__navigation .navigation__wrapper-menu {
    display: flex;
    flex-direction: column; }
    @media (max-width: 1023px) {
      .header .header__navigation .navigation__wrapper-menu {
        background-color: #F6F7F9; } }
    @media (min-width: 1024px) {
      .header .header__navigation .navigation__wrapper-menu {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px; } }
    @media (min-width: 1280px) {
      .header .header__navigation .navigation__wrapper-menu {
        padding: 0 60px; } }
  .header .header__navigation .navigation__menu {
    display: flex;
    flex-direction: column;
    padding: 20px 25px; }
    @media (max-width: 1660px) {
      .header .header__navigation .navigation__menu {
        position: relative; } }
    @media (max-width: 1023px) {
      .header .header__navigation .navigation__menu {
        padding: 20px 0 0 0; } }
    @media (min-width: 1024px) {
      .header .header__navigation .navigation__menu {
        padding: 0;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        height: 65px; } }
    @media (min-width: 1660px) {
      .header .header__navigation .navigation__menu {
        height: 80px; } }
    .header .header__navigation .navigation__menu .menu__item {
      line-height: 55px; }
      @media (min-width: 1024px) {
        .header .header__navigation .navigation__menu .menu__item {
          line-height: 74px;
          align-items: center;
          text-align: center; } }
      .header .header__navigation .navigation__menu .menu__item .menu__link {
        text-decoration: none;
        color: #353535;
        position: relative;
        transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        font-family: "adelle-sans", sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 22px;
        line-height: 30px; }
        @media (max-width: 1023px) {
          .header .header__navigation .navigation__menu .menu__item .menu__link {
            padding: 0 20px; } }
        @media (min-width: 1024px) {
          .header .header__navigation .navigation__menu .menu__item .menu__link {
            font-family: "adelle-sans", sans-serif;
            font-weight: 700;
            font-style: normal;
            text-decoration: none;
            font-size: 15px;
            line-height: 30px; }
            .header .header__navigation .navigation__menu .menu__item .menu__link:not(.link--no-underline):before {
              content: '';
              position: absolute;
              bottom: 0px;
              left: 0;
              right: 0;
              height: 2px;
              background-color: #FF2F00;
              transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
              transform-origin: left;
              pointer-events: none; } }
        @media (min-width: 1660px) {
          .header .header__navigation .navigation__menu .menu__item .menu__link {
            font-size: 16px; } }
        .header .header__navigation .navigation__menu .menu__item .menu__link:hover, .header .header__navigation .navigation__menu .menu__item .menu__link.is-active {
          color: #FF2F00; }
          .header .header__navigation .navigation__menu .menu__item .menu__link:hover:before, .header .header__navigation .navigation__menu .menu__item .menu__link.is-active:before {
            transform: scaleX(0.5); }
        .header .header__navigation .navigation__menu .menu__item .menu__link.link--search {
          display: flex;
          margin-left: 25px; }
          @media (max-width: 1023px) {
            .header .header__navigation .navigation__menu .menu__item .menu__link.link--search {
              display: none; } }
          .header .header__navigation .navigation__menu .menu__item .menu__link.link--search:before {
            content: '';
            display: block;
            height: 16px;
            width: 1px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1px;
            margin-right: 35px;
            background-color: #DADADA; }
          .header .header__navigation .navigation__menu .menu__item .menu__link.link--search img {
            vertical-align: middle; }
          .header .header__navigation .navigation__menu .menu__item .menu__link.link--search:hover {
            opacity: 0.7; }
        .header .header__navigation .navigation__menu .menu__item .menu__link.btn.btn--borders {
          padding-bottom: 5px;
          padding-left: 35px;
          padding-right: 35px;
          line-height: 20px; }
          @media (max-width: 1023px) {
            .header .header__navigation .navigation__menu .menu__item .menu__link.btn.btn--borders {
              margin-top: 30px; } }
      .header .header__navigation .navigation__menu .menu__item.logo-arte {
        display: none;
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
        @media (min-width: 1024px) {
          .header .header__navigation .navigation__menu .menu__item.logo-arte {
            display: block; } }
        .header .header__navigation .navigation__menu .menu__item.logo-arte .icon-arte-radio {
          width: 85px;
          height: auto;
          vertical-align: middle; }
    .header .header__navigation .navigation__menu .menu__submenu {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #FF2F00;
      top: 100%;
      padding: 0 60px;
      opacity: 0;
      visibility: hidden;
      transition-duration: 200ms, 200ms;
      transition-property: opacity, visibility;
      transition-delay: 0, 200ms; }
      @media (max-width: 1660px) {
        .header .header__navigation .navigation__menu .menu__submenu {
          flex-direction: column;
          width: fit-content;
          text-align: left; } }
      @media (max-width: 1023px) {
        .header .header__navigation .navigation__menu .menu__submenu {
          height: 0;
          width: 100%;
          overflow: hidden;
          position: static; } }
      @media (max-width: 1023px) {
        .header .header__navigation .navigation__menu .menu__submenu.is-active {
          opacity: 1;
          visibility: visible;
          transition-delay: 0; } }
      @media (max-width: 1023px) {
        .header .header__navigation .navigation__menu .menu__submenu .submenu__item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } }
      @media (min-width: 1024px) {
        .header .header__navigation .navigation__menu .menu__submenu .submenu__item {
          padding-right: 50px; } }
      @media (max-width: 1660px) {
        .header .header__navigation .navigation__menu .menu__submenu .submenu__item {
          line-height: 55px; } }
      .header .header__navigation .navigation__menu .menu__submenu .submenu__item .submenu__link {
        font-family: "adelle-sans", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 16px;
        color: #F6F7F9;
        line-height: 20px;
        text-decoration: none;
        position: relative; }
        @media (max-width: 1023px) {
          .header .header__navigation .navigation__menu .menu__submenu .submenu__item .submenu__link {
            font-family: "adelle-sans", sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 20px;
            line-height: 52px; } }
        .header .header__navigation .navigation__menu .menu__submenu .submenu__item .submenu__link:before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;
          height: 2px;
          background-color: #FFF;
          transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          transform-origin: right;
          transform: scaleX(0);
          pointer-events: none; }
        .header .header__navigation .navigation__menu .menu__submenu .submenu__item .submenu__link:hover:before, .header .header__navigation .navigation__menu .menu__submenu .submenu__item .submenu__link.is-active:before {
          transform-origin: left;
          transform: scaleX(1); }
    .header .header__navigation .navigation__menu .menu__item.has-submenu .menu__link:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 7px;
      background-image: url("../static/svg/utils/dropdown.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 15px;
      vertical-align: middle; }
    .header .header__navigation .navigation__menu .menu__item.has-submenu:hover .menu__link {
      color: #FF2F00; }
      .header .header__navigation .navigation__menu .menu__item.has-submenu:hover .menu__link:before {
        transform: scaleX(0.5); }
    @media (min-width: 1024px) {
      .header .header__navigation .navigation__menu .menu__item.has-submenu:hover > .menu__link + .menu__submenu {
        opacity: 1;
        visibility: visible;
        transition-delay: 0; }
      .header .header__navigation .navigation__menu .menu__item.has-submenu:hover .menu__submenu {
        opacity: 1;
        visibility: visible;
        transition-delay: 0; } }
    @media (max-width: 1023px) {
      .header .header__navigation .navigation__menu .menu__item.has-submenu .is-active {
        height: auto; } }
    @media (min-width: 1024px) {
      .header .header__navigation .navigation__menu.navigation__menu--secondary {
        justify-content: flex-end; }
      .header .header__navigation .navigation__menu.navigation__menu--main {
        justify-content: space-between;
        flex-grow: 20;
        max-width: 1400px; }
        .header .header__navigation .navigation__menu.navigation__menu--main .menu__item {
          flex-grow: 1;
          display: flex;
          padding: 0;
          height: 100%; }
          .header .header__navigation .navigation__menu.navigation__menu--main .menu__item:nth-child(2) {
            text-align: left;
            justify-content: space-between;
            flex-grow: 0.7; }
          .header .header__navigation .navigation__menu.navigation__menu--main .menu__item.logo-arte {
            flex-grow: 0;
            width: 0; }
          .header .header__navigation .navigation__menu.navigation__menu--main .menu__item:last-child {
            text-align: right; }
            .header .header__navigation .navigation__menu.navigation__menu--main .menu__item:last-child:after {
              background: transparent !important; }
          .header .header__navigation .navigation__menu.navigation__menu--main .menu__item:not(.logo-arte):after {
            content: '';
            display: block;
            height: 16px;
            width: 1px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1px;
            background-color: #DADADA; }
          .header .header__navigation .navigation__menu.navigation__menu--main .menu__item:not(:nth-child(2)):after {
            margin-left: auto; }
          .header .header__navigation .navigation__menu.navigation__menu--main .menu__item:not(:nth-child(2)) .menu__link {
            margin-left: auto; } }

/************************
**** Minified styles ****
*************************/
.header.is-minified {
  position: fixed; }
  @media (min-width: 1024px) {
    .header.is-minified .header__navigation {
      transform: translate3d(0, -75px, 0); } }
  @media (min-width: 1660px) {
    .header.is-minified .header__navigation {
      transform: translate3d(0, -117px, 0); } }
  @media (min-width: 1024px) {
    .header.is-minified .navigation__menu.navigation__menu--main .menu__item.logo-arte {
      width: 75px;
      opacity: 1;
      display: block;
      margin-right: 50px; } }
  @media (min-width: 1660px) {
    .header.is-minified .navigation__menu.navigation__menu--main .menu__item.logo-arte {
      width: 85px; } }
  .header.is-minified .navigation__menu.navigation__menu--main .menu__item.logo-arte .icon-arte-radio {
    width: 85px; }

.main-footer {
  background-color: #ffffff;
  margin-top: 35px;
  padding-top: 10px;
  padding-bottom: 65px; }
  @media (min-width: 1024px) {
    .main-footer {
      display: block;
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 83px; } }
  @media (min-width: 768px) {
    .main-footer__top, .main-footer__bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between; } }
  @media (min-width: 768px) {
    .main-footer__links {
      display: flex;
      align-items: flex-start;
      flex-direction: row; } }
  .main-footer__link, .main-footer__lang {
    color: #484848;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
    .main-footer__link:hover, .main-footer__lang:hover {
      color: #FF2F00; }
  .main-footer__top {
    padding: 30px 0px 20px;
    border-bottom: solid 1px #DADADA; }
    @media (max-width: 1023px) {
      .main-footer__top {
        padding-bottom: 15px; } }
  .main-footer__store {
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .main-footer__store p {
      font-size: 16px; }
      @media (max-width: 767px) {
        .main-footer__store p {
          font-size: 12px; } }
      .main-footer__store p.headline__title {
        margin-top: 25px;
        padding: 0;
        font-family: "adelle-sans", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 24px; }
        @media (max-width: 767px) {
          .main-footer__store p.headline__title {
            font-size: 15px; } }
    .main-footer__store a {
      text-decoration: none !important; }
    @media (max-width: 767px) {
      .main-footer__store .specific-store {
        width: 45%; } }
  .main-footer__bottom {
    padding: 35px 0px; }
    .main-footer__bottom .main-footer__links .main-footer__link {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      line-height: 26px;
      margin-bottom: 10px;
      text-decoration: none;
      font-size: 14px;
      display: block;
      text-align: center; }
      @media (min-width: 768px) {
        .main-footer__bottom .main-footer__links .main-footer__link {
          text-align: left;
          font-size: 12px;
          margin-right: 15px;
          margin-bottom: 0; } }
      @media (min-width: 1024px) {
        .main-footer__bottom .main-footer__links .main-footer__link {
          font-size: 14px;
          margin-right: 20px; } }
      @media (min-width: 1280px) {
        .main-footer__bottom .main-footer__links .main-footer__link {
          font-size: 16px; } }
      .main-footer__bottom .main-footer__links .main-footer__link:last-child {
        margin-right: 0px; }
      .main-footer__bottom .main-footer__links .main-footer__link .icon-arte-radio {
        width: 85px;
        height: auto; }
        @media (min-width: 1024px) {
          .main-footer__bottom .main-footer__links .main-footer__link .icon-arte-radio {
            margin-bottom: 0;
            width: 90px; } }
    .main-footer__bottom .main-footer__langs {
      text-align: center; }
      @media (min-width: 768px) {
        .main-footer__bottom .main-footer__langs {
          text-align: left; } }
      @media (min-width: 1024px) {
        .main-footer__bottom .main-footer__langs {
          align-self: flex-start; } }
      .main-footer__bottom .main-footer__langs .main-footer__lang {
        font-family: "adelle-sans", sans-serif;
        font-weight: 600;
        font-style: normal;
        line-height: 26px;
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none; }
        .main-footer__bottom .main-footer__langs .main-footer__lang:hover:before {
          color: #484848; }
        .main-footer__bottom .main-footer__langs .main-footer__lang:before {
          content: ' - '; }
        .main-footer__bottom .main-footer__langs .main-footer__lang:first-child:before {
          content: ''; }
        @media (min-width: 768px) {
          .main-footer__bottom .main-footer__langs .main-footer__lang {
            font-size: 12px; } }
        @media (min-width: 1024px) {
          .main-footer__bottom .main-footer__langs .main-footer__lang {
            font-size: 14px; } }
        @media (min-width: 1280px) {
          .main-footer__bottom .main-footer__langs .main-footer__lang {
            font-size: 16px; } }

.cover-all {
  position: relative;
  background-color: #1C1C1C;
  overflow: hidden;
  height: 60vh;
  display: flex;
  align-items: center; }
  @media (max-width: 1023px) {
    .cover-all {
      display: none; } }
  .cover-all .cover-all__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .cover-all .cover-all__image-container:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
    .cover-all .cover-all__image-container .cover-all__image-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0; }
  .cover-all .cover-all__image {
    opacity: 0.6;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
  .cover-all .cover-all__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
    max-width: 50%; }
    .cover-all .cover-all__content .cover-all__surtitle {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-bottom: 20px; }
    .cover-all .cover-all__content .cover-all__title::first-letter {
      text-transform: capitalize; }
    .cover-all .cover-all__content .cover-all__separator {
      margin: 30px auto; }
    .cover-all .cover-all__content .cover-all__description {
      margin-top: 15px; }
    .cover-all .cover-all__content .cover-all__description,
    .cover-all .cover-all__content .cover-all__sounds {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px; }
      @media (min-width: 1024px) {
        .cover-all .cover-all__content .cover-all__description,
        .cover-all .cover-all__content .cover-all__sounds {
          font-size: 15px;
          line-height: 25px; } }
      @media (min-width: 1660px) {
        .cover-all .cover-all__content .cover-all__description,
        .cover-all .cover-all__content .cover-all__sounds {
          font-size: 18px;
          line-height: 28px; } }
      .cover-all .cover-all__content .cover-all__description .btn--play,
      .cover-all .cover-all__content .cover-all__sounds .btn--play {
        margin: 45px auto 0 auto; }
    .cover-all .cover-all__content .cover-all__sounds .u-bold:before, .cover-all .cover-all__content .cover-all__sounds .u-bold:after {
      content: ' '; }

.cover-classiques {
  position: relative;
  background-color: #1C1C1C;
  overflow: hidden;
  height: 60vh;
  display: flex;
  align-items: center; }
  @media (max-width: 1023px) {
    .cover-classiques {
      height: 70vh; } }
  .cover-classiques .cover-classiques__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .cover-classiques .cover-classiques__image-container:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
    .cover-classiques .cover-classiques__image-container .cover-classiques__image-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0; }
  .cover-classiques .cover-classiques__image {
    opacity: 0.6;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
  .cover-classiques .cover-classiques__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 50%; }
    @media (max-width: 1023px) {
      .cover-classiques .cover-classiques__content {
        width: 100%; } }
    .cover-classiques .cover-classiques__content .cover-classiques__separator {
      margin: 30px auto; }
    .cover-classiques .cover-classiques__content .cover-classiques__description {
      margin-top: 15px;
      width: 50%;
      margin: auto; }
      @media (max-width: 1023px) {
        .cover-classiques .cover-classiques__content .cover-classiques__description {
          width: 80%; } }
    .cover-classiques .cover-classiques__content .cover-classiques__description {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px; }
      @media (min-width: 1024px) {
        .cover-classiques .cover-classiques__content .cover-classiques__description {
          font-size: 15px;
          line-height: 25px; } }
      @media (min-width: 1660px) {
        .cover-classiques .cover-classiques__content .cover-classiques__description {
          font-size: 18px;
          line-height: 28px; } }

.cover-episodes {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  background-color: #1C1C1C;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px; }
  @media (min-width: 768px) {
    .cover-episodes {
      padding-bottom: 130px; } }
  @media (min-width: 1024px) {
    .cover-episodes {
      padding-top: 80px;
      padding-bottom: 150px; } }
  @media (min-width: 1660px) {
    .cover-episodes {
      padding-top: 130px;
      padding-bottom: 200px; } }
  @media (min-width: 2160px) {
    .cover-episodes {
      padding-bottom: 230px; } }
  .cover-episodes .cover-episodes__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; }
  .cover-episodes .cover-episodes__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: blur(50px); }
    @media (min-width: 768px) {
      .cover-episodes .cover-episodes__image-container {
        filter: none; } }
    .cover-episodes .cover-episodes__image-container:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
      @media (min-width: 1024px) {
        .cover-episodes .cover-episodes__image-container:after {
          top: 102%;
          height: 100%;
          background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%); } }
    .cover-episodes .cover-episodes__image-container .cover-episodes__image-wrapper {
      height: 80%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
      @media (min-width: 1024px) {
        .cover-episodes .cover-episodes__image-container .cover-episodes__image-wrapper {
          height: 100%; } }
  .cover-episodes .cover-episodes__image {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .cover-episodes .cover-episodes__image {
        height: 100%; } }
  .cover-episodes .cover-episodes__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 50px); }
  .cover-episodes .cover-episodes__illustration-container {
    box-shadow: 0 6px 64px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 110px);
    margin: auto;
    position: relative; }
    .cover-episodes .cover-episodes__illustration-container:before {
      content: '';
      display: block;
      padding-bottom: 77.5%;
      height: 0; }
    @media (min-width: 500px) {
      .cover-episodes .cover-episodes__illustration-container {
        width: calc(100% - 250px); } }
    @media (min-width: 768px) {
      .cover-episodes .cover-episodes__illustration-container {
        margin: 0;
        width: calc(45% - 50px); } }
    @media (min-width: 2160px) {
      .cover-episodes .cover-episodes__illustration-container {
        width: calc(40% - 50px); } }
    .cover-episodes .cover-episodes__illustration-container .cover-episodes__illustration {
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
  .cover-episodes .cover-episodes__information {
    text-align: center;
    margin: 20px auto 0;
    width: calc(100%);
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .cover-episodes .cover-episodes__information {
        text-align: left;
        margin-left: 60px;
        width: 40%;
        align-self: flex-start; } }
    @media (min-width: 2160px) {
      .cover-episodes .cover-episodes__information {
        margin-top: 30px;
        margin-left: 120px;
        width: 40%; } }
  .cover-episodes .cover-episodes__serie {
    order: 0; }
    .cover-episodes .cover-episodes__serie .serie__episode {
      margin-left: 5px; }
  .cover-episodes .cover-episodes__title {
    order: 1;
    margin-bottom: 10px; }
    @media (min-width: 1024px) {
      .cover-episodes .cover-episodes__title {
        margin-bottom: 0px;
        max-width: 500px; } }
    @media (min-width: 1660px) {
      .cover-episodes .cover-episodes__title {
        max-width: 670px; } }
  .cover-episodes .cover-episodes__authors {
    order: 2;
    z-index: 2; }
    @media (min-width: 1660px) {
      .cover-episodes .cover-episodes__authors {
        font-size: 15px;
        line-height: 25px; } }
  .cover-episodes .cover-episodes__chapo {
    order: 4; }
  .cover-episodes .cover-episodes__description {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #F6F7F9;
    font-size: 15px;
    line-height: 24px; }
    @media (min-width: 375px) {
      .cover-episodes .cover-episodes__description {
        font-size: 17px;
        line-height: 28px; } }
    @media (min-width: 1024px) {
      .cover-episodes .cover-episodes__description {
        font-size: 18px;
        line-height: 28px;
        text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5);
        max-width: 450px; } }
    @media (min-width: 1660px) {
      .cover-episodes .cover-episodes__description {
        max-width: 670px;
        font-size: 20px;
        line-height: 32px; } }
  .cover-episodes .cover-episodes__separator {
    order: 3;
    margin: 20px auto; }
    @media (min-width: 768px) {
      .cover-episodes .cover-episodes__separator {
        margin: 20px 0; } }
    @media (min-width: 2160px) {
      .cover-episodes .cover-episodes__separator {
        margin: 30px 0; } }
  .cover-episodes .cover-episodes__buttons {
    order: 5;
    margin-top: 20px;
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      .cover-episodes .cover-episodes__buttons {
        justify-content: flex-start; } }
    @media (min-width: 2160px) {
      .cover-episodes .cover-episodes__buttons {
        margin-top: 40px; } }

.cover-home {
  position: relative;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 0px;
  background-color: #1C1C1C;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .cover-home {
      height: 65vh;
      padding-top: 75px;
      padding-bottom: 50px; } }
  @media (min-width: 1660px) {
    .cover-home {
      height: 70vh;
      padding-bottom: 100px; } }
  .cover-home .cover-home__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  .cover-home .cover-home__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .cover-home .cover-home__image-container:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
      @media (min-width: 1024px) {
        .cover-home .cover-home__image-container:after {
          top: 100%;
          height: 100%;
          background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%); } }
    .cover-home .cover-home__image-container .cover-home__image-wrapper {
      height: 80%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
      @media (min-width: 1024px) {
        .cover-home .cover-home__image-container .cover-home__image-wrapper {
          height: 100%; } }
  .cover-home .cover-home__image {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .cover-home .cover-home__image {
        height: 100%; } }
  .cover-home .cover-home__content {
    position: relative;
    display: block; }
    @media (min-width: 500px) {
      .cover-home .cover-home__content {
        max-width: 60%; } }
    @media (min-width: 1024px) {
      .cover-home .cover-home__content {
        max-width: 100%;
        margin-left: 100px; } }
  .cover-home .cover-home__illustration {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 110px);
    margin: auto; }
    @media (min-width: 768px) {
      .cover-home .cover-home__illustration {
        margin: 0;
        width: calc(45% - 50px); } }
  .cover-home .cover-home__information {
    text-align: center;
    margin: 20px auto 0; }
    @media (min-width: 768px) {
      .cover-home .cover-home__information {
        text-align: left;
        margin-left: 50px;
        max-width: 40%;
        align-self: flex-start; } }
    .cover-home .cover-home__information .cover-home__authors {
      margin-top: 5px; }
  .cover-home .cover-home__serie .serie__episode {
    margin-left: 5px; }
  .cover-home .cover-home__title {
    letter-spacing: 0.3px; }
    @media (min-width: 1024px) {
      .cover-home .cover-home__title {
        max-width: 700px; } }
    @media (min-width: 1660px) {
      .cover-home .cover-home__title {
        max-width: 900px; } }
  .cover-home .cover-home__authors {
    text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    @media (min-width: 1024px) {
      .cover-home .cover-home__authors {
        margin-top: 10px; } }
    @media (min-width: 1660px) {
      .cover-home .cover-home__authors {
        margin-top: 15px; } }
  @media (min-width: 1024px) {
    .cover-home .cover-home__chapo {
      max-width: 450px; } }
  @media (min-width: 1660px) {
    .cover-home .cover-home__chapo {
      max-width: 670px; } }
  .cover-home .cover-home__separator {
    margin: 20px 0px 20px; }
    @media (min-width: 1024px) {
      .cover-home .cover-home__separator {
        margin-top: 15px;
        margin-bottom: 15px; } }
    @media (min-width: 1660px) {
      .cover-home .cover-home__separator {
        margin-top: 25px;
        margin-bottom: 25px; } }
  .cover-home .cover-home__buttons {
    margin-top: 30px;
    display: flex; }

.cover-list {
  position: relative;
  background-color: #1C1C1C;
  overflow: hidden;
  padding-top: 190px;
  padding-bottom: 430px; }
  @media (min-width: 1024px) {
    .cover-list {
      padding-top: 80px;
      padding-bottom: 390px; } }
  @media (min-width: 1660px) {
    .cover-list {
      padding-top: 120px;
      padding-bottom: 430px; } }
  .cover-list .cover-list__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; }
  .cover-list .cover-list__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 300px);
    width: 100%; }
    @media (min-width: 1024px) {
      .cover-list .cover-list__image-container {
        height: 100%; } }
    .cover-list .cover-list__image-container:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
      @media (min-width: 1024px) {
        .cover-list .cover-list__image-container:after {
          top: 100%;
          height: 100%;
          background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%); } }
    .cover-list .cover-list__image-container .cover-list__image-wrapper {
      height: 80%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
      @media (min-width: 1024px) {
        .cover-list .cover-list__image-container .cover-list__image-wrapper {
          height: 100%; } }
  .cover-list .cover-list__image {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .cover-list .cover-list__image {
        height: 100%; } }
  .cover-list .cover-list__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 2; }
    @media (min-width: 1024px) {
      .cover-list .cover-list__content {
        max-width: 60%; } }
  .cover-list .cover-list__title {
    order: 0; }
  .cover-list .cover-list__chapo {
    order: 4;
    text-align: left; }
    @media (min-width: 1024px) {
      .cover-list .cover-list__chapo {
        text-align: center; } }
  .cover-list .cover-list__authors {
    order: 2; }
    @media (min-width: 1024px) {
      .cover-list .cover-list__authors {
        margin-top: 10px; } }
  .cover-list .cover-list__separator {
    margin: 20px auto;
    order: 1; }
    @media (min-width: 1024px) {
      .cover-list .cover-list__separator {
        order: 2;
        margin: 25px auto; } }
  .cover-list .cover-list__buttons {
    justify-content: center;
    order: 3;
    margin-bottom: 80px; }
    @media (min-width: 768px) {
      .cover-list .cover-list__buttons {
        justify-content: flex-start; } }
    @media (min-width: 1024px) {
      .cover-list .cover-list__buttons {
        margin-bottom: 0px;
        margin-top: 35px;
        order: 4; } }
    .cover-list .cover-list__buttons .subscribe-container {
      margin: 0 auto; }

.embed-list {
  position: relative; }
  .embed-list:after {
    content: '';
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    transition: background 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    pointer-events: none; }
  .embed-list.is-hidden:after {
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto; }
  .embed-list .podcast-list {
    max-width: calc(100% - 30px);
    margin: auto; }
    .embed-list .podcast-list:first-child {
      border-top: 1px solid #DADADA;
      margin-top: 15px; }
    .embed-list .podcast-list:last-child {
      border-bottom: none; }
    .embed-list .podcast-list:hover {
      background: none; }
    .embed-list .podcast-list .podcast-list__inner {
      max-width: 100%;
      padding: 20px 0px;
      margin: 0px; }
    .embed-list .podcast-list__infos {
      flex-direction: column; }
    .embed-list .podcast-list .podcast-list__serie {
      font-size: 17px;
      line-height: 20px; }
    .embed-list .podcast-list .podcast-list__title {
      font-size: 13px;
      line-height: 15px;
      margin-top: 5px; }
    .embed-list .podcast-list .podcast-list__buttons {
      align-self: center; }
      .embed-list .podcast-list .podcast-list__buttons .btn--play {
        width: 85px;
        padding: 5px 17px;
        margin-right: 0px; }
        @media (max-width: 1023px) {
          .embed-list .podcast-list .podcast-list__buttons .btn--play {
            padding: 9px 12px;
            width: auto; } }
      .embed-list .podcast-list .podcast-list__buttons .btn__duration {
        font-size: 15px; }
        @media (max-width: 1023px) {
          .embed-list .podcast-list .podcast-list__buttons .btn__duration {
            font-size: 13px; } }

.embed-player {
  position: relative;
  display: flex;
  padding: 15px; }
  .embed-player__artwork {
    width: 96px;
    height: 96px;
    position: relative;
    margin-right: 15px;
    background-color: #000000;
    border-radius: 5px; }
    @media (max-width: 374px) {
      .embed-player__artwork {
        width: 70px;
        height: 70px; } }
    .embed-player__artwork .artwork__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
      width: 100%;
      border-radius: 5px;
      opacity: 0.8; }
    .embed-player__artwork .artwork__player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      z-index: 2;
      cursor: pointer; }
      .embed-player__artwork .artwork__player:hover:before {
        opacity: 0; }
      .embed-player__artwork .artwork__player:hover:after {
        opacity: 1; }
      .embed-player__artwork .artwork__player:before, .embed-player__artwork .artwork__player:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 25px;
        transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .embed-player__artwork .artwork__player:before {
        background-image: url("../static/svg/utils/play.svg"); }
      .embed-player__artwork .artwork__player:after {
        background-image: url("../static/svg/utils/play-active.svg");
        opacity: 0; }
      .embed-player__artwork .artwork__player.playing:before, .embed-player__artwork .artwork__player.playing:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 14px;
        height: 25px;
        transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .embed-player__artwork .artwork__player.playing:before {
        background-image: url("../static/svg/utils/pause.svg"); }
      .embed-player__artwork .artwork__player.playing:after {
        background-image: url("../static/svg/utils/pause-active.svg"); }
  .embed-player__titles-and-logos {
    display: flex;
    justify-content: space-between; }
  .embed-player__logos {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .embed-player__logos img {
      width: 100%; }
  .embed-player .logo-partner {
    height: 40px; }
    @media (max-width: 499px) {
      .embed-player .logo-partner {
        height: 30px; } }
  .embed-player .logo-arte {
    width: 60px; }
    @media (max-width: 499px) {
      .embed-player .logo-arte {
        width: 45px; } }
  .embed-player__content {
    color: #353535;
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .embed-player__content .content__serie {
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      margin-bottom: 10px; }
      .embed-player__content .content__serie .serie__title {
        color: #FF2F00;
        text-decoration: none;
        margin-right: 5px; }
      .embed-player__content .content__serie span {
        margin-left: 5px; }
        @media (max-width: 1023px) {
          .embed-player__content .content__serie span:before {
            content: attr(data-mobile); } }
        @media (min-width: 1024px) {
          .embed-player__content .content__serie span:after {
            content: attr(data-desktop); } }
    .embed-player__content .content__title {
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      color: #FF2F00;
      text-decoration: none;
      margin-bottom: 5px;
      display: block;
      max-width: 365px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .embed-player__content .content__chapo {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      line-height: 16px; }
    .embed-player__content .content__player {
      display: flex;
      align-items: center;
      margin-top: auto;
      margin-bottom: 0; }
      @media (max-width: 374px) {
        .embed-player__content .content__player {
          margin-top: 10px; } }
      .embed-player__content .content__player .player__navigation .navigation__btn {
        width: 12px;
        height: 15px;
        position: relative; }
        .embed-player__content .content__player .player__navigation .navigation__btn:before, .embed-player__content .content__player .player__navigation .navigation__btn:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1); }
        .embed-player__content .content__player .player__navigation .navigation__btn--previous {
          margin-right: 10px; }
          .embed-player__content .content__player .player__navigation .navigation__btn--previous:hover:before {
            opacity: 0; }
          .embed-player__content .content__player .player__navigation .navigation__btn--previous:hover:after {
            opacity: 1; }
          .embed-player__content .content__player .player__navigation .navigation__btn--previous:before {
            background-image: url("../static/svg/utils/previous-embed.svg"); }
          .embed-player__content .content__player .player__navigation .navigation__btn--previous:after {
            background-image: url("../static/svg/utils/previous-embed-active.svg");
            opacity: 0; }
        .embed-player__content .content__player .player__navigation .navigation__btn--next {
          margin-right: 15px; }
          .embed-player__content .content__player .player__navigation .navigation__btn--next:hover:before {
            opacity: 0; }
          .embed-player__content .content__player .player__navigation .navigation__btn--next:hover:after {
            opacity: 1; }
          .embed-player__content .content__player .player__navigation .navigation__btn--next:before {
            background-image: url("../static/svg/utils/next-embed.svg"); }
          .embed-player__content .content__player .player__navigation .navigation__btn--next:after {
            background-image: url("../static/svg/utils/next-embed-active.svg");
            opacity: 0; }
      .embed-player__content .content__player .player__timeline {
        flex-grow: 1;
        position: relative;
        cursor: pointer; }
        .embed-player__content .content__player .player__timeline .timeline__progress-bar,
        .embed-player__content .content__player .player__timeline .timeline__progress-current {
          position: absolute;
          top: 0;
          left: 0;
          height: 3px;
          transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform-origin: bottom center;
          border-radius: 2px; }
        .embed-player__content .content__player .player__timeline .timeline__progress-bar {
          width: 100%;
          background-color: #D8D8D8; }
        .embed-player__content .content__player .player__timeline .timeline__progress-current {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #FF2F00;
          pointer-events: none; }
        .embed-player__content .content__player .player__timeline .timeline__progress-handle {
          opacity: 0;
          position: absolute;
          top: -8px;
          left: 0;
          width: 16px;
          height: 16px;
          transform: translate(-50%, 0%) scale(0.8);
          transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
          border-radius: 50%;
          background-color: #FF2F00;
          box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.2); }
        .embed-player__content .content__player .player__timeline:hover .timeline__progress-handle {
          opacity: 1;
          transform: translate(-50%, 0%) scale(1); }
      .embed-player__content .content__player .player__time-infos {
        display: flex;
        align-items: center;
        font-family: "adelle-sans", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 12px;
        color: #353535;
        margin: 5px 15px 0px; }
      .embed-player__content .content__player .time-infos__current {
        color: #FF2F00; }
      .embed-player__content .content__player .time-infos__separator {
        margin: 0 5px;
        color: #FF2F00; }
      .embed-player__content .content__player .player__btn--share {
        position: relative;
        width: 16px;
        height: 13px; }
        .embed-player__content .content__player .player__btn--share:hover:after {
          opacity: 1; }
        .embed-player__content .content__player .player__btn--share:hover:before {
          opacity: 0; }
        .embed-player__content .content__player .player__btn--share:before, .embed-player__content .content__player .player__btn--share:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 16px;
          height: 13px;
          transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1); }
        .embed-player__content .content__player .player__btn--share:before {
          background-image: url("../static/svg/utils/share-embed.svg"); }
        .embed-player__content .content__player .player__btn--share:after {
          background-image: url("../static/svg/utils/share-active.svg");
          opacity: 0; }

.embed-sharings {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #F6F7F9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden; }
  .embed-sharings__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FF2F00;
    text-align: center;
    margin-bottom: 20px; }
  .embed-sharings__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer; }
  .embed-sharings__buttons {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 499px) {
      .embed-sharings__buttons {
        justify-content: flex-start;
        padding: 0 20px;
        overflow-x: scroll; }
        .embed-sharings__buttons:after {
          content: '';
          padding-right: 20px;
          display: block;
          width: 20px;
          height: 20px; } }
    .embed-sharings__buttons .btn {
      margin-right: 10px; }
      @media (max-width: 374px) {
        .embed-sharings__buttons .btn {
          padding: 5px 14px; } }
      @media (max-width: 499px) {
        .embed-sharings__buttons .btn {
          flex: 0 0 auto;
          box-shadow: none; } }
      @media (min-width: 768px) {
        .embed-sharings__buttons .btn {
          margin-right: 20px; } }
      .embed-sharings__buttons .btn:last-child {
        margin-right: 0px; }
    .embed-sharings__buttons .btn--twitter {
      font-size: 5px; }

.embed-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.embed-container {
  background-color: #F6F7F9;
  max-width: 100%;
  max-height: 100%;
  overflow: scroll; }

.embed-small {
  margin-bottom: 30px; }

@media (min-width: 1024px) {
  .layout {
    padding-top: 140px; } }

@media (min-width: 1660px) {
  .layout {
    padding-top: 197px; } }

.layout--embed {
  padding-top: 0px; }

.layout--search {
  position: relative; }

.podcast-card {
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 31px 36px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
  .podcast-card .podcast-card__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; }
  .podcast-card .podcast-card__image-container {
    position: relative;
    overflow: hidden; }
    .podcast-card .podcast-card__image-container .podcast-card__buttons {
      position: absolute;
      left: 15px;
      bottom: 15px; }
      @media (min-width: 375px) {
        .podcast-card .podcast-card__image-container .podcast-card__buttons {
          left: 20px;
          bottom: 20px; } }
      @media (min-width: 1024px) {
        .podcast-card .podcast-card__image-container .podcast-card__buttons .btn--play {
          display: flex; } }
      @media (min-width: 1660px) {
        .podcast-card .podcast-card__image-container .podcast-card__buttons .btn--playlist {
          width: 65px; } }
    .podcast-card .podcast-card__image-container:before {
      content: '';
      display: block;
      padding-bottom: 77.5%;
      height: 0; }
    .podcast-card .podcast-card__image-container .podcast-card__image {
      transition: all 1.6s cubic-bezier(0.19, 1, 0.22, 1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .podcast-card .podcast-card__image-container .btn--play {
        display: none; } }
  .podcast-card .podcast-card__content {
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto; }
    @media (max-width: 374px) {
      .podcast-card .podcast-card__content {
        padding: 15px; } }
    @media (min-width: 1024px) {
      .podcast-card .podcast-card__content {
        padding: 30px 20px; } }
    .podcast-card .podcast-card__content a {
      position: relative;
      z-index: 15; }
  .podcast-card .podcast-card__serie a {
    text-decoration: underline; }
  .podcast-card .podcast-card__serie,
  .podcast-card .podcast-card__authors,
  .podcast-card .podcast-card__number {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #353535;
    line-height: 24px; }
    @media (min-width: 1024px) {
      .podcast-card .podcast-card__serie,
      .podcast-card .podcast-card__authors,
      .podcast-card .podcast-card__number {
        font-size: 15px; } }
    .podcast-card .podcast-card__serie a,
    .podcast-card .podcast-card__authors a,
    .podcast-card .podcast-card__number a {
      color: #ff2f00;
      margin-right: 5px; }
    .podcast-card .podcast-card__serie .serie__episode,
    .podcast-card .podcast-card__authors .serie__episode,
    .podcast-card .podcast-card__number .serie__episode {
      margin-left: 5px; }
      @media (max-width: 1023px) {
        .podcast-card .podcast-card__serie .serie__episode:before,
        .podcast-card .podcast-card__authors .serie__episode:before,
        .podcast-card .podcast-card__number .serie__episode:before {
          content: attr(data-mobile); } }
      @media (min-width: 1024px) {
        .podcast-card .podcast-card__serie .serie__episode:after,
        .podcast-card .podcast-card__authors .serie__episode:after,
        .podcast-card .podcast-card__number .serie__episode:after {
          content: attr(data-desktop); } }
  .podcast-card .podcast-card__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 24px;
    color: #353535;
    line-height: 30px;
    margin: 10px 0; }
    @media (max-width: 374px) {
      .podcast-card .podcast-card__title {
        font-size: 22px;
        line-height: 27px;
        margin: 7px 0; } }
    @media (min-width: 1024px) {
      .podcast-card .podcast-card__title {
        font-size: 24px;
        margin: 10px 0; } }
    @media (min-width: 1660px) {
      .podcast-card .podcast-card__title {
        font-size: 26px; } }
  .podcast-card .podcast-card__description {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #353535;
    line-height: 26px;
    margin: 15px 0 23px 0; }
    @media (max-width: 374px) {
      .podcast-card .podcast-card__description {
        margin-top: 7px;
        font-size: 14px;
        line-height: 23px; } }
    @media (min-width: 1660px) {
      .podcast-card .podcast-card__description {
        font-size: 17px; } }
  .podcast-card .podcast-card__footer {
    margin: auto 0 0 0; }
    .podcast-card .podcast-card__footer a {
      color: gray;
      text-decoration: underline #ff2f00; }
      .podcast-card .podcast-card__footer a:hover {
        color: #ff2f00; }
  .podcast-card .podcast-card__buttons {
    display: flex;
    margin-top: auto;
    margin-bottom: 0px;
    flex-wrap: wrap; }
    .podcast-card .podcast-card__buttons .btn--playlist {
      margin-left: 15px; }
    .podcast-card .podcast-card__buttons .btn--subscribe {
      margin-left: 15px; }
  .podcast-card:hover {
    box-shadow: 0 0px 17px 0 rgba(0, 0, 0, 0.1);
    transform: scale3d(0.99, 0.99, 0.99); }

@media (min-width: 768px) {
  .podcast-card.podcast-card--full {
    flex-direction: row-reverse; }
    .podcast-card.podcast-card--full:hover {
      transform: scale(1);
      box-shadow: 0 31px 36px 0 rgba(0, 0, 0, 0.1); }
    .podcast-card.podcast-card--full .podcast-card__image-container {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .podcast-card.podcast-card--full .podcast-card__image-container:before {
        display: none;
        content: none; }
      .podcast-card.podcast-card--full .podcast-card__image-container .podcast-card__image {
        position: static;
        width: 200px;
        height: calc(100% - 80px);
        margin-right: 40px;
        border-radius: 5px; } }
      @media (min-width: 768px) and (min-width: 1024px) {
        .podcast-card.podcast-card--full .podcast-card__image-container .podcast-card__image {
          width: 300px; } }

@media (min-width: 768px) {
    .podcast-card.podcast-card--full .podcast-card__content {
      padding: 40px;
      max-width: 65%; } }
    @media (min-width: 768px) and (min-width: 1024px) {
      .podcast-card.podcast-card--full .podcast-card__content {
        max-width: 57%; } }

.podcast-card.podcast-card--background-full .podcast-card__buttons {
  display: flex;
  margin-top: 0;
  margin-bottom: 0px;
  flex-wrap: wrap; }
  .podcast-card.podcast-card--background-full .podcast-card__buttons .btn--playlist {
    margin-left: 15px; }
  .podcast-card.podcast-card--background-full .podcast-card__buttons .btn--subscribe {
    margin-left: 15px; }

.podcast-card.podcast-card--background-full .podcast-card__image-container {
  position: relative; }
  .podcast-card.podcast-card--background-full .podcast-card__image-container:before {
    background-color: #1c1c1c;
    padding-bottom: 140%; }
  .podcast-card.podcast-card--background-full .podcast-card__image-container:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: linear-gradient(-180deg, transparent 6%, #000000 100%); }
  .podcast-card.podcast-card--background-full .podcast-card__image-container .podcast-card__image {
    opacity: 0.6; }

.podcast-card.podcast-card--background-full .podcast-card__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  flex-grow: 1; }

.podcast-card.podcast-card--background-full .podcast-card__title {
  color: #ffffff;
  text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px; }
  @media (min-width: 375px) {
    .podcast-card.podcast-card--background-full .podcast-card__title {
      margin-bottom: 5px; } }
  @media (min-width: 1024px) {
    .podcast-card.podcast-card--background-full .podcast-card__title {
      font-size: 24px; } }
  @media (min-width: 1660px) {
    .podcast-card.podcast-card--background-full .podcast-card__title {
      font-size: 26px; } }

.podcast-card.podcast-card--background-full .podcast-card__description {
  color: #f6f7f9;
  text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  display: none; }
  @media (min-width: 375px) {
    .podcast-card.podcast-card--background-full .podcast-card__description {
      display: block; } }
  @media (min-width: 1660px) {
    .podcast-card.podcast-card--background-full .podcast-card__description {
      font-size: 17px; } }

.podcast-card.podcast-card--background-full .podcast-card__chapo {
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  margin-bottom: 25px; }
  @media (max-width: 374px) {
    .podcast-card.podcast-card--background-full .podcast-card__chapo {
      font-size: 14px;
      line-height: 23px; } }
  @media (min-width: 1660px) {
    .podcast-card.podcast-card--background-full .podcast-card__chapo {
      font-size: 17px; } }
  .podcast-card.podcast-card--background-full .podcast-card__chapo:before {
    content: '';
    background-color: #ff2f00;
    width: 120px;
    height: 2px;
    display: block;
    margin-bottom: 15px; }

.podcast-card.podcast-card--background-full.podcast-card-playlist .podcast-card__title {
  margin-bottom: 20px; }

.podcast-card.podcast-card--background-full:hover {
  transform: scale(1); }
  .podcast-card.podcast-card--background-full:hover .podcast-card__image {
    transform: scale(1.05);
    opacity: 0.8; }

@media (max-width: 767px) {
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto; } }

@media (min-width: 768px) {
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px); } }

@media (max-width: 1023px) {
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl {
    border-radius: 0;
    box-shadow: none; } }

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__image-container {
  position: relative; }
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__image-container:before {
    padding-bottom: 85%; }
    @media (min-width: 1024px) {
      .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__image-container:before {
        padding-bottom: 77.5%; } }

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__serie,
.podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__number {
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 24px; }
  @media (min-width: 1024px) {
    .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__serie,
    .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__number {
      font-size: 15px; } }
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__serie a,
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__number a {
    text-decoration: underline;
    color: #ffffff;
    margin-right: 5px; }

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__content {
  max-width: 85%; }
  @media (min-width: 375px) {
    .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .podcast-card__content {
      max-width: 100%; } }

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl .btn--playlist:hover {
  background-color: #ffffff;
  border: solid 2px #ffffff; }
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl .btn--playlist:hover .btn__icon-container:before {
    background-image: url("../static/svg/utils/add-to-playlist-active.svg"); }

.podcast-card.podcast-card--background-full.podcast-card--background-full--xl.podcast-card--background-full--wide .podcast-card__content {
  max-width: 100%; }
  .podcast-card.podcast-card--background-full.podcast-card--background-full--xl.podcast-card--background-full--wide .podcast-card__content .podcast-card__authors {
    color: #FFFFFF;
    margin-bottom: 10px; }
    .podcast-card.podcast-card--background-full.podcast-card--background-full--xl.podcast-card--background-full--wide .podcast-card__content .podcast-card__authors a {
      color: #FFFFFF; }

.podcast-list {
  position: relative;
  border-bottom: 1px solid #DADADA;
  transition: background 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
  @media (min-width: 1024px) {
    .podcast-list:hover {
      background: #FFF; }
      .podcast-list:hover .podcast-list__serie,
      .podcast-list:hover .podcast-list__title {
        text-decoration: underline; } }
  .podcast-list .podcast-list__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; }
  .podcast-list .podcast-list__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    position: relative; }
    @media (max-width: 374px) {
      .podcast-list .podcast-list__inner {
        padding: 30px 0; } }
    @media (min-width: 1280px) {
      .podcast-list .podcast-list__inner {
        padding: 40px 0; } }
  @media (max-width: 767px) {
    .podcast-list .podcast-list__infos {
      margin-left: 0;
      margin-right: auto; } }
  @media (min-width: 768px) {
    .podcast-list .podcast-list__infos {
      display: flex;
      flex: 1; } }
  .podcast-list .podcast-list__serie {
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 17px;
    letter-spacing: 0.3px;
    line-height: 20px;
    color: #353535;
    margin-bottom: 4px; }
    @media (min-width: 768px) {
      .podcast-list .podcast-list__serie {
        flex: 1; } }
    @media (min-width: 1024px) {
      .podcast-list .podcast-list__serie {
        font-size: 20px;
        margin-bottom: 0px; } }
    @media (min-width: 1660px) {
      .podcast-list .podcast-list__serie {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.39px;
        margin-right: 15px; } }
  .podcast-list .podcast-list__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    color: #353535; }
    @media (min-width: 768px) {
      .podcast-list .podcast-list__title {
        flex: 2; } }
    @media (min-width: 1024px) {
      .podcast-list .podcast-list__title {
        font-size: 18px; } }
    @media (min-width: 1660px) {
      .podcast-list .podcast-list__title {
        font-size: 22px;
        line-height: 26px; } }
  .podcast-list .podcast-list__buttons {
    display: flex;
    margin-left: 10px;
    align-self: center; }
    @media (max-width: 374px) {
      .podcast-list .podcast-list__buttons {
        align-items: flex-start;
        flex-direction: column; } }
    .podcast-list .podcast-list__buttons .btn--play {
      margin-right: 7px; }
      @media (max-width: 374px) {
        .podcast-list .podcast-list__buttons .btn--play {
          margin-right: 0;
          margin-bottom: 9px; } }
      @media (min-width: 1024px) {
        .podcast-list .podcast-list__buttons .btn--play {
          width: 115px;
          margin-right: 15px; } }

.podcast-list.podcast-list--in-selected {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .podcast-list.podcast-list--in-selected:hover {
    background: none; }
  @media (max-width: 1023px) {
    .podcast-list.podcast-list--in-selected:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .podcast-list.podcast-list--in-selected {
      border-bottom: 1px solid #DADADA;
      max-width: calc(100% - 170px);
      margin: auto; } }
  @media (min-width: 1280px) {
    .podcast-list.podcast-list--in-selected {
      max-width: 900px; } }
  @media (min-width: 1440px) {
    .podcast-list.podcast-list--in-selected {
      max-width: 1000px; } }
  @media (min-width: 1660px) {
    .podcast-list.podcast-list--in-selected {
      max-width: 1100px; } }
  @media (min-width: 1921px) {
    .podcast-list.podcast-list--in-selected {
      max-width: 1500px; } }
  .podcast-list.podcast-list--in-selected .podcast-list__serie {
    color: #FFFFFF; }
    @media (min-width: 1024px) {
      .podcast-list.podcast-list--in-selected .podcast-list__serie {
        color: #353535; } }
  .podcast-list.podcast-list--in-selected .podcast-list__title {
    color: #FFFFFF; }
    @media (min-width: 1024px) {
      .podcast-list.podcast-list--in-selected .podcast-list__title {
        color: #353535; } }

.podcast-full-width {
  position: relative;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 0px;
  background-color: #1C1C1C;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .podcast-full-width {
      height: 65vh;
      padding-top: 75px;
      padding-bottom: 50px; } }
  @media (min-width: 1660px) {
    .podcast-full-width {
      height: 70vh;
      padding-bottom: 100px; } }
  .podcast-full-width--expanded {
    height: 100vh;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .podcast-full-width--expanded {
        height: calc(65vh - 85px); } }
    @media (min-width: 1024px) {
      .podcast-full-width--expanded {
        display: block;
        padding-top: 175px;
        height: calc(100vh - 85px);
        min-height: 750px; } }
    .podcast-full-width--expanded .podcast__title {
      font-size: 28px;
      line-height: 32px; }
      @media (min-width: 1024px) {
        .podcast-full-width--expanded .podcast__title {
          font-size: 50px;
          line-height: 50px; } }
    .podcast-full-width--expanded .podcast__buttons {
      justify-content: center; }
      @media (min-width: 768px) {
        .podcast-full-width--expanded .podcast__buttons {
          justify-content: flex-start; } }
    .podcast-full-width--expanded .podcast__image-container {
      filter: blur(50px); }
      @media (min-width: 768px) {
        .podcast-full-width--expanded .podcast__image-container {
          filter: none; } }
    .podcast-full-width--expanded .podcast__content .podcast__separator {
      margin: 20px auto; }
      @media (min-width: 768px) {
        .podcast-full-width--expanded .podcast__content .podcast__separator {
          margin: 20px 0; } }
    .podcast-full-width--expanded .podcast__information {
      width: calc(100% - 80px); }
      @media (min-width: 768px) {
        .podcast-full-width--expanded .podcast__information {
          width: 100%; } }
      @media (max-width: 767px) {
        .podcast-full-width--expanded .podcast__information .podcast__serie {
          font-size: 12px; } }
  .podcast-full-width .podcast__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; }
  .podcast-full-width .podcast__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .podcast-full-width .podcast__image-container:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__image-container:after {
          top: 100%;
          height: 100%;
          background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%); } }
    .podcast-full-width .podcast__image-container .podcast__image-wrapper {
      height: 80%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__image-container .podcast__image-wrapper {
          height: 100%; } }
  .podcast-full-width .podcast__image {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__image {
        height: 100%; } }
  .podcast-full-width .podcast__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .podcast-full-width .podcast__content--wide {
      display: block; }
      @media (min-width: 500px) {
        .podcast-full-width .podcast__content--wide {
          max-width: 60%; } }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__content--wide {
          max-width: 100%;
          margin-left: 100px; } }
  .podcast-full-width .podcast__illustration {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 110px);
    margin: auto; }
    @media (min-width: 768px) {
      .podcast-full-width .podcast__illustration {
        margin: 0;
        width: calc(45% - 50px); } }
  .podcast-full-width .podcast__information {
    text-align: center;
    margin: 20px auto 0; }
    @media (min-width: 768px) {
      .podcast-full-width .podcast__information {
        text-align: left;
        margin-left: 50px;
        max-width: 40%;
        align-self: flex-start; } }
    .podcast-full-width .podcast__information .podcast__chapo {
      margin-top: 5px; }
  .podcast-full-width .podcast__serie {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
    margin-bottom: 10px; }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__serie {
        font-size: 15px;
        margin-bottom: 15px; } }
    .podcast-full-width .podcast__serie a {
      text-decoration: underline;
      color: #FFFFFF;
      margin-right: 5px; }
    .podcast-full-width .podcast__serie .serie__episode {
      margin-left: 5px; }
  .podcast-full-width .podcast__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 30px;
    color: #F6F7F9;
    letter-spacing: 0.3px;
    line-height: 30px; }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__title {
        font-size: 42px;
        line-height: 50px;
        text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5);
        max-width: 500px; } }
    @media (min-width: 1660px) {
      .podcast-full-width .podcast__title {
        font-size: 50px;
        line-height: 58px;
        max-width: 670px; } }
    .podcast-full-width .podcast__title--small {
      font-size: 26px; }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__title--small {
          font-size: 40px;
          line-height: 47px; } }
  .podcast-full-width .podcast__chapo {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #F6F7F9;
    line-height: 24px;
    text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__chapo {
        font-size: 15px;
        margin-top: 10px; } }
    @media (min-width: 1660px) {
      .podcast-full-width .podcast__chapo {
        font-size: 18px;
        margin-top: 15px; } }
  .podcast-full-width .podcast__description {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    color: #F6F7F9;
    line-height: 26px; }
    @media (min-width: 375px) {
      .podcast-full-width .podcast__description {
        font-size: 17px;
        line-height: 28px; } }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__description {
        font-size: 16px;
        line-height: 28px;
        text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5);
        max-width: 450px; } }
    @media (min-width: 1660px) {
      .podcast-full-width .podcast__description {
        font-size: 22px;
        line-height: 34px;
        max-width: 670px; } }
    .podcast-full-width .podcast__description--small {
      font-size: 15px;
      line-height: 24px; }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__description--small {
          font-size: 18px; } }
      @media (min-width: 1660px) {
        .podcast-full-width .podcast__description--small {
          font-size: 20px;
          line-height: 32px; } }
  .podcast-full-width .podcast__separator {
    display: block;
    background: #FF2F00;
    width: 113px;
    height: 2px;
    margin: 20px 0px 20px; }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__separator {
        width: 260px;
        margin-top: 15px;
        margin-bottom: 15px; } }
    @media (min-width: 1660px) {
      .podcast-full-width .podcast__separator {
        margin-top: 25px;
        margin-bottom: 25px; } }
  .podcast-full-width .podcast__buttons {
    margin-top: 30px;
    display: flex; }
    .podcast-full-width .podcast__buttons .btn--play {
      margin-right: 15px; }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__buttons .btn--play {
          width: 280px;
          height: 50px; }
          .podcast-full-width .podcast__buttons .btn--play .btn__icon-container:before {
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%); } }
    .podcast-full-width .podcast__buttons .btn--playlist {
      margin-right: 15px; }
      @media (min-width: 1024px) {
        .podcast-full-width .podcast__buttons .btn--playlist {
          width: 120px;
          margin-right: 0px; } }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__buttons .btn--share {
        display: none; } }
    @media (min-width: 1024px) {
      .podcast-full-width .podcast__buttons .btn__label, .podcast-full-width .podcast__buttons .btn__duration {
        font-size: 18px; } }

.podcast-description {
  position: relative;
  z-index: 10;
  background-color: #FFF;
  border-radius: 5px;
  margin-top: -30px; }
  @media (min-width: 768px) {
    .podcast-description {
      margin-top: -60px; } }
  @media (min-width: 1024px) {
    .podcast-description {
      margin-top: -80px; } }
  @media (min-width: 1660px) {
    .podcast-description {
      margin-top: -100px; } }
  @media (min-width: 2160px) {
    .podcast-description {
      margin-top: -120px; } }
  .podcast-description__inner {
    display: block;
    padding: 20px 25px; }
    @media (min-width: 768px) {
      .podcast-description__inner {
        padding: 40px 60px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse; } }
    @media (min-width: 1024px) {
      .podcast-description__inner {
        padding: 60px 90px; } }
    .podcast-description__inner .podcast-description__sidebar {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;
      margin-top: 25px; }
      @media (min-width: 768px) {
        .podcast-description__inner .podcast-description__sidebar {
          margin-top: 0px; } }
    .podcast-description__inner .podcast-description__main {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 55%; }
    .podcast-description__inner .podcast-description__credits {
      display: flex;
      flex-direction: column; }
      @media (max-width: 499px) {
        .podcast-description__inner .podcast-description__credits {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
          display: block; } }
      .podcast-description__inner .podcast-description__credits__item {
        color: #16113D;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px; }
        @media (min-width: 1024px) {
          .podcast-description__inner .podcast-description__credits__item {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px; } }
        .podcast-description__inner .podcast-description__credits__item .title {
          font-family: "adelle-sans", sans-serif;
          font-weight: 700;
          font-style: normal; }
        .podcast-description__inner .podcast-description__credits__item .content {
          font-family: "adelle-sans", sans-serif;
          font-weight: 400;
          font-style: normal; }
    .podcast-description__inner .podcast-description__preexisting-music {
      color: #FF2F00;
      text-decoration: underline;
      font-weight: bold; }
    .podcast-description__inner .podcast-description__preexisting-music__content {
      display: none; }
    .podcast-description__inner .podcast-description__quote {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      color: #FF2F00;
      font-size: 24px;
      line-height: 30px; }
      @media (min-width: 1024px) {
        .podcast-description__inner .podcast-description__quote {
          font-size: 36px;
          line-height: 42px; } }
    .podcast-description__inner .btn.btn--orange {
      display: inline-block;
      box-shadow: none;
      padding: 5px 25px; }
    .podcast-description__inner .podcast-description__content {
      font-family: "adelle-sans", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #353535;
      font-size: 15px;
      line-height: 26px;
      margin-top: 15px; }
      @media (min-width: 1024px) {
        .podcast-description__inner .podcast-description__content {
          font-size: 18px;
          line-height: 32px;
          margin-top: 30px; } }
      @media (min-width: 1921px) {
        .podcast-description__inner .podcast-description__content {
          font-size: 20px;
          line-height: 34px; } }
      .podcast-description__inner .podcast-description__content a {
        color: #353535;
        text-decoration: none;
        border-bottom: solid 2px #FF2F00;
        transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
        .podcast-description__inner .podcast-description__content a:hover {
          color: #ff2f00; }
    .podcast-description__inner .podcast-description__distribution .distribution-item {
      margin-top: 25px; }
      @media (min-width: 1024px) {
        .podcast-description__inner .podcast-description__distribution .distribution-item {
          margin-top: 45px; } }
      .podcast-description__inner .podcast-description__distribution .distribution-item__title, .podcast-description__inner .podcast-description__distribution .distribution-item__subtitle {
        font-size: 15px;
        line-height: 26px; }
        @media (min-width: 1024px) {
          .podcast-description__inner .podcast-description__distribution .distribution-item__title, .podcast-description__inner .podcast-description__distribution .distribution-item__subtitle {
            font-size: 18px;
            line-height: 32px; } }
        @media (min-width: 1921px) {
          .podcast-description__inner .podcast-description__distribution .distribution-item__title, .podcast-description__inner .podcast-description__distribution .distribution-item__subtitle {
            font-size: 20px;
            line-height: 34px; } }
      .podcast-description__inner .podcast-description__distribution .distribution-item__title {
        font-family: "adelle-sans", sans-serif;
        font-weight: 700;
        font-style: normal;
        color: #FF2F00; }
      .podcast-description__inner .podcast-description__distribution .distribution-item__subtitle {
        font-family: "adelle-sans", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #353535; }
      .podcast-description__inner .podcast-description__distribution .distribution-item__list .item {
        font-family: "adelle-sans", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #353535;
        position: relative;
        display: flex;
        font-size: 15px;
        line-height: 22px; }
        @media (min-width: 1024px) {
          .podcast-description__inner .podcast-description__distribution .distribution-item__list .item {
            font-size: 18px;
            line-height: 26px; } }
        @media (min-width: 1921px) {
          .podcast-description__inner .podcast-description__distribution .distribution-item__list .item {
            font-size: 20px;
            line-height: 28px; } }
        .podcast-description__inner .podcast-description__distribution .distribution-item__list .item:not(first-child) {
          margin-top: 7px; }
        .podcast-description__inner .podcast-description__distribution .distribution-item__list .item__disc {
          display: block;
          margin-top: 8px;
          width: 6px;
          height: 6px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 6px;
          border-radius: 25px;
          margin-right: 10px;
          background-color: #FF2F00; }
          @media (min-width: 1024px) {
            .podcast-description__inner .podcast-description__distribution .distribution-item__list .item__disc {
              margin-top: 12px;
              width: 8px;
              height: 8px;
              flex-basis: 8px; } }
        .podcast-description__inner .podcast-description__distribution .distribution-item__list .item__link {
          text-decoration: none;
          border-bottom: 2px solid #FF2F00;
          color: #353535;
          transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
          .podcast-description__inner .podcast-description__distribution .distribution-item__list .item__link:hover {
            color: #FF2F00; }
    .podcast-description__inner .podcast-description__distribution .separator {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #353535;
      margin-bottom: 25px; }

.selected-podcasts {
  position: relative;
  padding: 25px 0; }
  @media (min-width: 1024px) {
    .selected-podcasts {
      padding: 85px 0;
      overflow: visible; } }
  @media (min-width: 768px) {
    .selected-podcasts .selected-podcasts__list {
      flex-direction: column; } }
  .selected-podcasts .selected-podcasts__list .list__item {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal; }
    @media (min-width: 1024px) {
      .selected-podcasts .selected-podcasts__list .list__item {
        font-size: 28px;
        line-height: 46px; } }
    @media (min-width: 1280px) {
      .selected-podcasts .selected-podcasts__list .list__item {
        font-size: 36px;
        line-height: 52px; } }
    .selected-podcasts .selected-podcasts__list .list__item a {
      color: #353535;
      text-decoration: none;
      transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
      .selected-podcasts .selected-podcasts__list .list__item a:hover {
        color: #FF2F00; }
    .selected-podcasts .selected-podcasts__list .list__item span {
      color: #FF2F00; }
  .selected-podcasts .selected-podcasts__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #484848;
    margin-bottom: 30px; }
    @media (min-width: 1024px) {
      .selected-podcasts .selected-podcasts__header {
        padding-bottom: 25px;
        margin-bottom: 45px; } }
    .selected-podcasts .selected-podcasts__header .selected-podcasts__title {
      font-family: "adelle-sans", sans-serif;
      font-weight: 800;
      font-style: normal;
      font-size: 22px;
      color: #FF2F00;
      line-height: 20px; }
      @media (min-width: 1024px) {
        .selected-podcasts .selected-podcasts__header .selected-podcasts__title {
          font-size: 36px; } }
    .selected-podcasts .selected-podcasts__header .selected-podcasts__see-all {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: #FF2F00;
      text-align: right;
      line-height: 20px;
      transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
      .selected-podcasts .selected-podcasts__header .selected-podcasts__see-all:hover {
        color: #000; }
    .selected-podcasts .selected-podcasts__header .btn--playlist {
      width: 80px; }
      @media (min-width: 768px) {
        .selected-podcasts .selected-podcasts__header .btn--playlist:not(.btn--playlist-short) {
          width: 195px;
          height: 40px; } }
      .selected-podcasts .selected-podcasts__header .btn--playlist:hover {
        background: #FFF;
        border: solid 2px #FFF; }
        .selected-podcasts .selected-podcasts__header .btn--playlist:hover .selected-podcasts__numbers span {
          color: #FF2F00; }
        .selected-podcasts .selected-podcasts__header .btn--playlist:hover .btn__icon-container:before {
          background-image: url("../static/svg/utils/add-to-playlist-active.svg"); }
      .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span {
        font-family: "adelle-sans", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: #353535; }
        @media (min-width: 768px) {
          .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span:first-child:after {
            display: none; } }
        @media (max-width: 767px) {
          .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span:last-child {
            display: none; } }
        .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span:first-child:after, .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span:last-child:after {
          content: '|';
          margin: 0 5px;
          color: #D8D8D8; }
          @media (min-width: 768px) {
            .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span:first-child:after, .selected-podcasts .selected-podcasts__header .btn--playlist .selected-podcasts__numbers span:last-child:after {
              margin: 0 10px; } }
  @media (min-width: 1024px) {
    .selected-podcasts.is-negative--large {
      margin-top: -335px; } }
  .selected-podcasts.is-negative--wide {
    margin-top: -380px; }
    @media (min-width: 1024px) {
      .selected-podcasts.is-negative--wide {
        margin-top: -400px; } }
  .selected-podcasts.is-white .selected-podcasts__header {
    border-bottom-color: #FFF; }
    .selected-podcasts.is-white .selected-podcasts__header .selected-podcasts__title {
      color: #FFF; }
  @media (max-width: 1023px) {
    .selected-podcasts.is-white--desktop .selected-podcasts__header {
      border-bottom-color: #353535; }
      .selected-podcasts.is-white--desktop .selected-podcasts__header .selected-podcasts__title {
        color: #FF2F00; }
      .selected-podcasts.is-white--desktop .selected-podcasts__header .btn .selected-podcasts__numbers span {
        color: #353535; }
      .selected-podcasts.is-white--desktop .selected-podcasts__header .btn .btn__icon-container:before {
        background-image: url("../static/svg/utils/add-to-playlist.svg"); } }
  .selected-podcasts.is-white .btn .selected-podcasts__numbers span {
    color: #FFFFFF; }

.selected-podcasts .glide:hover .glide__arrows {
  opacity: 1; }

.selected-podcasts .glide.glide--mobile-only .glide__arrows {
  display: none; }

.selected-podcasts .glide .glide__arrows {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
  @media (max-width: 1023px) {
    .selected-podcasts .glide .glide__arrows {
      display: none; } }
  .selected-podcasts .glide .glide__arrows .glide__arrow {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    background: rgba(255, 255, 255, 0.96);
    width: 75px;
    height: 100%;
    pointer-events: auto;
    outline: none; }
    .selected-podcasts .glide .glide__arrows .glide__arrow::before {
      content: "";
      display: block;
      width: 30px;
      height: 17px;
      background: url("../static/svg/utils/back-active.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transform-origin: center; }
    @media (min-width: 1024px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--left {
        left: calc(((100vw - 100%) / 2) * -1); } }
    @media (min-width: 1280px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--left {
        left: calc(((100vw - 900px) / 2) * -1); } }
    @media (min-width: 1440px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--left {
        left: calc(((100vw - 1000px) / 2) * -1); } }
    @media (min-width: 1660px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--left {
        left: calc(((100vw - 1100px) / 2) * -1); } }
    @media (min-width: 1921px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--left {
        left: calc(((100vw - 100%) / 2) * -1); } }
    .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--right::before {
      transform: translate(-50%, -50%) rotate(-90deg); }
    @media (min-width: 1024px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--right {
        right: calc(((100vw - 100%) / 2) * -1); } }
    @media (min-width: 1280px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--right {
        right: calc(((100vw - 900px) / 2) * -1); } }
    @media (min-width: 1440px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--right {
        right: calc(((100vw - 1000px) / 2) * -1); } }
    @media (min-width: 1660px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--right {
        right: calc(((100vw - 1100px) / 2) * -1); } }
    @media (min-width: 1921px) {
      .selected-podcasts .glide .glide__arrows .glide__arrow.glide__arrow--right {
        right: calc(((100vw - 100%) / 2) * -1); } }

.selected-podcasts .selected-podcasts__load-more {
  margin-top: 30px; }
  @media (min-width: 1024px) {
    .selected-podcasts .selected-podcasts__load-more {
      margin-top: 50px; } }
  @media (max-width: 1023px) {
    .selected-podcasts .selected-podcasts__load-more {
      height: 80px; } }
  .selected-podcasts .selected-podcasts__load-more .btn--more {
    width: 100%;
    justify-content: center;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .selected-podcasts .selected-podcasts__load-more .btn--more {
        width: 530px; } }

@media (min-width: 375px) {
  .selected-podcasts.no-ms-mobile .selected-podcasts__grid {
    margin: 0px; } }

.selected-podcasts .selected-podcasts__grid {
  margin: 0 -20px; }
  @media (min-width: 375px) {
    .selected-podcasts .selected-podcasts__grid {
      margin: 0 -25px; } }
  @media (min-width: 768px) {
    .selected-podcasts .selected-podcasts__grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0px; } }
  @media (min-width: 768px) {
    .selected-podcasts .selected-podcasts__grid {
      justify-content: space-between; } }
  .selected-podcasts .selected-podcasts__grid--unflex {
    display: block; }
  @media (min-width: 768px) {
    .selected-podcasts .selected-podcasts__grid .podcast-card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 48.64%;
      margin-bottom: 20px; } }
  @media (min-width: 1024px) {
    .selected-podcasts .selected-podcasts__grid .podcast-card {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .selected-podcasts .selected-podcasts__grid .podcast-card--full {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%; } }

/****
*** Slideshow mobile-only
*** Here the style to apply only for desktop
**/
@media (min-width: 1024px) {
  .selected-podcasts .glide--mobile-only .glide__slides {
    flex-wrap: wrap;
    justify-content: space-between; }
    .selected-podcasts .glide--mobile-only .glide__slides:after {
      content: '';
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 31.454545%; } }
  @media (min-width: 1024px) and (min-width: 1921px) {
    .selected-podcasts .glide--mobile-only .glide__slides:after {
      content: '';
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 32%; } }

@media (min-width: 1024px) {
  .selected-podcasts .glide--mobile-only .glide__slide {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 31.454545%;
    margin-bottom: 30px; } }
  @media (min-width: 1024px) and (min-width: 1921px) {
    .selected-podcasts .glide--mobile-only .glide__slide {
      flex-basis: 32%; } }

.selected-podcasts.selected-podcasts--all {
  padding-top: 30px;
  max-width: 100%;
  margin: 0px; }
  @media (max-width: 1023px) {
    .selected-podcasts.selected-podcasts--all .selected-podcasts__grid {
      margin: 0px; } }
  @media (max-width: 1023px) {
    .selected-podcasts.selected-podcasts--all .podcast-card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 50%;
      margin-bottom: 0;
      border-radius: 0px; }
    .selected-podcasts.selected-podcasts--all .podcast-card__image-container:before {
      padding-bottom: 85%; } }
  @media (min-width: 1024px) {
    .selected-podcasts.selected-podcasts--all .selected-podcasts__grid:after {
      content: '';
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(50% - 17.5px); } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .selected-podcasts.selected-podcasts--all .selected-podcasts__grid:after {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(33.333333% - 17.5px); } }
  @media (min-width: 1024px) {
    .selected-podcasts.selected-podcasts--all .selected-podcasts__grid .glide__slide {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(50% - 17.5px);
      margin-bottom: 35px; } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .selected-podcasts.selected-podcasts--all .selected-podcasts__grid .glide__slide {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(33.333333% - 17.5px); } }

@media (min-width: 1024px) {
  .selected-podcasts--list .selected-podcasts__grid, .selected-podcasts--all .selected-podcasts__grid {
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (max-width: 1023px) {
  .selected-podcasts--list .selected-podcasts__grid .podcast-card {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    margin-bottom: 20px; }
    .selected-podcasts--list .selected-podcasts__grid .podcast-card .podcast-card__buttons {
      display: none; } }

@media (max-width: 1023px) and (max-width: 1023px) {
  .selected-podcasts--list.selected-podcasts--dark-mobile .selected-podcasts__list .list__item {
    padding: 10px 0px; }
    .selected-podcasts--list.selected-podcasts--dark-mobile .selected-podcasts__list .list__item:first-child {
      padding-top: 0; }
    .selected-podcasts--list.selected-podcasts--dark-mobile .selected-podcasts__list .list__item a {
      color: #353535; } }

@media (min-width: 1024px) {
  .selected-podcasts--list .selected-podcasts__grid:after {
    content: '';
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 31.454545%; } }

@media (min-width: 1024px) and (min-width: 1921px) {
  .selected-podcasts--list .selected-podcasts__grid:after {
    content: '';
    flex-basis: 0 0 32%; } }

@media (min-width: 1024px) {
  .selected-podcasts--list .selected-podcasts__grid .glide__slide {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 31.454545%;
    margin-bottom: 30px; } }
  @media (min-width: 1024px) and (min-width: 1921px) {
    .selected-podcasts--list .selected-podcasts__grid .glide__slide {
      flex-basis: 32%; } }

@media (max-width: 1023px) {
  .selected-podcasts--list {
    padding: 0px; }
    .selected-podcasts--list .selected-podcasts__header {
      display: none; }
    .selected-podcasts--list .selected-podcasts__grid {
      margin: 0; }
    .selected-podcasts--list .selected-podcasts__list .list__item {
      font-size: 20px;
      line-height: 20px;
      padding: 20px 0px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
      .selected-podcasts--list .selected-podcasts__list .list__item a {
        color: #FFFFFF; }
    .selected-podcasts--list .podcast-card {
      margin: 20px 0px;
      box-shadow: none;
      flex-direction: row;
      background: transparent;
      padding-bottom: 20px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      border-radius: 0px;
      align-items: flex-start; }
      .selected-podcasts--list .podcast-card:last-of-type {
        margin-bottom: 0px; }
      .selected-podcasts--list .podcast-card .podcast-card__image-container {
        margin-right: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 36%;
        border-radius: 5px; }
        .selected-podcasts--list .podcast-card .podcast-card__image-container:before {
          padding-bottom: 71.5%;
          background: none;
          border-radius: 5px; }
        .selected-podcasts--list .podcast-card .podcast-card__image-container:after {
          content: none; }
        .selected-podcasts--list .podcast-card .podcast-card__image-container .podcast-card__image {
          opacity: 1;
          border-radius: 5px; }
        .selected-podcasts--list .podcast-card .podcast-card__image-container .btn--play {
          position: absolute;
          left: 10px;
          bottom: 10px;
          width: 30px;
          height: 30px;
          padding: 0px; }
          .selected-podcasts--list .podcast-card .podcast-card__image-container .btn--play .btn__icon-container {
            margin-right: 0px; }
      .selected-podcasts--list .podcast-card .podcast-card__content {
        position: static;
        padding: 0px;
        display: block; }
        .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__title {
          text-shadow: none;
          color: #FFFFFF;
          font-size: 18px;
          line-height: 26px; } }
        @media (max-width: 1023px) and (min-width: 410px) {
          .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__title {
            font-size: 20px;
            line-height: 28px; } }

@media (max-width: 1023px) {
        .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__serie {
          color: #FFFFFF; }
          .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__serie a {
            color: #FFFFFF; }
        .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__description,
        .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__chapo,
        .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__authors {
          display: none; }
        .selected-podcasts--list .podcast-card .podcast-card__content .podcast-card__buttons {
          display: none; } }

.podcast-subscribe {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1C1C1C;
  overflow: hidden;
  margin-top: -30px;
  padding: 140px 0px 40px; }
  @media (min-width: 768px) {
    .podcast-subscribe {
      padding: 80px 0px 40px; } }
  @media (min-width: 1024px) {
    .podcast-subscribe {
      padding: 150px 0px 320px;
      margin-top: -70px; } }
  .podcast-subscribe__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%; }
    .podcast-subscribe__image-container:after {
      content: "";
      position: absolute;
      top: 102%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 0%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
      @media (min-width: 1024px) {
        .podcast-subscribe__image-container:after {
          display: none; } }
    @media (min-width: 1024px) {
      .podcast-subscribe__image-container {
        height: 100%;
        filter: blur(50px);
        transform: scale(1.2); } }
    .podcast-subscribe__image-container .podcast-subscribe__image {
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
      @media (min-width: 1024px) {
        .podcast-subscribe__image-container .podcast-subscribe__image {
          height: 100%; } }
  .podcast-subscribe .podcast-subscribe__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between; }
    .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__title {
      position: relative;
      text-align: center;
      font-size: 28px;
      line-height: 30px;
      color: white;
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%; }
      @media (min-width: 768px) {
        .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__title {
          margin-bottom: 30px; } }
      @media (min-width: 1024px) {
        .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__title {
          margin-bottom: 50px;
          font-size: 50px;
          line-height: 48px; } }
    .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content {
      overflow: hidden;
      align-self: flex-start;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%; }
      @media (min-width: 1024px) {
        .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content {
          flex-basis: 60%; } }
      @media (min-width: 1280px) {
        .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content {
          flex-basis: 55%; } }
      .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content .btn--subscribe {
        margin: 20px auto 80px; }
        @media (min-width: 768px) {
          .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content .btn--subscribe {
            display: none; } }
      .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content .content__description {
        font-family: "adelle-sans", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        line-height: 26px;
        color: #FFFFFF; }
        @media (min-width: 768px) {
          .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content .content__description {
            max-width: 80%;
            text-align: center;
            margin: auto; } }
        @media (min-width: 1024px) {
          .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__content .content__description {
            text-align: left;
            font-size: 18px;
            line-height: 28px;
            max-width: 100%; } }
    .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__illustration-container {
      display: none; }
      @media (min-width: 1024px) {
        .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__illustration-container {
          display: block;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 35%;
          position: relative; }
          .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__illustration-container:before {
            content: '';
            display: block;
            padding-bottom: 77.5%;
            height: 0; }
          .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__illustration-container .podcast-subscribe__illustration {
            border-radius: 5px;
            box-shadow: 0 6px 64px 0 rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover; object-position: center;'; } }
      @media (min-width: 1280px) {
        .podcast-subscribe .podcast-subscribe__inner .podcast-subscribe__illustration-container {
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 41%; } }

.selected-playlist {
  position: relative; }
  .selected-playlist .selected-playlist__header {
    height: 60vh;
    display: flex;
    align-items: center;
    text-align: center; }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__header {
        height: 70vh;
        position: relative; } }
  .selected-playlist .selected-playlist__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    overflow: hidden; }
    .selected-playlist .selected-playlist__image-container:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 0%, #1C1C1C 100%);
      height: 70%;
      width: 100%; }
      @media (min-width: 1024px) {
        .selected-playlist .selected-playlist__image-container:after {
          top: 100%;
          height: 70%;
          transform: translateY(-100%); } }
    .selected-playlist .selected-playlist__image-container .layer-dark {
      display: none; }
      @media (max-width: 1023px) {
        .selected-playlist .selected-playlist__image-container .layer-dark {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 30%;
          background: #1c1c1c;
          z-index: 0; } }
  .selected-playlist .selected-playlist__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (max-width: 1023px) {
      .selected-playlist .selected-playlist__image {
        height: 70%; } }
  .selected-playlist .selected-playlist__content {
    position: relative; }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__content {
        margin-top: -50px; } }
  .selected-playlist .selected-playlist__label {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 0.3px;
    line-height: 30px;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
    text-transform: uppercase; }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__label {
        font-size: 17px; } }
    @media (min-width: 1660px) {
      .selected-playlist .selected-playlist__label {
        font-size: 19px;
        letter-spacing: 1.27px; } }
  .selected-playlist .selected-playlist__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin-top: 10px;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5); }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__title {
        font-size: 42px;
        line-height: 50px;
        letter-spacing: 0.63px;
        margin: 20px 0; } }
    @media (min-width: 1660px) {
      .selected-playlist .selected-playlist__title {
        font-size: 50px;
        line-height: 58px;
        margin: 40px 0; } }
  .selected-playlist .selected-playlist__description {
    font-size: 20px;
    line-height: 32px;
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #F6F7F9;
    text-shadow: 0 1px 11px rgba(0, 0, 0, 0.5); }
    @media (max-width: 1023px) {
      .selected-playlist .selected-playlist__description {
        display: none; } }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__description {
        max-width: 95%;
        margin: auto; } }
    @media (min-width: 1660px) {
      .selected-playlist .selected-playlist__description {
        max-width: 100%;
        font-size: 22px;
        line-height: 34px; } }
  .selected-playlist .selected-playlist__btn {
    display: flex;
    justify-content: center;
    margin-top: 20px; }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__btn {
        margin-top: 25px; } }
    @media (min-width: 1660px) {
      .selected-playlist .selected-playlist__btn {
        margin-top: 45px; } }
  .selected-playlist .selected-playlist__podcasts {
    position: relative; }
    @media (min-width: 1024px) {
      .selected-playlist .selected-playlist__podcasts {
        margin-top: -50px; }
        .selected-playlist .selected-playlist__podcasts:before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          max-width: calc(100% - 50px);
          width: 100%;
          height: 100px;
          background-color: #F6F7F9;
          transform: translateX(-50%);
          border-radius: 5px; } }
    @media (min-width: 1440px) {
      .selected-playlist .selected-playlist__podcasts:before {
        max-width: 1290px; } }
    @media (min-width: 1921px) {
      .selected-playlist .selected-playlist__podcasts:before {
        max-width: 1630px; } }

.playlist-popin {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 20;
  pointer-events: none;
  opacity: 0;
  visibility: hidden; }
  .playlist-popin .popin-wrapper {
    position: absolute;
    bottom: 65px;
    width: 100%;
    height: 65px; }
    @media (min-width: 1024px) {
      .playlist-popin .popin-wrapper {
        height: 70px;
        bottom: 100px; } }
    .playlist-popin .popin-wrapper .popin-container {
      height: 100%;
      width: 100%;
      background-color: #FF2F00;
      display: flex;
      align-items: center;
      margin: auto;
      padding-left: 25px; }
      @media (max-width: 374px) {
        .playlist-popin .popin-wrapper .popin-container {
          padding-left: 15px; } }
      @media (min-width: 1024px) {
        .playlist-popin .popin-wrapper .popin-container {
          width: 85%;
          border-radius: 5px; } }
      @media (min-width: 1280px) {
        .playlist-popin .popin-wrapper .popin-container {
          max-width: 900px; } }
      @media (min-width: 1440px) {
        .playlist-popin .popin-wrapper .popin-container {
          max-width: 1000px; } }
      @media (min-width: 1660px) {
        .playlist-popin .popin-wrapper .popin-container {
          max-width: 1100px; } }
      .playlist-popin .popin-wrapper .popin-container .popin {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .playlist-popin .popin-wrapper .popin-container .popin__text {
          font-family: "adelle-sans", sans-serif;
          font-weight: 600;
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          color: #FFF; }
        .playlist-popin .popin-wrapper .popin-container .popin__close {
          cursor: pointer;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 50px;
          width: 50px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center; }
  .playlist-popin.fixed-bottom {
    z-index: 303; }
    .playlist-popin.fixed-bottom .popin-wrapper {
      bottom: 0px; }

.player__app-infos {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 65px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: white;
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 50px; }
  @media (min-width: 768px) {
    .player__app-infos {
      display: none; } }
  .player__app-infos__link, .player__app-infos__stay {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-weight: bold; }
  .player__app-infos__link {
    min-height: 50px;
    border-radius: 5px !important;
    color: white !important; }
  .player__app-infos__stay {
    text-decoration: underline; }
  .player__app-infos__close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal; }
    .player__app-infos__close:before {
      content: "\2715";
      color: #FF2F00;
      font-size: 30px; }

.player-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: #000000;
  z-index: 199;
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  visibility: hidden; }
  @media (min-width: 1024px) {
    .player-controls {
      height: 83px;
      z-index: 201; }
      .player-controls:hover {
        background-color: #171717; } }
  .player-controls .player-controls__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 25px;
    height: 100%;
    position: relative;
    width: 99vw; }
    @media (max-width: 374px) {
      .player-controls .player-controls__wrapper {
        padding: 13px 15px; } }

@media (max-width: 1023px) {
  .player-controls:before {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #FF2F00;
    display: block;
    position: absolute;
    top: -2px;
    left: 0;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateY(2px);
    opacity: 0; }
  .player-controls.playing:before {
    opacity: 1;
    transform: translateY(0px); } }

.player-controls .player-controls__timeline {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px; }
  @media (max-width: 1023px) {
    .player-controls .player-controls__timeline {
      display: none; } }
  .player-controls .player-controls__timeline .timeline__progress-bar,
  .player-controls .player-controls__timeline .timeline__progress-current {
    height: 2px;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-origin: bottom center; }
  .player-controls .player-controls__timeline .timeline__progress-bar {
    width: 100%;
    background-color: #686868; }
  .player-controls .player-controls__timeline .timeline__progress-current {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FF2F00;
    pointer-events: none; }
  .player-controls .player-controls__timeline .timeline__progress-handle {
    opacity: 0;
    position: absolute;
    top: -8px;
    left: 0;
    width: 16px;
    height: 16px;
    transform: translate(-50%, 0%) scale(0.8);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 50%;
    background-color: #FF2F00;
    box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.2); }
  .player-controls .player-controls__timeline:hover .timeline__progress-handle {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1); }

.player-controls:hover .timeline__progress-bar,
.player-controls:hover .timeline__progress-current {
  transform: scaleY(2); }

.player-controls .player-controls__now-playing {
  max-width: 75%;
  display: flex;
  align-items: center; }
  @media (min-width: 1024px) {
    .player-controls .player-controls__now-playing {
      max-width: 40%; } }
  @media (min-width: 1440px) {
    .player-controls .player-controls__now-playing {
      max-width: 75%; } }
  .player-controls .player-controls__now-playing .player-controls__artwork {
    height: 40px;
    width: 49px;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 49px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 0; }
    @media (min-width: 1024px) {
      .player-controls .player-controls__now-playing .player-controls__artwork {
        height: 50px;
        width: 61.25px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 61.25px; } }
    .player-controls .player-controls__now-playing .player-controls__artwork img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
  .player-controls .player-controls__now-playing .player-controls__title {
    margin-left: 15px;
    overflow: hidden;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    color: #F6F7F9;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media (min-width: 1024px) {
      .player-controls .player-controls__now-playing .player-controls__title {
        margin-left: 20px; } }
    @media (max-width: 767px) {
      .player-controls .player-controls__now-playing .player-controls__title {
        font-size: 16px; } }
    @media (max-width: 499px) {
      .player-controls .player-controls__now-playing .player-controls__title {
        font-size: 15px; } }
    @media (max-width: 410px) {
      .player-controls .player-controls__now-playing .player-controls__title {
        font-size: 14px; } }

.player-controls .player-controls__main-elements {
  display: flex; }
  @media (min-width: 1024px) {
    .player-controls .player-controls__main-elements {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }

.player-controls .player-controls__second-elements {
  display: flex; }
  @media (max-width: 1023px) {
    .player-controls .player-controls__second-elements {
      display: none; } }
  .player-controls .player-controls__second-elements .player-controls__time-infos {
    display: flex;
    align-items: center;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: #F6F7F9;
    line-height: 24px;
    margin-right: 20px; }
    .player-controls .player-controls__second-elements .player-controls__time-infos:after {
      content: "";
      width: 1px;
      height: 16px;
      background-color: #353535;
      margin-left: 30px; }
  .player-controls .player-controls__second-elements .time-infos__current, .player-controls .player-controls__second-elements .time-infos__separator {
    color: #FF2F00; }
  .player-controls .player-controls__second-elements .time-infos__current {
    width: 50px;
    text-align: right; }
  .player-controls .player-controls__second-elements .time-infos__separator {
    margin: 0 5px; }

.player-extend {
  background-color: #F6F7F9;
  position: fixed;
  z-index: 301;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: none; }
  @media (min-width: 1024px) {
    .player-extend {
      height: calc(100vh - 83px);
      z-index: 200; } }
  .player-extend__close {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 16px;
    height: 16px;
    z-index: 3; }
    @media (max-width: 1023px) {
      .player-extend__close {
        display: none; } }
  .player-extend.extended {
    display: block; }
  .player-extend .player-extend__scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-top: 30px; }
    @media (min-width: 1024px) {
      .player-extend .player-extend__scroll {
        height: calc(100% - 210px);
        padding-bottom: 50px; } }
  .player-extend .player-extend__current-podcast {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 35px; }
    @media (min-width: 1024px) {
      .player-extend .player-extend__current-podcast {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #171717;
        z-index: 21;
        max-width: calc(100% - 15px);
        margin-bottom: 0;
        padding: 25px 0px; } }
    @media (min-width: 1024px) {
      .player-extend .player-extend__current-podcast .current-podcast__wrapper {
        display: flex; } }
    .player-extend .player-extend__current-podcast .current-podcast__artwork {
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 24%;
      width: 24%;
      margin-right: 30px; }
      @media (max-width: 1023px) {
        .player-extend .player-extend__current-podcast .current-podcast__artwork {
          display: none; } }
      .player-extend .player-extend__current-podcast .current-podcast__artwork:before {
        content: "";
        display: block;
        height: 0;
        padding-bottom: 77.50%; }
      .player-extend .player-extend__current-podcast .current-podcast__artwork img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;'; }
        @media (min-width: 1024px) {
          .player-extend .player-extend__current-podcast .current-podcast__artwork img {
            border-radius: 5px; } }
      .player-extend .player-extend__current-podcast .current-podcast__artwork .is-online {
        position: absolute;
        top: 18px;
        left: 18px; }
        @media (min-width: 1024px) {
          .player-extend .player-extend__current-podcast .current-podcast__artwork .is-online {
            top: 18px;
            left: 18px; } }
        @media (min-width: 1660px) {
          .player-extend .player-extend__current-podcast .current-podcast__artwork .is-online {
            top: 10px;
            left: 10px; } }
    @media (min-width: 1024px) {
      .player-extend .player-extend__current-podcast .current-podcast__infos {
        padding-top: 15px; } }
    .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__serie {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px; }
      @media (min-width: 1024px) {
        .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__serie {
          font-size: 16px; } }
      @media (min-width: 1024px) {
        .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__serie .separator {
          color: #FFF; } }
      .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__serie a {
        color: #FF2F00;
        margin-right: 5px; }
      .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__serie .serie__episode {
        margin-left: 5px; }
        @media (min-width: 1024px) {
          .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__serie .serie__episode {
            color: #FFF; } }
    .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__title {
      font-family: "adelle-sans", sans-serif;
      font-weight: 800;
      font-style: normal;
      font-size: 24px;
      color: #353535;
      letter-spacing: 0.3px;
      line-height: 30px;
      margin: 15px 0px; }
      @media (min-width: 1024px) {
        .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__title {
          font-size: 24px;
          color: #FFF; } }
      @media (min-width: 1660px) {
        .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__title {
          font-size: 26px; } }
    .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__description {
      font-size: 17px;
      color: #353535;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: 5px; }
      @media (min-width: 1024px) {
        .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__description {
          max-width: 500px;
          color: #FFF;
          margin-bottom: 20px; } }
    .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__link {
      color: #FF2F00; }
      @media (min-width: 1024px) {
        .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__link {
          text-decoration: none;
          display: flex; }
          .player-extend .player-extend__current-podcast .current-podcast__infos .current-podcast__link:before {
            content: '';
            background-image: url("../static/svg/utils/arrow-oranger.svg");
            background-size: 16px 10px;
            width: 16px;
            height: 10px;
            margin-top: 2px;
            margin-right: 10px; } }
  .player-extend .player-extend__playlist .playlist__label {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #FF2F00;
    line-height: 20px;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;
    padding: 15px 0px; }
    @media (min-width: 1024px) {
      .player-extend .player-extend__playlist .playlist__label {
        border-top: none;
        font-size: 24px;
        line-height: 35px;
        padding: 2px 0px;
        padding-bottom: 30px; } }
    @media (min-width: 1660px) {
      .player-extend .player-extend__playlist .playlist__label {
        padding: 20px 0px; } }
  @media (min-width: 1024px) {
    .player-extend .player-extend__playlist .podcast-list__inner {
      padding: 25px 0px; } }
  @media (min-width: 1660px) {
    .player-extend .player-extend__playlist .podcast-list__inner {
      padding: 40px 0px; } }

.player-mobile {
  position: relative; }
  @media (min-width: 1024px) {
    .player-mobile {
      display: none; } }
  .player-mobile .player-mobile__artwork {
    position: relative;
    background-color: #1C1C1C; }
    .player-mobile .player-mobile__artwork:before {
      content: "";
      display: block;
      height: 0;
      padding-bottom: 56.54%; }
    .player-mobile .player-mobile__artwork:after {
      content: "";
      display: block;
      opacity: 0.7;
      background-image: linear-gradient(-180deg, #1C1C1C 0%, rgba(28, 28, 28, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .player-mobile .player-mobile__artwork img {
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
  .player-mobile .player-mobile__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px; }
  .player-mobile .player-mobile__top {
    display: flex;
    align-items: center; }
    .player-mobile .player-mobile__top .player-btn--share {
      margin-left: 10px; }
    .player-mobile .player-mobile__top .player-btn--back {
      margin-left: auto; }
  .player-mobile .player-mobile__controls {
    display: flex;
    justify-content: center; }
  .player-mobile .player-mobile__time-infos {
    display: flex;
    justify-content: space-between;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    color: #F6F7F9; }
    .player-mobile .player-mobile__time-infos .time-infos__current {
      color: #FF2F00; }
  .player-mobile .player-mobile__timeline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .player-mobile .player-mobile__timeline .timeline__progress-bar,
    .player-mobile .player-mobile__timeline .timeline__progress-current {
      height: 2px;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform-origin: bottom center; }
    .player-mobile .player-mobile__timeline .timeline__progress-bar {
      width: 100%;
      background-color: #686868; }
    .player-mobile .player-mobile__timeline .timeline__progress-current {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #FF2F00;
      pointer-events: none; }
    .player-mobile .player-mobile__timeline .timeline__progress-handle {
      position: absolute;
      bottom: -9px;
      left: 0;
      width: 22px;
      height: 22px;
      transform: translate(-50%, 0%);
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      border-radius: 50%;
      background-color: rgba(255, 47, 0, 0.22); }
      .player-mobile .player-mobile__timeline .timeline__progress-handle:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

.player-btn {
  width: 30px;
  height: 40px;
  position: relative; }
  @media (min-width: 1024px) {
    .player-btn {
      height: 60px;
      width: 40px; } }
  .player-btn:before, .player-btn:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 20px;
    height: 19px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (min-width: 1024px) {
      .player-btn:before, .player-btn:after {
        height: 30px;
        width: 20px; } }
  .player-btn:after {
    opacity: 0; }
  .player-btn:hover:before {
    opacity: 0; }
  .player-btn:hover:after {
    opacity: 1; }
  .player-btn.player-btn--play {
    margin: 0 15px; }
    @media (min-width: 1024px) {
      .player-btn.player-btn--play {
        margin: 0 25px; } }
    .player-btn.player-btn--play:before, .player-btn.player-btn--play:after {
      width: 16px; }
      @media (min-width: 1024px) {
        .player-btn.player-btn--play:before, .player-btn.player-btn--play:after {
          width: 16px; } }
    .player-btn.player-btn--play:before {
      background-image: url("../static/svg/utils/play.svg"); }
    .player-btn.player-btn--play:after {
      background-image: url("../static/svg/utils/play-active.svg"); }
  .player-btn.player-btn--play.playing:before {
    background-image: url("../static/svg/utils/pause.svg"); }
  .player-btn.player-btn--play.playing:after {
    background-image: url("../static/svg/utils/pause-active.svg"); }
  .player-btn.player-btn--prev:before {
    background-image: url("../static/svg/utils/previous.svg"); }
  .player-btn.player-btn--prev:after {
    background-image: url("../static/svg/utils/previous-active.svg"); }
  .player-btn.player-btn--next:before {
    background-image: url("../static/svg/utils/next.svg"); }
  .player-btn.player-btn--next:after {
    background-image: url("../static/svg/utils/next-active.svg"); }
  .player-btn.player-btn--random:before {
    background-image: url("../static/svg/utils/random.svg"); }
  .player-btn.player-btn--random:after {
    background-image: url("../static/svg/utils/random-active.svg"); }
  .player-btn.player-btn--share:before, .player-btn.player-btn--share:after {
    width: 16px; }
  .player-btn.player-btn--share:before {
    background-image: url("../static/svg/utils/share.svg"); }
  .player-btn.player-btn--share:after {
    background-image: url("../static/svg/utils/share-active.svg"); }
  .player-btn.player-btn--extend:before {
    background-image: url("../static/svg/utils/hamburger.svg"); }
  .player-btn.player-btn--extend:after {
    background-image: url("../static/svg/utils/hamburger-active.svg"); }
  .player-btn.player-btn--extend.extended:before, .player-btn.player-btn--extend.extended:after {
    width: 12px; }
  .player-btn.player-btn--extend.extended:before {
    background-image: url("../static/svg/utils/cross-white.svg"); }
  .player-btn.player-btn--extend.extended:after {
    background-image: url("../static/svg/utils/cross.svg"); }
  .player-btn.player-btn--add-playlist:before {
    background-image: url("../static/svg/utils/add-to-playlist-white.svg"); }
  .player-btn.player-btn--add-playlist:after {
    background-image: url("../static/svg/utils/add-to-playlist-active.svg"); }
  .player-btn.player-btn--back:before {
    background-image: url("../static/svg/utils/back.svg"); }
  .player-btn.player-btn--back:after {
    background-image: url("../static/svg/utils/back-active.svg"); }
  .player-btn.player-btn--xl:before, .player-btn.player-btn--xl:after {
    width: 25px;
    height: 30px; }
  .player-btn.player-btn--xl.player-btn--play {
    margin: 0 50px; }

.newsletter {
  display: flex;
  background-color: #F6F7F9;
  border-radius: 5px; }
  @media (max-width: 1023px) {
    .newsletter {
      flex-direction: column; } }

.newsletter-image {
  margin: auto 40px; }
  @media (max-width: 1023px) {
    .newsletter-image {
      margin: 30px auto; } }

.newsletter-subscription {
  padding: 0; }
  @media (min-width: 1024px) {
    .newsletter-subscription {
      padding: 40px 40px 40px 0;
      margin-bottom: 130px; } }
  @media (min-width: 768px) {
    .newsletter-subscription {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      flex-direction: column; } }
  .newsletter-subscription form {
    margin-top: 30px; }
  .newsletter-subscription input, .newsletter-subscription button {
    margin: 20px 0; }
    .newsletter-subscription input[type=button], .newsletter-subscription button[type=button] {
      border: none;
      color: white;
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 15px;
      text-align: center;
      line-height: 24px;
      text-transform: uppercase; }
  .newsletter-subscription .error, .newsletter-subscription .result {
    color: #FF2F00;
    font-weight: bold; }

.newsletter-subscription__headline .headline__title {
  margin: 5px 0;
  padding: 0;
  font-family: "adelle-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  color: #FF2F00;
  letter-spacing: 0.33px;
  text-align: left;
  line-height: 20px;
  text-transform: uppercase; }
  @media (min-width: 1024px) {
    .newsletter-subscription__headline .headline__title {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.49px; } }
  @media (max-width: 1023px) {
    .newsletter-subscription__headline .headline__title {
      padding-bottom: 30px;
      text-align: center; } }

.newsletter-subscription__headline .headline__subtitle {
  padding: 0;
  font-family: "adelle-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #353535;
  text-align: left;
  line-height: 20px; }

.newsletter-subscription__field {
  display: flex;
  flex-direction: column; }
  .newsletter-subscription__field label {
    font-weight: bold; }
  .newsletter-subscription__field input {
    width: 60%;
    margin: 10px 0 20px 0;
    border: 1px solid #A6A6A6;
    background-color: #E3E3E3;
    border-radius: 10px; }
    @media (max-width: 1023px) {
      .newsletter-subscription__field input {
        width: 100%; } }

.newsletter-subscription__checkbox {
  display: flex;
  align-items: center; }
  .newsletter-subscription__checkbox input {
    border: 1px solid #A6A6A6;
    background-color: #E3E3E3;
    border-radius: 5px; }
    .newsletter-subscription__checkbox input:checked {
      background-color: #FF2F00; }
  .newsletter-subscription__checkbox label {
    line-height: 20px; }

#newsletter_submit {
  margin: 30px 0 0 0;
  border-radius: 10px; }
  @media (max-width: 1023px) {
    #newsletter_submit {
      margin: 40px auto; } }

.btn {
  border-radius: 50px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  padding: 7px 17px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 20;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center; }
  .btn .btn__icon-container {
    margin-right: 10px; }
    .btn .btn__icon-container:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      background-size: contain;
      background-repeat: no-repeat; }
  .btn .btn__label {
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 15px;
    text-align: center;
    line-height: 24px; }
    @media (max-width: 374px) {
      .btn .btn__label {
        font-size: 13px; } }
  .btn .btn__duration {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    margin-right: 10px; }
    @media (max-width: 374px) {
      .btn .btn__duration {
        font-size: 13px;
        margin-right: 5px; } }
  .btn.btn--xs.btn--play .btn__icon-container:before {
    width: 9px;
    height: 12px; }
  @media (max-width: 1023px) {
    .btn.btn--xs {
      padding: 10px 12px; }
      .btn.btn--xs .btn__icon-container {
        margin-right: 5px; }
      .btn.btn--xs .btn__duration {
        font-size: 13px;
        line-height: 13px; }
      .btn.btn--xs.btn--play .btn__icon-container:before {
        width: 12px;
        height: 12px; } }
  .btn.btn--xl {
    padding: 10px 25px; }
  .btn.btn--no-label .btn__duration {
    margin-right: 0px; }
  .btn.btn--orange {
    color: #FFF;
    background: #FF2F00; }
    .btn.btn--orange:hover {
      background: #000; }
  .btn.btn--black {
    color: #FFFFFF;
    background: #000; }
    .btn.btn--black:hover {
      background: #FF2F00; }
  .btn.btn--light:hover {
    background: red; }
  .btn.btn--play {
    background: #FF2F00; }
    .btn.btn--play:hover {
      background: #000; }
    .btn.btn--play .btn__icon-container:before {
      width: 10px;
      height: 11px;
      background-image: url("../static/svg/utils/play-xs.svg"); }
    .btn.btn--play .btn__icon-container.playing:before {
      width: 10px;
      height: 11px;
      background-image: url("../static/svg/utils/pause.svg"); }
    .btn.btn--play .btn__label {
      color: #F6F7F9; }
    .btn.btn--play .btn__duration {
      color: #FFFFFF; }
  .btn.btn--play.btn--visual:hover {
    background: #FFFFFF; }
    .btn.btn--play.btn--visual:hover .btn__duration,
    .btn.btn--play.btn--visual:hover .btn__label {
      color: #FF2F00; }
    .btn.btn--play.btn--visual:hover .btn__icon-container:before {
      background-image: url("../static/svg/utils/play-xs-orange.svg"); }
    .btn.btn--play.btn--visual:hover .btn__icon-container.playing:before {
      background-image: url("../static/svg/utils/pause-active.svg"); }
  @media (min-width: 1280px) {
    .btn.btn--share {
      width: 65px; } }
  @media (min-width: 1660px) {
    .btn.btn--share {
      width: 90px; } }
  .btn.btn--share .btn__icon-container {
    margin-right: 0px; }
    .btn.btn--share .btn__icon-container:before {
      width: 15px;
      height: 13px;
      background-image: url("../static/svg/utils/share.svg"); }
  .btn.btn--share.btn--playlist-white .btn__icon-container:before {
    background-image: url("../static/svg/utils/share.svg"); }
  @media (min-width: 1024px) {
    .btn.btn--share.btn--playlist-black-desktop .btn__icon-container:before {
      background-image: url("../static/svg/utils/share.svg"); } }
  @media (min-width: 1280px) {
    .btn.btn--playlist {
      width: 65px; } }
  @media (min-width: 1660px) {
    .btn.btn--playlist {
      width: 90px; } }
  .btn.btn--playlist .btn__icon-container {
    margin-right: 0px; }
    .btn.btn--playlist .btn__icon-container:before {
      width: 20px;
      height: 13px;
      background-image: url("../static/svg/utils/add-to-playlist.svg"); }
  .btn.btn--playlist.btn--playlist-white .btn__icon-container:before {
    background-image: url("../static/svg/utils/add-to-playlist-white.svg"); }
  .btn.btn--playlist.btn--playlist-light {
    background: #FFFFFF;
    border-color: #FFFFFF;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1); }
    .btn.btn--playlist.btn--playlist-light .btn__icon-container:before {
      background-image: url("../static/svg/utils/add-to-playlist.svg"); }
  @media (min-width: 1024px) {
    .btn.btn--playlist.btn--playlist-black-desktop .btn__icon-container:before {
      background-image: url("../static/svg/utils/add-to-playlist.svg"); } }
  .btn.btn--search {
    background-color: transparent;
    padding: 0; }
    .btn.btn--search .btn__icon-container {
      margin-right: 0px; }
      .btn.btn--search .btn__icon-container:before {
        background-image: url("../static/svg/utils/arrow-right.svg"); }
    @media (min-width: 1024px) {
      .btn.btn--search {
        background-color: #FF2F00; }
        .btn.btn--search .btn__icon-container:before {
          background-image: url("../static/svg/utils/search-icon-white.svg"); } }
  .btn.btn--borders {
    box-shadow: none;
    border: 2px solid #DADADA; }
    .btn.btn--borders:hover {
      background: rgba(236, 236, 236, 0.6);
      border: 2px solid rgba(236, 236, 236, 0.7); }
      .btn.btn--borders:hover .btn__label {
        color: #FFF; }
    .btn.btn--borders .btn__label {
      color: #DADADA; }
    .btn.btn--borders.btn--white:hover {
      background: #FFF;
      border: 2px solid #FFF; }
      .btn.btn--borders.btn--white:hover .btn__label {
        color: #FF2F00; }
    .btn.btn--borders.btn--white .btn__label {
      color: #FFF; }
    .btn.btn--borders.btn--playlist-light {
      background: #FFFFFF;
      border-color: #FFFFFF; }
      .btn.btn--borders.btn--playlist-light:hover .btn__icon-container:before {
        background-image: url("../static/svg/utils/add-to-playlist-active.svg"); }
  .btn.btn--borders.btn--borders-orange {
    box-shadow: none;
    border: 2px solid #FF2F00; }
    .btn.btn--borders.btn--borders-orange:hover {
      background: #FF2F00; }
      .btn.btn--borders.btn--borders-orange:hover .btn__label {
        color: #FFF; }
    .btn.btn--borders.btn--borders-orange .btn__label {
      color: #FF2F00; }
  .btn.btn--facebook {
    color: #FFF;
    background-color: #3B5998; }
    .btn.btn--facebook:hover {
      background: #253a68; }
    .btn.btn--facebook .btn__icon-container:before {
      width: 7px;
      height: 17px;
      background-image: url("../static/svg/socials/facebook-white.svg"); }
  .btn.btn--twitter {
    color: #FFF;
    background-color: #6FAEDC; }
    .btn.btn--twitter:hover {
      background: #467799; }
    .btn.btn--twitter .btn__icon-container:before {
      width: 15px;
      height: 12px;
      background-image: url("../static/svg/socials/twitter-white.svg"); }
  .btn.btn--mail {
    color: #FFF; }
    .btn.btn--mail .btn__icon-container:before {
      width: 15px;
      height: 12px;
      background-image: url("../static/svg/socials/mail-icon.svg"); }
  .btn.btn--copy .btn__icon-container:before {
    width: 18px;
    height: 11px;
    background-image: url("../static/svg/utils/link.svg"); }
  .btn.btn--dl {
    color: #FFF; }
    .btn.btn--dl .btn__icon-container:before {
      width: 15px;
      height: 12px;
      background-image: url("../static/svg/socials/dl-icon.svg"); }
  .btn.btn--itunes {
    color: #FFF; }
    .btn.btn--itunes .btn__icon-container:before {
      width: 20px;
      height: 21px;
      background-image: url("../static/svg/socials/itunes-icon.svg"); }
  .btn.btn--spotify {
    color: #FFF; }
    .btn.btn--spotify .btn__icon-container:before {
      width: 24px;
      height: 24px;
      background-image: url("../static/svg/socials/spotify-icon.svg"); }
  .btn.btn--deezer {
    color: #FFF; }
    .btn.btn--deezer .btn__icon-container:before {
      width: 34px;
      height: 19px;
      background-image: url("../static/svg/socials/deezer-icon.svg"); }
  .btn.btn--rss {
    color: #FFF; }
    .btn.btn--rss .btn__icon-container:before {
      width: 17px;
      height: 18px;
      background-image: url("../static/svg/socials/rss-icon.svg"); }
  .btn.btn--google {
    color: #FFF; }
    .btn.btn--google .btn__icon-container:before {
      width: 20px;
      height: 20px;
      background-image: url("../static/svg/socials/google-icon.svg"); }
  .btn.btn--stitcher {
    color: #FFF; }
    .btn.btn--stitcher .btn__icon-container:before {
      width: 32px;
      height: 15px;
      background-image: url("../static/svg/socials/stitcher-icon.svg"); }
  .btn.btn--plus {
    color: #FFF;
    padding: 13px 38px; }
    .btn.btn--plus .btn__icon-container {
      margin-right: 0px; }
      .btn.btn--plus .btn__icon-container:before {
        width: 12px;
        height: 12px;
        background-image: url("../static/svg/utils/plus.svg"); }
  .btn--light.btn:hover {
    background: #ffffff;
    color: #FF2F00; }
  .btn--light.btn--itunes:hover .btn__icon-container:before {
    background-image: url("../static/svg/socials/itunes.svg"); }
  .btn--light.btn--spotify:hover .btn__icon-container:before {
    background-image: url("../static/svg/socials/spotify.svg"); }
  .btn--light.btn--deezer:hover .btn__icon-container:before {
    background-image: url("../static/svg/socials/deezer.svg"); }
  .btn--light.btn--rss:hover .btn__icon-container:before {
    background-image: url("../static/svg/socials/rss.svg"); }
  .btn--light.btn--plus:hover .btn__icon-container:before {
    background-image: url("../static/svg/utils/plus-orange.svg"); }

.btn-sharing {
  white-space: nowrap;
  padding-right: 25px;
  text-align: center;
  text-decoration: none; }
  @media (max-width: 374px) {
    .btn-sharing {
      padding-right: 15px; } }
  .btn-sharing__img {
    width: 88px;
    height: 88px;
    border-radius: 88px;
    display: flex;
    margin-bottom: 10px; }
    @media (max-width: 374px) {
      .btn-sharing__img {
        width: 78px;
        height: 78px;
        border-radius: 78px; } }
    .btn-sharing__img:before {
      content: '';
      margin: auto; }
  .btn-sharing--copy .btn-sharing__img {
    background: #FF2F00; }
    .btn-sharing--copy .btn-sharing__img:before {
      width: 40px;
      height: 40px;
      background-image: url("../static/svg/socials/copy-icon.svg");
      background-size: 40px 40px; }
      @media (max-width: 374px) {
        .btn-sharing--copy .btn-sharing__img:before {
          width: 30px;
          height: 30px;
          background-size: 30px 30px; } }
  .btn-sharing--twitter .btn-sharing__img {
    background: #6FAEDC; }
    .btn-sharing--twitter .btn-sharing__img:before {
      width: 27px;
      height: 22px;
      background-image: url("../static/svg/socials/tw-icon.svg");
      background-size: 27px 22px; }
  .btn-sharing--facebook .btn-sharing__img {
    background: #3B5998; }
    .btn-sharing--facebook .btn-sharing__img:before {
      width: 11px;
      height: 21px;
      background-image: url("../static/svg/socials/fb-icon.svg");
      background-size: 11px 21px; }
  .btn-sharing--whatsapp .btn-sharing__img {
    background: #45CC55; }
    .btn-sharing--whatsapp .btn-sharing__img:before {
      width: 30px;
      height: 30px;
      background-image: url("../static/svg/socials/whatsapp-icon.svg");
      background-size: 30px 30px; }
  .btn-sharing__text {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 28px;
    color: #353535; }
    @media (max-width: 374px) {
      .btn-sharing__text {
        font-size: 13px; } }

.filters {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none; }
  .filters__item {
    position: relative;
    width: 100%;
    text-align: center; }

.filters-close {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 60px;
  margin: auto; }
  .filters-close:before {
    content: '';
    width: 14px;
    height: 14px;
    background-image: url("../static/svg/utils/close-icon-orange.svg");
    background-size: cover; }

.filters-open .filters {
  bottom: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .filters-open .filters:before {
    position: absolute;
    top: 20px;
    font-size: 18px; }
  .filters-open .filters__item {
    padding: 20px 0px 20px 0px;
    background: transparent;
    border: none;
    font-size: 18px; }
    .filters-open .filters__item:hover {
      background-color: transparent; }

.filters-open .filters-close {
  display: flex;
  opacity: 1;
  visibility: visible; }

.filters-order {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: solid 2px #353535;
  padding: 32px 0px; }
  .filters-order li {
    width: 33%;
    text-align: center; }
    .filters-order li:nth-of-type(2) {
      border-left: solid 1px #DADADA;
      border-right: solid 1px #DADADA; }
  .filters-order__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333333%;
    text-align: center;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #353535;
    transition: color 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    cursor: pointer; }
    .filters-order__item:hover {
      color: #FF2F00; }
    .filters-order__item--active {
      color: #FF2F00; }

.filters-letter {
  z-index: 100; }
  .filters-letter#filters-absolute {
    position: absolute;
    top: 0;
    right: 0; }
  .filters-letter#filters-fixed {
    position: fixed; }
  .filters-letter.is-fixed {
    position: fixed;
    right: auto; }
  .filters-letter__item a {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: #484848;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    @media (max-width: 1023px) {
      .filters-letter__item a {
        font-size: 14px; } }
  .filters-letter__item:hover a {
    color: #FF2F00; }

.filters-categories {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 302; }
  @media (min-width: 1024px) {
    .filters-categories {
      display: none; } }
  .filters-categories .image {
    filter: blur(0px);
    transition: filter 1s ease-out; }
  .filters-categories.is-open {
    height: 100%; }
    .filters-categories.is-open .image {
      filter: blur(50px); }
  .filters-categories__current {
    position: absolute;
    opacity: 0;
    padding-top: 30px;
    z-index: 301;
    top: 0;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    pointer-events: none;
    cursor: pointer; }
    .filters-categories__current:after {
      content: '';
      background-image: url("../static/svg/utils/back.svg");
      margin-left: 10px;
      width: 13px;
      height: 13px;
      background-size: 13px 13px;
      display: inline-block;
      position: absolute;
      right: -20px;
      margin-top: 2px; }
  .filters-categories__container {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative; }
    .filters-categories__container .image-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: -1;
      background-color: #1c1c1c; }
      .filters-categories__container .image-container:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #1c1c1c;
        opacity: 0.6; }
      .filters-categories__container .image-container .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;'; }
  .filters-categories__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 30px; }
  .filters-categories__list {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    width: 90%;
    max-height: 50%;
    overflow: scroll; }
    .header .filters-categories__list {
      overflow-x: unset; }
    .filters-categories__list:-webkit-scrollbar {
      display: none; }
    .filters-categories__list .list__item {
      text-align: center; }
      .filters-categories__list .list__item:not(:last-child) {
        margin-bottom: 40px; }
      .filters-categories__list .list__item p {
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 15px;
        font-family: "adelle-sans", sans-serif;
        font-weight: 700;
        font-style: normal; }
      .filters-categories__list .list__item a, .filters-categories__list .list__item span, .filters-categories__list .list__item p {
        color: #BBBBBB;
        text-decoration: none; }
      .filters-categories__list .list__item--active a {
        color: #FFFFFF; }
      .filters-categories__list .list__item--active span, .filters-categories__list .list__item--active p {
        color: #FF2F00; }
  .filters-categories .js-filters-close {
    width: 60px;
    height: 60px;
    margin-bottom: 60px;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    position: relative; }
    .filters-categories .js-filters-close:before {
      content: '';
      width: 14px;
      height: 14px;
      background-image: url("../static/svg/utils/close-icon-orange.svg");
      background-size: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

/**************************  Basic Modal Styles
\**************************/
.modal, .micromodal-slide {
  position: relative;
  z-index: 301; }

@media (max-width: 1023px) {
  .modal--no-padding .modal__container {
    padding: 0px; } }

@media (max-width: 1023px) {
  .modal--no-cross .modal__close {
    display: none; } }

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .modal__overlay {
      align-items: flex-end; } }

.modal__container {
  padding: 50px 45px;
  max-width: 910px;
  width: 100%;
  max-height: 100vh;
  border-radius: 5px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  background: #F6F7F9;
  box-shadow: 0 0 104px 0 rgba(0, 0, 0, 0.8); }
  @media (max-width: 767px) {
    .modal__container {
      padding: 40px 30px;
      border-radius: 0px; } }

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box; }

.modal__close {
  background: transparent;
  border: 0;
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer; }

.modal__close:before {
  content: "\2715";
  font-size: 20px; }

.modal__content {
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8); }
  @media (max-width: 1023px) {
    .modal__content {
      padding: 15px; } }

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out; }

/**************************  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from {
    transform: translateY(15%); }
  to {
    transform: translateY(0); } }

@keyframes mmslideOut {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(15%); } }

.micromodal-slide {
  display: none; }

.micromodal-slide.is-open {
  display: block; }

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform; }

@media (min-width: 1024px) {
  .micromodal-slide .modal__sharings {
    display: none; } }

.micromodal-slide .modal__sharings .sharings__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #DADADA;
  padding: 10px 25px; }
  @media (max-width: 374px) {
    .micromodal-slide .modal__sharings .sharings__header {
      padding: 10px 15px; } }
  .micromodal-slide .modal__sharings .sharings__header .header__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #353535; }
  .micromodal-slide .modal__sharings .sharings__header .header__close {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 28px;
    text-decoration: underline;
    color: #FF2F00; }

.micromodal-slide .modal__sharings .sharings__buttons {
  padding: 35px 25px;
  display: flex;
  overflow-x: scroll; }
  @media (max-width: 374px) {
    .micromodal-slide .modal__sharings .sharings__buttons {
      padding: 25px 15px; } }
  @media (min-width: 500px) {
    .micromodal-slide .modal__sharings .sharings__buttons {
      justify-content: center; } }

.micromodal-slide .modal__current-podcast {
  display: flex;
  width: 100%;
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal; }
  .micromodal-slide .modal__current-podcast .current-podcast__artwork {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 95px;
    height: 95px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: #000;
    margin-right: 30px; }
    .micromodal-slide .modal__current-podcast .current-podcast__artwork img {
      opacity: 0.75;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
  .micromodal-slide .modal__current-podcast .current-podcast__serie {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px; }
    .micromodal-slide .modal__current-podcast .current-podcast__serie a {
      color: #FF2F00;
      margin-right: 5px; }
    .micromodal-slide .modal__current-podcast .current-podcast__serie .serie__episode {
      margin-left: 5px; }
  .micromodal-slide .modal__current-podcast .current-podcast__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 24px;
    color: #353535;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin: 5px 0px; }
  .micromodal-slide .modal__current-podcast .current-podcast__description {
    font-size: 17px;
    color: #353535;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 25px; }
    .micromodal-slide .modal__current-podcast .current-podcast__description ul {
      padding: 10px 17px;
      list-style: square; }
    .micromodal-slide .modal__current-podcast .current-podcast__description p {
      margin: 10px 0; }

.micromodal-slide .modal__subscribe .subscribe {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column; }
  .micromodal-slide .modal__subscribe .subscribe .subscribe__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: #FF2F00;
    margin-top: 65px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .micromodal-slide .modal__subscribe .subscribe .subscribe__title {
        margin-top: 5px; } }
  .micromodal-slide .modal__subscribe .subscribe .current-podcast__title {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 42px;
    line-height: 46px;
    color: #353535;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin: 20px 0px 50px; }
    @media (max-width: 767px) {
      .micromodal-slide .modal__subscribe .subscribe .current-podcast__title {
        font-size: 24px;
        line-height: 28px;
        margin: 10px 0px 35px; } }
  .micromodal-slide .modal__subscribe .subscribe .buttons {
    width: calc(100% - 100px);
    justify-content: space-between;
    margin-bottom: 30px; }
    .micromodal-slide .modal__subscribe .subscribe .buttons:last-child {
      margin-bottom: 50px;
      justify-content: center; }
      @media (max-width: 767px) {
        .micromodal-slide .modal__subscribe .subscribe .buttons:last-child {
          margin-bottom: 0px; } }
    @media (max-width: 767px) {
      .micromodal-slide .modal__subscribe .subscribe .buttons {
        flex-direction: column;
        margin-bottom: 20px;
        width: 100%; } }
    .micromodal-slide .modal__subscribe .subscribe .buttons .btn {
      width: 100%; }
      @media (max-width: 767px) {
        .micromodal-slide .modal__subscribe .subscribe .buttons .btn {
          margin-bottom: 20px; } }
      @media (max-width: 767px) {
        .micromodal-slide .modal__subscribe .subscribe .buttons .btn:last-child {
          margin-bottom: 0px; } }
      .micromodal-slide .modal__subscribe .subscribe .buttons .btn--google {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 32%; }
      .micromodal-slide .modal__subscribe .subscribe .buttons .btn--stitcher {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 28%; }

@media (max-width: 1023px) {
  .micromodal-slide .modal__tabs {
    display: none; } }

.micromodal-slide .modal__subscribe .buttons,
.micromodal-slide .modal__tabs .buttons {
  margin-bottom: 40px; }
  @media (min-width: 1024px) {
    .micromodal-slide .modal__subscribe .buttons,
    .micromodal-slide .modal__tabs .buttons {
      display: flex;
      justify-content: center; } }

@media screen and (min-width: 769px) {
  .micromodal-slide .modal__subscribe .btn:not(:last-child),
  .micromodal-slide .modal__tabs .btn:not(:last-child) {
    margin-right: 25px; } }

.micromodal-slide .modal__subscribe .authorization-checkbox,
.micromodal-slide .modal__tabs .authorization-checkbox {
  text-align: center; }
  .micromodal-slide .modal__subscribe .authorization-checkbox .authorization-checkbox__desc,
  .micromodal-slide .modal__tabs .authorization-checkbox .authorization-checkbox__desc {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    color: #353535;
    letter-spacing: 0;
    line-height: 24px; }
    .micromodal-slide .modal__subscribe .authorization-checkbox .authorization-checkbox__desc a,
    .micromodal-slide .modal__tabs .authorization-checkbox .authorization-checkbox__desc a {
      color: #FF2F00; }

.micromodal-slide .modal__subscribe .link,
.micromodal-slide .modal__tabs .link {
  margin-top: 40px;
  padding-bottom: 7px;
  border-bottom: 2px solid #000; }
  .micromodal-slide .modal__subscribe .link .link__label,
  .micromodal-slide .modal__tabs .link .link__label {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    font-size: 12px;
    color: #FF2F00;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 7px; }
  .micromodal-slide .modal__subscribe .link .link__wrapper,
  .micromodal-slide .modal__tabs .link .link__wrapper {
    display: flex;
    justify-content: space-between; }
    .micromodal-slide .modal__subscribe .link .link__wrapper .link__input,
    .micromodal-slide .modal__tabs .link .link__wrapper .link__input {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 17px;
      color: #353535;
      line-height: 24px;
      flex-grow: 1; }
    .micromodal-slide .modal__subscribe .link .link__wrapper .link__copy,
    .micromodal-slide .modal__tabs .link .link__wrapper .link__copy {
      cursor: pointer;
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      color: #353535;
      text-align: right;
      line-height: 24px;
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
      .micromodal-slide .modal__subscribe .link .link__wrapper .link__copy:hover,
      .micromodal-slide .modal__tabs .link .link__wrapper .link__copy:hover {
        color: #FF2F00; }

#preexistent-music-modal .modal__overlay {
  align-items: center; }

#preexistent-music-modal .modal__container {
  width: 500px;
  height: 90%;
  padding: 40px; }
  @media (max-width: 499px) {
    #preexistent-music-modal .modal__container {
      width: 90%; } }
  #preexistent-music-modal .modal__container > header {
    display: flex;
    justify-content: space-between;
    color: #FF2F00;
    font-size: 35px;
    font-weight: bold;
    align-items: center; }
  #preexistent-music-modal .modal__container .modal__close {
    position: static;
    font-weight: normal;
    color: #FF2F00; }
    #preexistent-music-modal .modal__container .modal__close:before {
      font-size: 35px; }
  #preexistent-music-modal .modal__container .modal__content {
    padding: 30px 15px 0 15px; }

.modal__container__separator {
  width: 100%;
  position: absolute;
  left: 0;
  border: none;
  border-bottom: 1px solid #FF2F00;
  color: #FF2F00;
  margin: 30px 0; }

.modal__preexistent-music header {
  font-size: 25px;
  font-family: "adelle-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 60px; }

.modal__preexistent-music__infos {
  line-height: 25px; }

.modal__preexistent-music__infos__title {
  font-family: "adelle-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 10px; }

.modal__preexistent-music__infos__content {
  font-family: "adelle-sans", sans-serif;
  font-weight: 400;
  font-style: normal; }

.modal__preexistent-music__separator {
  border: none;
  border-bottom: 1px solid #FF2F00;
  color: #FF2F00;
  margin: 30px 15% 10px 15%; }

.js-tabs {
  max-width: 100%; }

.js-tabs__header {
  display: flex;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 30px; }

.js-tabs__header li {
  flex-grow: 1;
  text-align: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px; }
  .js-tabs__header li:not(:last-child):after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 25px;
    background-color: #000; }

.js-tabs__title {
  cursor: pointer;
  text-decoration: none;
  font-family: "adelle-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 17px;
  color: #000000;
  line-height: 20px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  display: block; }
  .js-tabs__title:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000; }

.js-tabs__title:not(.js-tabs__title-active):hover {
  text-decoration: none;
  color: #353535; }

.js-tabs__title-active {
  color: #FF2F00;
  letter-spacing: 0;
  line-height: 20px; }
  .js-tabs__title-active:before {
    background-color: #FF2F00; }

.tabs {
  position: relative;
  display: flex;
  overflow: scroll;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  padding: 25px 15px 0px; }
  @media (min-width: 375px) {
    .tabs {
      padding: 25px 25px 0px; } }
  .tabs::-webkit-scrollbar {
    display: none; }
  .tabs:after {
    content: '';
    padding-right: 25px; }
  @media (min-width: 1024px) {
    .tabs {
      display: none; } }
  .tabs .tab {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-left: 30px;
    padding-bottom: 10px;
    cursor: pointer; }
    .tabs .tab:first-of-type {
      margin-left: 0px; }
    .tabs .tab--current {
      border-bottom: solid 4px #FF2F00; }

.quote {
  min-height: 60vh;
  height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .quote {
      min-height: 100vh;
      height: 100vh;
      margin: -200px 0px;
      position: relative;
      z-index: -1; } }
  .quote .quote__cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    @media (max-width: 1023px) {
      .quote .quote__cover {
        background: #1C1C1C; } }
    .quote .quote__cover:before {
      content: "";
      background-image: linear-gradient(0deg, rgba(246, 247, 249, 0) 0%, #F6F7F9 100%);
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: 40%;
      z-index: 1; }
      @media (max-width: 1023px) {
        .quote .quote__cover:before {
          background-image: linear-gradient(0deg, rgba(28, 28, 28, 0) 0%, #1C1C1C 100%); } }
    .quote .quote__cover:after {
      content: "";
      background-image: linear-gradient(-180deg, rgba(246, 247, 249, 0) 0%, #F6F7F9 100%);
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 20%;
      z-index: 1; }
      @media (max-width: 1023px) {
        .quote .quote__cover:after {
          background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 0%, #1C1C1C 100%); } }
    .quote .quote__cover img {
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
  .quote .quote__inner {
    position: relative;
    text-align: center;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    z-index: 1;
    width: 100%; }
    @media (min-width: 1024px) {
      .quote .quote__inner {
        width: 65%; } }
  .quote .quote__label {
    position: relative;
    font-size: 17px;
    color: #F6F7F9;
    line-height: 24px; }
    @media (min-width: 1024px) {
      .quote .quote__label {
        color: #353535;
        font-size: 18px;
        line-height: 24px; } }
    .quote .quote__label:after {
      content: "";
      display: block;
      height: 2px;
      margin: 0 auto;
      background-color: #949494;
      margin-top: 25px;
      width: 80%; }
      @media (min-width: 1024px) {
        .quote .quote__label:after {
          width: 350px;
          background-color: #353535; } }
  .quote .quote__title {
    font-size: 36px;
    line-height: 44px;
    color: #F6F7F9;
    letter-spacing: 0.3px;
    margin-top: 30px; }
    .quote .quote__title--small {
      font-size: 24px;
      line-height: 30px; }
      @media (min-width: 1024px) {
        .quote .quote__title--small {
          font-size: 40px;
          line-height: 46px; } }
    @media (max-width: 1023px) {
      .quote .quote__title {
        text-shadow: 0 0 24px rgba(0, 0, 0, 0.63); } }
    @media (min-width: 1024px) {
      .quote .quote__title {
        font-size: 56px;
        color: #FF2F00;
        letter-spacing: 0.47px;
        line-height: 60px;
        margin-top: 50px; } }
  .quote .quote__button {
    margin-top: 60px;
    display: flex;
    justify-content: center; }

.search-form {
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  @media (max-width: 1023px) {
    .search-form {
      width: auto;
      margin: 0 25px; } }
  .search-form .search-bar {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 45px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    flex-wrap: wrap;
    transition: border-radius 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .search-form .search-bar:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      background-image: url("../static/svg/utils/search-icon.svg");
      background-size: cover; }
    @media (min-width: 1024px) {
      .search-form .search-bar {
        flex-basis: calc(100% - 200px);
        height: 70px;
        flex-wrap: nowrap; }
        .search-form .search-bar:before {
          width: 20px;
          height: 20px;
          left: 30px; }
        .search-form .search-bar:after {
          display: none; } }
    .search-form .search-bar.input-active {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0px;
      transition: border-radius 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      @media (min-width: 1024px) {
        .search-form .search-bar.input-active {
          border-top-left-radius: 5px;
          border-top-right-radius: 25px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 25px; } }
      .search-form .search-bar.input-active .search-bar__list {
        display: block; }
    .search-form .search-bar__input {
      width: 100%;
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #353535;
      flex-grow: 0;
      flex-shrink: 0;
      outline: none;
      font-size: 14px;
      padding-left: 40px;
      padding-right: 20px;
      height: 100%; }
      .search-form .search-bar__input::placeholder {
        color: #949494;
        transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      .search-form .search-bar__input::-ms-clear {
        display: none; }
      .search-form .search-bar__input::-webkit-input-placeholder {
        line-height: 24px; }
        @media (min-width: 1024px) {
          .search-form .search-bar__input::-webkit-input-placeholder {
            line-height: 28px; } }
      .search-form .search-bar__input:focus::placeholder {
        opacity: 0.5; }
      @media (min-width: 1024px) {
        .search-form .search-bar__input {
          padding-left: 70px;
          font-size: 17px;
          flex-grow: 0;
          flex-shrink: 0; }
          .search-form .search-bar__input::placeholder {
            height: 70px; } }
      @media (min-width: 1440px) {
        .search-form .search-bar__input {
          font-size: 20px;
          flex-grow: 0;
          flex-shrink: 0; } }
    .search-form .search-bar__list {
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 10;
      width: 100%;
      background-color: #FFFFFF;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      display: none; }
      @media (min-width: 1024px) {
        .search-form .search-bar__list {
          top: 70px;
          width: calc(65% + 70px); } }
      .search-form .search-bar__list .list__item {
        padding: 0px 40px;
        height: 40px;
        border-top: solid 1px #D8D8D8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer; }
        @media (min-width: 1024px) {
          .search-form .search-bar__list .list__item {
            padding: 25px 30px 25px 70px;
            height: auto; } }
      .search-form .search-bar__list .item__link {
        color: #353535;
        font-family: "adelle-sans", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        text-decoration: none; }
        @media (min-width: 1024px) {
          .search-form .search-bar__list .item__link {
            font-size: 20px; } }
        .search-form .search-bar__list .item__link .highlight {
          color: #FF2F00; }
      .search-form .search-bar__list .item__duration {
        font-family: "adelle-sans", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        color: #BBBBBB;
        display: none; }
        @media (min-width: 1024px) {
          .search-form .search-bar__list .item__duration {
            display: block; } }
    .search-form .search-bar__filters {
      height: 100%;
      position: relative;
      z-index: 3;
      font-family: "adelle-sans", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
      margin-top: 15px; }
      @media (min-width: 1024px) {
        .search-form .search-bar__filters {
          width: 100%;
          font-size: 17px;
          color: #353535;
          margin-top: 0px; } }
      @media (min-width: 1440px) {
        .search-form .search-bar__filters {
          font-size: 20px; } }
      .search-form .search-bar__filters .current-filter {
        padding: 11px 28px 8px;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        border-top: none;
        background-color: transparent;
        border: solid 1px #FFFFFF;
        border-radius: 25px; }
        .search-form .search-bar__filters .current-filter:hover {
          background-color: transparent; }
        .search-form .search-bar__filters .current-filter:before {
          font-size: 11px;
          color: #949494;
          content: 'DURÉE';
          display: none; }
        @media (max-width: 1023px) {
          .search-form .search-bar__filters .current-filter.has-been-selected {
            background-color: #FFFFFF;
            color: #FF2F00; } }
        @media (min-width: 1024px) {
          .search-form .search-bar__filters .current-filter {
            padding: 0px 0px 0px 20px;
            border: none;
            display: flex;
            border-radius: 0px; }
            .search-form .search-bar__filters .current-filter:before {
              display: block; } }
        @media (min-width: 1440px) {
          .search-form .search-bar__filters .current-filter {
            padding: 0px 0px 0px 30px; } }
      @media (min-width: 1024px) {
        .search-form .search-bar__filters .filters__list {
          left: -80px; } }
      .search-form .search-bar__filters .filters__list .list__item {
        border-top: solid 1px #D8D8D8;
        background-color: #FFFFFF;
        transition: background-color 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        cursor: pointer;
        position: relative;
        width: 100%;
        text-align: center; }
        @media (min-width: 1024px) {
          .search-form .search-bar__filters .filters__list .list__item {
            width: auto;
            text-align: left;
            padding: 15px 0px 15px 30px; }
            .search-form .search-bar__filters .filters__list .list__item:hover {
              background-color: transparent; } }
        .search-form .search-bar__filters .filters__list .list__item:hover {
          background-color: #F6F7F9; }
        .search-form .search-bar__filters .filters__list .list__item:last-of-type {
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          margin-bottom: 60px; }
        .search-form .search-bar__filters .filters__list .list__item--current {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-top-right-radius: 5px;
          border-top-color: transparent;
          border-left: 1px solid #DADADA; }
          .search-form .search-bar__filters .filters__list .list__item--current:before {
            font-size: 11px;
            color: #949494;
            content: 'DURÉE';
            margin-bottom: 7.5px; }
        .search-form .search-bar__filters .filters__list .list__item.is-selected {
          width: auto; }
          .search-form .search-bar__filters .filters__list .list__item.is-selected:after {
            content: '';
            width: 20px;
            height: 15px;
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            background-size: cover;
            background-image: url("../static/svg/utils/tick-icon.svg"); }
  .search-form .btn--search {
    border-radius: 45px; }
    @media (max-width: 1023px) {
      .search-form .btn--search {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        box-shadow: none; }
        .search-form .btn--search .btn__icon-container:before {
          width: 20px;
          height: 13px; } }
    @media (min-width: 1024px) {
      .search-form .btn--search {
        display: flex;
        width: 160px;
        height: 70px; } }
    .search-form .btn--search:hover {
      background: #FFFFFF; }
      @media (min-width: 1024px) {
        .search-form .btn--search:hover .btn__icon-container:before {
          background-image: url("../static/svg/utils/search-icon-orange.svg"); } }

.search-popular {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start; }
  @media (min-width: 1024px) {
    .search-popular {
      display: flex;
      margin-top: 40px;
      align-items: center; } }
  .search-popular__title {
    margin: 0 0 20px 0;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    line-height: 34px;
    color: white;
    text-align: center; }
  .search-popular__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (min-width: 1024px) {
      .search-popular__buttons {
        flex-direction: row; } }
    @media (max-width: 1023px) {
      .search-popular__buttons .btn {
        transition: none;
        width: 100%;
        justify-content: flex-start;
        padding: 18px 0px;
        border-radius: 0px; }
        .search-popular__buttons .btn:not(:first-of-type) {
          border-top: solid 1px rgba(255, 255, 255, 0.2); }
        .search-popular__buttons .btn:hover {
          background: none; } }
    @media (min-width: 1024px) {
      .search-popular__buttons .btn {
        padding: 7px 28px; }
        .search-popular__buttons .btn:hover {
          background: #FFFFFF;
          color: #FF2F00; } }
    @media (min-width: 1660px) {
      .search-popular__buttons .btn {
        padding: 7px 50px; } }
    .search-popular__buttons .btn__label {
      font-size: 18px; }
      @media (min-width: 768px) {
        .search-popular__buttons .btn__label {
          margin: auto; } }
      @media (min-width: 1024px) {
        .search-popular__buttons .btn__label {
          font-size: 15px; } }
    .search-popular__buttons .btn--orange {
      background-color: transparent;
      box-shadow: none; }
      @media (min-width: 1024px) {
        .search-popular__buttons .btn--orange {
          background-color: #FF2F00; } }
    .search-popular__buttons .btn--borders {
      border-color: transparent; }
      @media (min-width: 1024px) {
        .search-popular__buttons .btn--borders {
          border-color: #FFFFFF; } }
      @media (max-width: 1023px) {
        .search-popular__buttons .btn--borders.btn--white:hover {
          background-color: transparent;
          border-color: transparent;
          border-top-color: rgba(255, 255, 255, 0.2);
          border-top-width: 1px; }
          .search-popular__buttons .btn--borders.btn--white:hover .btn__label {
            color: #FFFFFF; } }
  .search-popular--wide {
    margin-top: 0px; }
    .search-popular--wide .search-popular__title {
      color: #353535; }
    @media (max-width: 1023px) {
      .search-popular--wide .search-popular__buttons .btn__label {
        margin: auto;
        color: #353535 !important; }
      .search-popular--wide .search-popular__buttons .btn:hover {
        border-color: transparent;
        background: none; }
        .search-popular--wide .search-popular__buttons .btn:hover span {
          color: #353535; }
      .search-popular--wide .search-popular__buttons .btn:last-of-type {
        padding-bottom: 0px; } }
    @media (min-width: 1024px) {
      .search-popular--wide {
        flex-direction: column; }
        .search-popular--wide .search-popular__title {
          font-family: "adelle-sans", sans-serif;
          font-weight: 600;
          font-style: normal;
          font-size: 30px;
          line-height: 43px;
          color: #353535;
          margin-bottom: 20px; }
        .search-popular--wide .search-popular__buttons .btn--orange:hover {
          background: #000; }
          .search-popular--wide .search-popular__buttons .btn--orange:hover span {
            color: #FFFFFF; }
        .search-popular--wide .search-popular__buttons .btn--borders {
          border-color: #BBBBBB; }
          .search-popular--wide .search-popular__buttons .btn--borders span {
            color: #1f1717 !important; }
          .search-popular--wide .search-popular__buttons .btn--borders:hover {
            background: rgba(236, 236, 236, 0.6);
            border-color: rgba(236, 236, 236, 0.6); }
            .search-popular--wide .search-popular__buttons .btn--borders:hover span {
              color: #353535; } }

@media (max-width: 1023px) {
  .layout--search.results-displayed {
    background-color: #333333; } }

.search-results {
  min-height: 100px !important;
  padding-top: 100px;
  position: relative; }
  @media (min-width: 1024px) {
    .search-results {
      padding-top: 40px; } }
  .search-results .mainloader {
    top: auto;
    width: 30px;
    height: 30px;
    left: calc(50% - 15px); }
    @media (max-width: 1023px) {
      .search-results .mainloader {
        top: 30px; } }
  .search-results .content__search {
    color: #FFFFFF; }
    @media (min-width: 1024px) {
      .search-results .content__search {
        color: #FF2F00; } }
    .search-results .content__search:before, .search-results .content__search:after {
      color: #FFFFFF; }
      @media (min-width: 1024px) {
        .search-results .content__search:before, .search-results .content__search:after {
          color: #353535; } }
    .search-results .content__search:before {
      content: ' « '; }
    .search-results .content__search:after {
      content: ' »'; }
  .search-results__content {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #FFFFFF;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 22px; }
    @media (min-width: 410px) {
      .search-results__content {
        font-size: 18px;
        line-height: 24px; } }
    @media (min-width: 1024px) {
      .search-results__content {
        font-size: 36px;
        line-height: 43px;
        color: #353535; } }
    .search-results__content span {
      color: #FF2F00; }
  @media (max-width: 1023px) {
    .search-results__list {
      display: none; } }
  .search-results__list .list__item:not(.hidden) {
    font-family: "adelle-sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 26px;
    line-height: 43px;
    color: #353535;
    display: inline-block; }
    .search-results__list .list__item:not(.hidden):after {
      content: ', ';
      color: #353535; }
    .search-results__list .list__item:not(.hidden).only-item:before {
      content: none; }
    .search-results__list .list__item:not(.hidden).only-item:after {
      content: none; }
    .search-results__list .list__item:not(.hidden).second-to-last-item:after {
      content: none; }
    .search-results__list .list__item:not(.hidden).last-item:before {
      content: ' et '; }
    .search-results__list .list__item:not(.hidden).last-item:after {
      content: none; }
    .search-results__list .list__item:not(.hidden) .item__link {
      text-decoration: none;
      border-bottom: solid 2px #FF2F00;
      color: #FF2F00;
      padding-bottom: 5px; }
      .search-results__list .list__item:not(.hidden) .item__link:hover span {
        color: #FF2F00; }
      .search-results__list .list__item:not(.hidden) .item__link span {
        color: #353535;
        transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
        .search-results__list .list__item:not(.hidden) .item__link span.item__length {
          color: #FF2F00; }
  .search-results__details {
    padding-top: 60px; }
    @media (max-width: 1023px) {
      .search-results__details {
        padding-top: 0; } }
    .search-results__details .selected-podcasts__load-more {
      position: relative; }
    .search-results__details .loader.mainloader {
      top: -50px;
      left: calc(50% - 0.5em);
      font-size: 20px; }
  .search-results--empty {
    margin-top: 91px !important;
    padding: 30px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1023px) {
      .search-results--empty {
        display: block; } }
    @media (min-width: 1024px) {
      .search-results--empty {
        margin-top: 50px;
        padding: 60px 0px 80px; } }
    .search-results--empty .search-results__content {
      color: #353535;
      margin-bottom: 0px;
      text-align: center; }
      @media (max-width: 1023px) {
        .search-results--empty .search-results__content {
          font-size: 24px;
          line-height: 30px; } }
    .search-results--empty .separator {
      width: 120px;
      border: none;
      border-bottom: solid 2px #FF2F00;
      margin: 25px auto; }
      @media (min-width: 1024px) {
        .search-results--empty .separator {
          margin: 35px 0px; } }

#search-results__content {
  font-family: "adelle-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #353535;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px; }
  @media (min-width: 410px) {
    #search-results__content {
      font-size: 18px;
      line-height: 24px; } }
  @media (min-width: 1024px) {
    #search-results__content {
      font-size: 36px;
      line-height: 43px; } }
  @media (max-width: 1023px) {
    #search-results__content {
      color: white; } }
  #search-results__content span.content__length, #search-results__content span.content__search {
    color: #FF2F00; }
  @media (max-width: 1023px) {
    #search-results__content span.content__search {
      color: white; } }

.search-results__tabs {
  display: none; }
  @media (max-width: 1023px) {
    .search-results__tabs {
      display: block; } }

.podcast-card--pattern, .list__item--pattern {
  display: none; }

.mobile-bgd {
  display: none;
  width: 100%;
  height: 70vh;
  opacity: 0.08;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;'; }

@media (min-width: 1921px) {
  .u-container.u-container--specific {
    max-width: 1400px; } }

.global-wrapper.image-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1c1c1c; }
  @media (max-width: 1023px) {
    .global-wrapper.image-container {
      margin: 0px;
      max-width: 100%;
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .global-wrapper.image-container {
      background: transparent;
      position: relative; } }
  .global-wrapper.image-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(-180deg, rgba(35, 35, 35, 0) 0%, #353535 100%);
    height: 70vh;
    width: 100%; }
    @media (min-width: 1024px) {
      .global-wrapper.image-container:after {
        content: none; } }
  .global-wrapper.image-container .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    opacity: 0.08;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .global-wrapper.image-container .image {
        display: none; } }
  .global-wrapper.image-container .layer-dark {
    display: none; }
    @media (max-width: 1023px) {
      .global-wrapper.image-container .layer-dark {
        display: block;
        position: absolute;
        top: 70vh;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #353535;
        z-index: 0; } }

.global-wrapper.search-wrapper {
  height: 40vh;
  align-content: center;
  background-color: #000000;
  position: relative;
  padding-top: 95px;
  padding-bottom: 40px; }
  @media (max-width: 1023px) {
    .global-wrapper.search-wrapper {
      height: auto;
      background-color: #1c1c1c; } }
  @media (min-width: 1024px) {
    .global-wrapper.search-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 0px;
      padding-bottom: 0px; } }
  .global-wrapper.search-wrapper.filters-open {
    z-index: 301; }
    .global-wrapper.search-wrapper.filters-open .search-form {
      position: static; }
    .global-wrapper.search-wrapper.filters-open .search-bar {
      position: static;
      background: none; }
      .global-wrapper.search-wrapper.filters-open .search-bar:before {
        display: none; }
      .global-wrapper.search-wrapper.filters-open .search-bar:after {
        display: none; }
    .global-wrapper.search-wrapper.filters-open .search-bar__input, .global-wrapper.search-wrapper.filters-open .search-popular, .global-wrapper.search-wrapper.filters-open .separator, .global-wrapper.search-wrapper.filters-open .btn--search {
      visibility: hidden;
      opacity: 0; }
    .global-wrapper.search-wrapper.filters-open .search-bar__filters {
      display: flex;
      align-items: center;
      position: static; }
    .global-wrapper.search-wrapper.filters-open .image {
      filter: blur(50px); }
    .global-wrapper.search-wrapper.filters-open .current-filter {
      display: none; }
    .global-wrapper.search-wrapper.filters-open .filters__list:before {
      content: 'Durée'; }
    .global-wrapper.search-wrapper.filters-open .filters__list .list__item {
      background: transparent;
      border: none; }
      .global-wrapper.search-wrapper.filters-open .filters__list .list__item:hover {
        background-color: transparent; }
      .global-wrapper.search-wrapper.filters-open .filters__list .list__item--current {
        height: auto; }
        .global-wrapper.search-wrapper.filters-open .filters__list .list__item--current:before {
          display: none; }
      .global-wrapper.search-wrapper.filters-open .filters__list .list__item.is-selected {
        display: flex;
        align-items: center;
        justify-content: center; }
        .global-wrapper.search-wrapper.filters-open .filters__list .list__item.is-selected:after {
          right: -30px;
          width: 15px;
          height: 11px; }
  .global-wrapper.search-wrapper .image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .global-wrapper.search-wrapper .image-container:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, #1c1c1c 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%); }
    .global-wrapper.search-wrapper .image-container .image-wrapper {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
  .global-wrapper.search-wrapper .image {
    opacity: 0.6;
    filter: blur(0px);
    transition: filter 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation: scaling 15s infinite alternate;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;'; }
    @media (min-width: 1024px) {
      .global-wrapper.search-wrapper .image {
        height: 100%; } }
  .global-wrapper.search-wrapper .separator {
    margin-top: 85px;
    margin-left: -25px;
    margin-right: -25px;
    border-bottom-color: rgba(255, 255, 255, 0.2); }
    @media (min-width: 1024px) {
      .global-wrapper.search-wrapper .separator {
        display: none; } }

@keyframes scaling {
  from {
    -webkit-transform: scale(1); }
  to {
    -webkit-transform: scale(1.06); } }

.sharings-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }
  @media (min-width: 1024px) {
    .sharings-container {
      width: 85%; } }
  @media (min-width: 1280px) {
    .sharings-container {
      width: 900px; } }
  @media (min-width: 1440px) {
    .sharings-container {
      width: 1000px; } }
  @media (min-width: 1660px) {
    .sharings-container {
      width: 1100px; } }
  @media (min-width: 1921px) {
    .sharings-container {
      width: 1500px; } }
  .sharings-container .sharings {
    position: absolute;
    background: #FF2F00;
    border-radius: 36px;
    padding: 20px 15px;
    overflow: hidden;
    display: none;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2); }
    @media (min-width: 1024px) {
      .sharings-container .sharings {
        top: 100px;
        left: calc(85% + 30px); } }
    @media (min-width: 1280px) {
      .sharings-container .sharings {
        top: 300px;
        padding: 30px 20px;
        left: calc(900px + 30px); } }
    @media (min-width: 1440px) {
      .sharings-container .sharings {
        left: calc(1000px + 30px); } }
    @media (min-width: 1660px) {
      .sharings-container .sharings {
        left: calc(1100px + 30px); } }
    @media (min-width: 1921px) {
      .sharings-container .sharings {
        left: calc(1500px + 30px); } }
    .sharings-container .sharings.is-fixed {
      position: fixed;
      right: auto; }
    .sharings-container .sharings.is-expanded .sharings__list__item:not(:last-child) {
      padding-bottom: 25px;
      border-bottom: solid 1px white; }
    .sharings-container .sharings__list {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .sharings-container .sharings__list__item {
        width: 100%;
        text-align: center;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        cursor: pointer; }
        .sharings-container .sharings__list__item:hover {
          opacity: 0.7; }
        .sharings-container .sharings__list__item:not(:first-child) {
          padding-top: 25px; }
        .sharings-container .sharings__list__item:not(:last-child) {
          padding-bottom: 25px;
          border-bottom: solid 1px white; }
        .sharings-container .sharings__list__item.to-hide {
          opacity: 0;
          visibility: hidden;
          height: 0;
          padding: 0; }
      .sharings-container .sharings__list .cross {
        transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
        .sharings-container .sharings__list .cross.is-active {
          transform: rotate(45deg); }
    .sharings-container .sharings__link.twitter {
      width: 17px; }
    .sharings-container .sharings__link.facebook {
      width: 10px; }

.sidebar-block {
  font-family: "adelle-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
  position: relative; }
  @media (min-width: 1024px) {
    .sidebar-block {
      font-family: "adelle-sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      color: #353535;
      padding: 10px 0px;
      border-bottom: solid 2px #353535; } }
  @media (min-width: 1280px) {
    .sidebar-block {
      font-size: 17px; } }
  .sidebar-block:last-of-type {
    border: none; }
  .sidebar-block__filter {
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
    margin: 35px 0px 8px; }
    @media (min-width: 1024px) {
      .sidebar-block__filter {
        margin: 15px 0px 8px; } }
  .sidebar-block__list .list__item {
    padding: 15px 0px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
    @media (min-width: 1024px) {
      .sidebar-block__list .list__item {
        border-bottom: solid 1px #DADADA; }
        .sidebar-block__list .list__item:last-child {
          border: none; } }
    @media (min-width: 1024px) {
      .sidebar-block__list .list__item .item__image-container {
        display: none; } }
    .sidebar-block__list .list__item.has-sublist .item__link--category:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 8px;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../static/svg/utils/dropdown.svg");
      transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
    .sidebar-block__list .list__item.has-sublist .item__link--active:before {
      transform: rotate(-180deg); }
    .sidebar-block__list .list__item.has-sublist .sublist {
      display: none;
      padding: 25px 0px 15px 22px; }
      .sidebar-block__list .list__item.has-sublist .sublist.is-active {
        display: block; }
    .sidebar-block__list .list__item.has-sublist .sublist__item {
      font-size: 15px;
      margin-bottom: 15px; }
      .sidebar-block__list .list__item.has-sublist .sublist__item:last-of-type {
        margin-bottom: 0px; }
  .sidebar-block__list .item__link {
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: pointer; }
    @media (min-width: 1024px) {
      .sidebar-block__list .item__link {
        color: #353535; } }
    .sidebar-block__list .item__link:hover {
      color: #FF2F00; }
  .sidebar-block__list .item__length {
    color: #FF2F00; }
    .sidebar-block__list .item__length:before {
      content: ' ('; }
    .sidebar-block__list .item__length:after {
      content: ')'; }
  @media (max-width: 1023px) {
    .sidebar-block__list--filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .sidebar-block__list--filters:after {
        content: '';
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 48%; }
      .sidebar-block__list--filters .list__item {
        border-bottom: none;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 31.454545%; } }
    @media (max-width: 1023px) and (max-width: 499px) {
      .sidebar-block__list--filters .list__item {
        flex-basis: 48%; } }
  @media (max-width: 1023px) {
        .sidebar-block__list--filters .list__item.has-sublist {
          position: relative; }
          .sidebar-block__list--filters .list__item.has-sublist .item__link--category {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            text-align: center;
            width: 80%;
            margin: auto; }
            .sidebar-block__list--filters .list__item.has-sublist .item__link--category:hover {
              color: #FFFFFF; }
            .sidebar-block__list--filters .list__item.has-sublist .item__link--category:before {
              content: none; }
          .sidebar-block__list--filters .list__item.has-sublist .item__length {
            color: #FFFFFF; }
          .sidebar-block__list--filters .list__item.has-sublist .item__image-container {
            position: relative;
            overflow: hidden;
            border-radius: 5px;
            background: #000000;
            box-shadow: 0 6px 12px 0px rgba(0, 0, 0, 0.2); }
            .sidebar-block__list--filters .list__item.has-sublist .item__image-container:before {
              content: '';
              display: block;
              padding-bottom: 77.5%;
              height: 0; }
            .sidebar-block__list--filters .list__item.has-sublist .item__image-container .item__image {
              width: 100%;
              filter: blur(17px);
              transform: scale(1.3);
              opacity: 0.7;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              font-family: 'object-fit: cover; object-position: center;'; }
            .sidebar-block__list--filters .list__item.has-sublist .item__image-container a {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 2; } }

.sidebar {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  align-self: flex-start;
  padding: 80px 15px;
  width: 100%; }
  @media (min-width: 375px) {
    .sidebar {
      padding: 80px 25px; } }
  @media (min-width: 768px) {
    .sidebar {
      padding: 120px 25px; } }
  @media (min-width: 1024px) {
    .sidebar {
      padding: 30px;
      background-color: #FFFFFF;
      border-radius: 5px;
      margin-right: 30px;
      width: 280px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 280px; } }
  @media (min-width: 1280px) {
    .sidebar {
      width: 315px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 315px; } }
  .sidebar__title {
    position: relative;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    z-index: 1;
    color: #FFFFFF;
    margin-bottom: 5px;
    padding-bottom: 15px;
    border-bottom: solid 2px #FFFFFF; }
    @media (min-width: 1024px) {
      .sidebar__title {
        display: none; } }
    .sidebar__title span {
      color: #FF2F00; }
  .sidebar .sidebar__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1c1c1c; }
    @media (max-width: 1023px) {
      .sidebar .sidebar__image-container {
        margin: 0px;
        max-width: 100%;
        overflow: hidden; } }
    @media (min-width: 1024px) {
      .sidebar .sidebar__image-container {
        display: none; } }
    .sidebar .sidebar__image-container.sidebar__image-container--full .sidebar__image {
      height: 100%; }
    .sidebar .sidebar__image-container.sidebar__image-container--full .layer-dark {
      display: none; }
    .sidebar .sidebar__image-container.sidebar__image-container--full:after {
      height: 100%; }
    .sidebar .sidebar__image-container:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(35, 35, 35, 0) 0%, #353535 100%);
      height: 50%;
      width: 100%; }
      @media (min-width: 1024px) {
        .sidebar .sidebar__image-container:after {
          content: none; } }
    .sidebar .sidebar__image-container .sidebar__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      opacity: 0.6;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;'; }
      @media (min-width: 1024px) {
        .sidebar .sidebar__image-container .sidebar__image {
          display: none; } }
    .sidebar .sidebar__image-container .layer-dark {
      display: block;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #353535;
      z-index: 0; }
      @media (min-width: 1024px) {
        .sidebar .sidebar__image-container .layer-dark {
          display: none; } }

.subscribe-container {
  display: none; }
  @media (min-width: 768px) {
    .subscribe-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: solid 1px rgba(246, 247, 249, 0.2);
      border-top: 0;
      border-radius: 5px;
      padding: 40px 5px;
      margin: 50px auto 0px;
      max-width: 80%; } }
  @media (min-width: 768px) {
    .subscribe-container--no-border {
      border: none;
      padding: 5px 0px;
      justify-content: space-between; } }
  @media (min-width: 1024px) {
    .subscribe-container {
      max-width: 100%; } }
  .subscribe-container .border-top {
    position: absolute;
    height: 0px;
    width: calc(50% - 220px/2 - 1px);
    top: 0;
    border-top: solid 1px rgba(246, 247, 249, 0.2); }
    .subscribe-container .border-top--right {
      left: 2px; }
    .subscribe-container .border-top--left {
      right: 2px; }
  .subscribe-container p {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    height: 35px;
    text-transform: uppercase;
    font-family: "adelle-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center; }
  .subscribe-container .btn:not(:last-child) {
    margin-right: 10px; }
    @media (min-width: 1440px) {
      .subscribe-container .btn:not(:last-child) {
        margin-right: 20px; } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

em {
  font-style: italic; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  cursor: pointer !important; }

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  /* Browsers have different default form fonts */
  font-size: 13px;
  font-family: Arial; }

/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* Text Inputs
-----------------------------------------------*/
/* Button Controls
-----------------------------------------------*/
input[type=checkbox],
input[type=radio] {
  width: 13px;
  height: 13px; }

/* File Uploads
-----------------------------------------------*/
/* Search Input
-----------------------------------------------*/
/* Make webkit render the search input like a normal text field */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box; }

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none; }

/* Buttons
-----------------------------------------------*/
button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto; }

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none; }

/* Textarea
-----------------------------------------------*/
textarea {
  /* Move the label to the top */
  vertical-align: top;
  /* Turn off scroll bars in IE unless needed */
  overflow: auto; }

/* Selects
-----------------------------------------------*/
select[multiple] {
  /* Move the label to the top */
  vertical-align: top; }

button:focus,
:active:focus,
.active:focus,
.focus,
:active.focus,
.active.focus {
  cursor: pointer !important; }

hr.separator {
  border-top: none;
  border-bottom-color: #DADADA; }
  @media (max-width: 1023px) {
    hr.separator {
      margin-top: 35px; } }
