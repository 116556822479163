@import "includes/base/mixins/_mixins.scss";

*,
*:after,
*:before {
  box-sizing: border-box;
}


body {
  background: #F6F7F9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
}

.wrapper {
  overflow-x: hidden;
}

.inner-wrapper {
  transition: filter .4s ease-out;

  &--blurred {
    filter: blur(50px);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background: #000000;
      opacity: 0.3;
    }
  }
}

img:not([src]) {
  visibility: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

//// AUDIT

*:focus, #cke_message:focus{
  border: 1px solid #FF2F00 !important;
}

a:focus {
  border: 1px solid #FF2F00 !important;
}

