.selected-podcasts {
  position: relative;
  padding: 25px 0;

  @include l-up {
    padding: 85px 0;
    overflow: visible;
  }

  .selected-podcasts__list {
    @include m-up {
      flex-direction: column;
    }
    .list__item {
      @include AdelleSansSemiBold();

      @include l-up {
        font-size: 28px;
        line-height: 46px;
      }

      @include xl-up {
        font-size: 36px;
        line-height: 52px;
      }

      a {
        color: #353535;
        text-decoration: none;
        transition: color .4s $easeOutExpo;

        &:hover {
          color: #FF2F00;
        }
      }

      span {
        color: #FF2F00;
      }
    }
  }

  .selected-podcasts__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #484848;
    margin-bottom: 30px;

    @include l-up {
      padding-bottom: 25px;
      margin-bottom: 45px;
    }

    .selected-podcasts__title {
      @include AdelleSansExtraBold();
      font-size: 22px;
      color: #FF2F00;
      line-height: 20px;

      @include l-up {
        font-size: 36px;
      }
    }

    .selected-podcasts__see-all {
      @include AdelleSansSemiBold();
      font-size: 16px;
      color: #FF2F00;
      text-align: right;
      line-height: 20px;
      transition: color 0.3s $easeOutExpo;

      &:hover {
        color: #000;
      }
    }

    .btn {
      &--playlist {
        width: 80px;

        &:not(.btn--playlist-short){
          @include m-up {
            width: 195px;
            height: 40px;
          }
        }

        &:hover {
          background: #FFF;
          border: solid 2px #FFF;

          .selected-podcasts__numbers span {
            color: #FF2F00;
          }

          .btn__icon-container {
            &:before {
              background-image: url('../static/svg/utils/add-to-playlist-active.svg');
            }
          }
        }

        .selected-podcasts__numbers {
          //height: 16px;
          span {
            @include AdelleSansSemiBold();
            font-size: 16px;
            line-height: 20px;
            color: #353535;

            &:first-child:after {
              @include m-up {
                display: none;
              }
            }

            &:last-child {
              @include m-down {
                display: none;
              }
            }

            &:first-child, &:last-child {
              &:after {
                content: '|';
                margin: 0 5px;
                color: #D8D8D8;

                @include m-up {
                  margin: 0 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.is-negative {

    &--large {
      @include l-up {
        margin-top: -335px;
      }
    }

    &--wide {
      margin-top: -380px;

      @include l-up {
        margin-top: -400px;
      }
    }
  }

  &.is-white {
    .selected-podcasts__header {
      border-bottom-color: #FFF;

      .selected-podcasts__title {
        color: #FFF;
      }
    }

    &--desktop {
      @include l-down {
        .selected-podcasts__header {
          border-bottom-color: #353535;

          .selected-podcasts__title {
            color: #FF2F00;
          }

          .btn .selected-podcasts__numbers span {
            color: #353535;
          }

          .btn .btn__icon-container:before {
            background-image: url('../static/svg/utils/add-to-playlist.svg');
          }
        }
      }
    }

    .btn {
      .selected-podcasts__numbers span {
        color: #FFFFFF;
      }
    }
  }
}

.selected-podcasts .glide {

  &:hover {
    .glide__arrows {
      opacity: 1;
    }
  }

  &.glide--mobile-only .glide__arrows {
    display: none;
  }

  .glide__arrows {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: all 0.6s $easeOutExpo;

    @include l-down {
      display: none;
    }


    .glide__arrow {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      background: rgba(#FFFFFF, 0.96);
      width: 75px;
      height: 100%;
      pointer-events: auto;
      outline: none;

      &::before{
        content: "";
        display: block;
        width: 30px;
        height: 17px;
        background: url('../static/svg/utils/back-active.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transform-origin: center;
      }

      &.glide__arrow--left {
        @include l-up {
          left: calc(((100vw - 100%) / 2) * -1);
        }

        @include xl-up {
          left: calc(((100vw - 900px) / 2) * -1);
        }

        @include xxl-up {
          left: calc(((100vw - 1000px) / 2) * -1);
        }

        @include xxxl-up {
          left: calc(((100vw - 1100px) / 2) * -1);
        }

        @include xxxxl-up {
          left: calc(((100vw - 100%) / 2) * -1);
        }

      }

      &.glide__arrow--right {

        &::before{
          transform: translate(-50%, -50%) rotate(-90deg);
        }

        @include l-up {
          right: calc(((100vw - 100%) / 2) * -1);
        }

        @include xl-up {
          right: calc(((100vw - 900px) / 2) * -1);
        }

        @include xxl-up {
          right: calc(((100vw - 1000px) / 2) * -1);
        }

        @include xxxl-up {
          right: calc(((100vw - 1100px) / 2) * -1);
        }

        @include xxxxl-up {
          right: calc(((100vw - 100%) / 2) * -1);
        }
      }

    }
  }
}

.selected-podcasts .selected-podcasts__load-more {
  margin-top: 30px;

  @include l-up {
    margin-top: 50px;
  }

  @include l-down {
    height: 80px;
  }

  .btn--more {
    width: 100%;
    justify-content: center;
    margin: 0 auto;

    @include l-up {
      width: 530px;
    }
  }
}

.selected-podcasts.no-ms-mobile {
  .selected-podcasts__grid {
    @include xxs-up {
      margin: 0px;
    }
  }
}

.selected-podcasts .selected-podcasts__grid {
  margin: 0 -20px;

  @include xxs-up {
    margin: 0 -25px;
  }

  @include m-up {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
  }

  @include m-up {
    justify-content: space-between;
  }

  &--unflex {
    display: block;
  }

  .podcast-card {

    @include m-up {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 48.64%;
      margin-bottom: 20px;
    }

    @include l-up {
      margin-bottom: 30px;
    }
  }

  .podcast-card--full {

    @include m-up {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
    }
  }
}

/****
*** Slideshow mobile-only
*** Here the style to apply only for desktop
**/

@include l-up {
  .selected-podcasts {
    .glide--mobile-only {
      .glide__slides {
        flex-wrap: wrap;
        justify-content: space-between;

        &:after {
          content: '';
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 31.454545%;
        }

        @include xxxxl-up {
          &:after {
            content: '';
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 32%;
          }
        }
      }

      .glide__slide {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 31.454545%;
        margin-bottom: 30px;

        @include xxxxl-up {
          flex-basis: 32%;
        }
      }
    }
  }
}

.selected-podcasts.selected-podcasts--all {
  padding-top: 30px;
  max-width: 100%;
  margin: 0px;

  .selected-podcasts__grid {
    @include l-down {
      margin: 0px;
    }
  }

  @include l-down {
    .podcast-card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 50%;
      margin-bottom: 0;
      border-radius: 0px;
    }

    .podcast-card__image-container:before {
      padding-bottom: 85%;
    }
  }

  @include l-up {
    .selected-podcasts__grid {
      &:after {
        content: '';
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 17.5px);

        @include xxl-up {
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(33.333333% - 17.5px);
        }
      }

      .glide__slide {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 17.5px);

        margin-bottom: 35px;

        @include xxl-up {
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(33.333333% - 17.5px);
        }
      }
    }
  }
}

// RESULTS SEARCH PODCAST CARD STYLE


.selected-podcasts--list, .selected-podcasts--all {
  @include l-up {
    .selected-podcasts__grid {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

.selected-podcasts--list {
  @include l-down {
    .selected-podcasts__grid .podcast-card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      margin-bottom: 20px;

      .podcast-card__buttons {
        display: none;
      }
    }

    &.selected-podcasts--dark-mobile {
      @include l-down {
        .selected-podcasts__list {
          .list__item {
            padding: 10px 0px;

            &:first-child {
              padding-top: 0;
            }

            a {
              color: #353535;
            }
          }
        }
      }
    }
  }
  @include l-up {
    .selected-podcasts__grid {
      &:after {
        content: '';
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 31.454545%;
      }

      @include xxxxl-up {
        &:after {
          content: '';
          flex-basis: 0 0 32%;
        }
      }

      .glide__slide {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 31.454545%;
        margin-bottom: 30px;

        @include xxxxl-up {
          flex-basis: 32%;
        }
      }

    }
  }

  @include l-down {
    padding: 0px;

    .selected-podcasts__header {
      display: none;
    }

    .selected-podcasts__grid {
      margin: 0;
    }

    .selected-podcasts__list {
      .list__item {
        font-size: 20px;
        line-height: 20px;
        padding: 20px 0px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);

        a {
          color: #FFFFFF;
        }
      }
    }

    .podcast-card {
      margin: 20px 0px;
      box-shadow: none;
      flex-direction: row;
      background: transparent;
      padding-bottom: 20px;
      border-bottom: solid 1px rgba(255, 255, 255, .2);
      border-radius: 0px;
      align-items: flex-start;

      &:last-of-type {
        margin-bottom: 0px;
      }

      .podcast-card__image-container {
        margin-right: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 36%;
        border-radius: 5px;

        &:before {
          padding-bottom: 71.5%;
          background: none;
          border-radius: 5px;
        }

        &:after {
          content: none
        }

        .podcast-card__image {
          opacity: 1;
          border-radius: 5px;
        }

        .btn--play {
          position: absolute;
          left: 10px;
          bottom: 10px;
          width: 30px;
          height: 30px;
          padding: 0px;

          .btn__icon-container {
            margin-right: 0px;
          }
        }
      }

      .podcast-card__content {
        position: static;
        padding: 0px;
        display: block;

        .podcast-card__title {
          text-shadow: none;
          color: #FFFFFF;
          font-size: 18px;
          line-height: 26px;

          @include xs-up {
            font-size: 20px;
            line-height: 28px;
          }
        }

        .podcast-card__serie {
          color: #FFFFFF;
          a {
            color: #FFFFFF;
          }
        }

        .podcast-card__description,
        .podcast-card__chapo,
        .podcast-card__authors {
          display: none;
        }

        .podcast-card__buttons {
          display: none;
        }
      }
    }

  }
}
