.player__app-infos {
  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 65px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: white;  
  @include AdelleSansSemiBold();
  line-height: 50px;

  @include m-up {
    display: none;
  }

  &__link, &__stay {
    @include AdelleSansSemiBold();
    font-weight: bold;
  }
  
  &__link {
    min-height: 50px;
    border-radius: 5px !important;
    color: white !important;
  }

  &__stay {
    text-decoration: underline;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 10px;
    @include AdelleSansExtraBold();

    &:before {
      content: "\2715";
      color: #FF2F00;
      font-size: 30px;
    }
  }
}

.player-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: #000000;
  z-index: 199;
  cursor: pointer;
  transition: background-color 0.3s $easeOutCubic;
  opacity: 0;
  visibility: hidden;

  @include l-up {
    height: 83px;
    z-index: 201;

    &:hover {
      background-color: #171717;
    }
  }

  .player-controls__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 25px;
    height: 100%;
    position: relative;
    width: 99vw;

    @include xxs-down {
      padding: 13px 15px
    }
  }
}

.player-controls {
  @include l-down {

    &:before {
      content: "";
      height: 2px;
      width: 100%;
      background-color: #FF2F00;
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      transition: all 0.4s $easeOutCubic;
      transform: translateY(2px);
      opacity: 0;
    }

    &.playing {
      &:before {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }

  .player-controls__timeline {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;

    @include l-down {
      display: none;
    }

    .timeline__progress-bar,
    .timeline__progress-current {

      height: 2px;
      transition: all 0.4s $easeOutCubic;
      transform-origin: bottom center;
    }

    .timeline__progress-bar {
      width: 100%;
      background-color: #686868;
    }

    .timeline__progress-current {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FF2F00;
      pointer-events: none;
    }

    .timeline__progress-handle {
      opacity: 0;
      position: absolute;
      top: -8px;
      left: 0;
      width: 16px;
      height: 16px;
      transform: translate(-50%, 0%) scale(0.8);
      transition: all 0.4s $easeOutCubic;
      border-radius: 50%;
      background-color: #FF2F00;
      box-shadow: 0 0px 7px 0 rgba(0,0,0,0.20);
    }

    &:hover {
      .timeline__progress-handle {
        opacity: 1;
        transform: translate(-50%, 0%) scale(1);
      }
    }
  }

  &:hover {
    .timeline__progress-bar,
    .timeline__progress-current {
      transform: scaleY(2)
    }
  }
}

.player-controls .player-controls__now-playing {
  max-width: 75%;
  display: flex;
  align-items: center;

  @include l-up {
    max-width: 40%;
  }

  @include xxl-up {
    max-width: 75%;
  }

  .player-controls__artwork {
    height: 40px;
    width: 49px;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 49px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 0;

    @include l-up {
      height: 50px;
      width: 61.25px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 61.25px;
    }

    img {
      @include object-fit();
    }
  }

  .player-controls__title {
    margin-left: 15px;
    overflow: hidden;
    @include AdelleSansBold();
    font-size: 17px;
    color: #F6F7F9;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include l-up {
      margin-left: 20px;
    }

    @include m-down {
      font-size: 16px;
    }

    @include s-down {
      font-size: 15px;
    }

    @include xs-down {
      font-size: 14px;
    }
  }
}

.player-controls .player-controls__main-elements {
  display: flex;

  @include l-up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}

.player-controls .player-controls__second-elements {
  @include l-down {
    display: none;
  }

  display: flex;

  .player-controls__time-infos {
    display: flex;
    align-items: center;
    @include AdelleSansBold();
    font-size: 12px;
    color: #F6F7F9;
    line-height: 24px;
    margin-right: 20px;


    &:after {
      content: "";
      width: 1px;
      height: 16px;
      background-color: #353535;
      margin-left: 30px;
    }
  }

  .time-infos__current, .time-infos__separator {
    color: #FF2F00;
  }

  .time-infos__current{
    width: 50px;
    text-align: right;
  }

  .time-infos__separator {
    margin: 0 5px;
  }
}
