.playlist-popin {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 20;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  .popin-wrapper {
    position: absolute;
    bottom: 65px;
    width: 100%;
    height: 65px;

    @include l-up {
      height: 70px;
      bottom: 100px;
    }

    .popin-container {
      height: 100%;
      width: 100%;
      background-color: #FF2F00;
      display: flex;
      align-items: center;
      margin: auto;
      padding-left: 25px;

      @include xxs-down {
        padding-left: 15px;
      }

      @include l-up {
        width: 85%;
        border-radius: 5px;
      }

      @include xl-up {
        max-width: 900px;
      }

      @include xxl-up {
        max-width: 1000px;
      }

      @include xxxl-up {
        max-width: 1100px;
      }

      .popin {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__text {
          @include AdelleSansSemiBold();
          font-size: 16px;
          line-height: 20px;
          color: #FFF;
        }

        &__close {
          cursor: pointer;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 50px;
          width: 50px;
          height: 60px;
          @include flexCentered();
        }
      }
    }
  }
  &.fixed-bottom {
    z-index: 303;
    .popin-wrapper {
      bottom: 0px;
    }
  }

}
