@import "sidebar-block";

.sidebar {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  align-self: flex-start;
  padding: 80px 15px;
  width: 100%;

  @include xxs-up {
    padding: 80px 25px;
  }

  @include m-up {
    padding: 120px 25px;
  }

  @include l-up {
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-right: 30px;
    width: 280px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 280px;
  }

  @include xl-up {
    width: 315px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 315px;
  }

  &__title {
    position: relative;
    @include AdelleSansBold();
    font-size: 20px;
    z-index: 1;
    color: #FFFFFF;
    margin-bottom: 5px;
    padding-bottom: 15px;
    border-bottom: solid 2px #FFFFFF;

    @include l-up {
      display: none;
    }

    span {
      color: #FF2F00;
    }
  }

  .sidebar__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1c1c1c;

    @include l-down {
      margin: 0px;
      max-width: 100%;
      overflow: hidden;
    }

    @include l-up {
      display: none;
    }

    &.sidebar__image-container--full {
      .sidebar__image {
        height: 100%;
      }

      .layer-dark {
        display: none;
      }

      &:after {
        height: 100%;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(35,35,35,0.00) 0%, #353535 100%);
      height: 50%;
      width: 100%;

      @include l-up {
        content: none
      }
    }

    .sidebar__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      opacity: 0.6;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';

      @include l-up {
        display: none;
      }
    }

    .layer-dark {
      display: block;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #353535;
      z-index: 0;

      @include l-up {
        display: none;
      }
    }
  }
}
