.player-mobile {
  position: relative;

  @include l-up {
    display: none;
  }

  .player-mobile__artwork {
    position: relative;
    background-color: #1C1C1C;

    &:before {
      content: "";
      display: block;
      height: 0;
      padding-bottom: 56.54%;
    }

    &:after {
      content: "";
      display: block;
      opacity: 0.7;
      background-image: linear-gradient(-180deg, #1C1C1C 0%, rgba(28,28,28,0.00) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      opacity: 0.7;
      @include object-fit();
    }
  }


  .player-mobile__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
  }


  .player-mobile__top {
    display: flex;
    align-items: center;

    .player-btn--share {
      margin-left: 10px;
    }

    .player-btn--back {
      margin-left: auto;
    }
  }

  .player-mobile__controls {
    display: flex;
    justify-content: center;
  }

  .player-mobile__time-infos {
    display: flex;
    justify-content: space-between;
    @include AdelleSansBold();
    font-size: 12px;
    line-height: 24px;
    color: #F6F7F9;

    .time-infos__current {
      color: #FF2F00;
    }
  }

  .player-mobile__timeline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .timeline__progress-bar,
    .timeline__progress-current {
      height: 2px;
      transition: all 0.4s $easeOutCubic;
      transform-origin: bottom center;
    }

    .timeline__progress-bar {
      width: 100%;
      background-color: #686868;
    }

    .timeline__progress-current {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #FF2F00;
      pointer-events: none;
    }

    .timeline__progress-handle {
      position: absolute;
      bottom: -9px;
      left: 0;
      width: 22px;
      height: 22px;
      transform: translate(-50%, 0%);
      transition: all 0.4s $easeOutCubic;
      border-radius: 50%;
      background-color: rgba(#FF2F00, 0.22);

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

  }
}
