.cover-list {
  position: relative;
  background-color: #1C1C1C;
  overflow: hidden;
  padding-top: 190px;
  padding-bottom: 430px;

  @include l-up {
    padding-top: 80px;
    padding-bottom: 390px;
  }

  @include xxxl-up {
    padding-top: 120px;
    padding-bottom: 430px;
  }

  .cover-list__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .cover-list__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 300px);
    width: 100%;

    @include l-up {
      height: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);


      @include l-up {
        top: 100%;
        height: 100%;
        background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%);
      }
    }

    .cover-list__image-wrapper {
      height: 80%;
      overflow:hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include l-up {
        height: 100%;
      }
    }
  }

  .cover-list__image {
    opacity: 0.6;
    @include object-fit();

    @include l-up {
      height: 100%;
    }
  }

  .cover-list__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 2;

    @include l-up {
      max-width: 60%;
    }
  }

  .cover-list__title {
    order: 0;
  }

  .cover-list__chapo {
    order: 4;
    text-align: left;

    @include l-up {
      text-align: center;
    }
  }

  .cover-list__authors {
    order: 2;

    @include l-up {
      margin-top: 10px;
    }
  }
  .cover-list__separator {
    margin: 20px auto;
    order: 1;

    @include l-up {
      order: 2;
      margin: 25px auto;
    }
  }

  .cover-list__buttons {
    justify-content: center;
    order: 3;
    margin-bottom: 80px;

    @include m-up {
      justify-content: flex-start;
    }

    @include l-up {
      margin-bottom: 0px;
      margin-top: 35px;
      order: 4
    }

    .subscribe-container {
      margin: 0 auto;
    }
  }
}
