.podcast-subscribe {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1C1C1C;
  overflow: hidden;
  margin-top: -30px;
  padding: 140px 0px 40px;

  @include m-up {
    padding: 80px 0px 40px;
  }

  @include l-up {
    padding: 150px 0px 320px;
    margin-top: -70px;
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 102%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 0%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);

      @include l-up {
        display: none;
      }
    }

    @include l-up {
      height: 100%;
      filter: blur(50px);
      transform: scale(1.2);
    }

    .podcast-subscribe__image {
      opacity: 0.6;
      @include object-fit();

      @include l-up {
        height: 100%;
      }
    }
  }

  .podcast-subscribe__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .podcast-subscribe__title {
      position: relative;
      text-align:center;
      font-size: 28px;
      line-height: 30px;
      color: white;
      @include AdelleSansBold();
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;

      @include m-up {
        margin-bottom: 30px;
      }

      @include l-up {
        margin-bottom: 50px;
        font-size: 50px;
        line-height: 48px;
      }
    }

    .podcast-subscribe__content {
      overflow: hidden;
      align-self: flex-start;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;

      @include l-up {
        flex-basis: 60%;
      }

      @include xl-up {
        flex-basis: 55%;
      }

      .btn {
        &--subscribe {
          margin: 20px auto 80px;

          @include m-up {
            display: none;
          }
        }
      }

      .content__description {
        @include AdelleSans();
        font-size: 15px;
        line-height: 26px;
        color: #FFFFFF;

        @include m-up {
          max-width: 80%;
          text-align: center;
          margin: auto;
        }

        @include l-up {
          text-align: left;
          font-size: 18px;
          line-height: 28px;
          max-width: 100%;
        }
      }
    }

    .podcast-subscribe__illustration-container {
      display: none;

      @include l-up {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 35%;
        position: relative;

        &:before {
          content: '';
          display: block;
          padding-bottom: 77.5%;
          height: 0;
        }

        .podcast-subscribe__illustration {
          border-radius: 5px;
          box-shadow: 0 6px 64px 0 rgba(0, 0, 0, 0.5);
          @include object-fit();
        }
      }

      @include xl-up {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 41%;
      }
    }
  }
}
