.podcast-description {
  position: relative;
  z-index:10;
  background-color: #FFF;
  border-radius: 5px;
  margin-top: -30px;

  @include m-up {
    margin-top: -60px;
  }

  @include l-up {
    margin-top: -80px;
  }

  @include xxxl-up {
    margin-top: -100px;
  }

  @include xxxxxl-up {
    margin-top: -120px;
  }

  &__inner {
    display: block;
    // flex-direction: column-reverse;
    padding: 20px 25px;

    @include m-up {
      padding: 40px 60px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    @include l-up {
      padding: 60px 90px;
    }

    .podcast-description__sidebar {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;
      margin-top: 25px;

      @include m-up {
        margin-top: 0px;
      }
    }

    .podcast-description__main {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 55%;
    }

    .podcast-description__credits {
      display: flex;
      flex-direction: column;

      @include s-down {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        display: block;
      }

      &__item {
        color: #16113D;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;

        @include l-up {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .title {
          @include AdelleSansBold();
        }
        .content {
          @include AdelleSans();
        }
      }
    }
    
    .podcast-description__preexisting-music {
      color: #FF2F00;
      text-decoration: underline;
      font-weight: bold;
    }

    .podcast-description__preexisting-music__content {
      display: none;
    }

    .podcast-description__quote {
      @include AdelleSansSemiBold();
      color: #FF2F00;
      font-size: 24px;
      line-height: 30px;

      @include l-up {
        font-size: 36px;
        line-height: 42px;
      }
    }

    .btn.btn--orange {
      display: inline-block;
      box-shadow: none;
      padding: 5px 25px;
    }

    .podcast-description__content {
      @include AdelleSans();
      color: #353535;
      font-size: 15px;
      line-height: 26px;
      margin-top: 15px;

      @include l-up {
        font-size: 18px;
        line-height: 32px;
        margin-top: 30px;
      }

      @include xxxxl-up {
        font-size: 20px;
        line-height: 34px;
      }

      a {
        color: #353535;
        text-decoration: none;
        border-bottom: solid 2px #FF2F00;
        transition: color .4s $easeOutExpo;

        &:hover {
          color: #ff2f00;
        }
      }
    }

    .podcast-description__distribution {
      .distribution-item {
        margin-top: 25px;

        @include l-up {
          margin-top: 45px;
        }

        &__title,
        &__subtitle {
          font-size: 15px;
          line-height: 26px;

          @include l-up {
            font-size: 18px;
            line-height: 32px;
          }

          @include xxxxl-up {
            font-size: 20px;
            line-height: 34px;
          }
        }

        &__title {
          @include AdelleSansBold();
          color: #FF2F00;
        }
        &__subtitle {
          @include AdelleSans();
          color: #353535;
        }

        &__list {
          .item {
            @include AdelleSans();
            color: #353535;
            position: relative;
            display: flex;
            font-size: 15px;
            line-height: 22px;

            @include l-up {
              font-size: 18px;
              line-height: 26px;
            }

            @include xxxxl-up {
              font-size: 20px;
              line-height: 28px;
            }

            &:not(first-child) {
              margin-top: 7px
            }

            &__disc {
              display: block;
              margin-top: 8px;
              width: 6px;
              height: 6px;
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: 6px;
              border-radius: 25px;
              margin-right: 10px;
              background-color: #FF2F00;

              @include l-up {
                margin-top: 12px;
                width: 8px;
                height: 8px;
                flex-basis: 8px;
              }
            }

            &__link {
              text-decoration: none;
              border-bottom: 2px solid #FF2F00;
              color: #353535;
              transition: color 0.3s $easeOutExpo;

              &:hover {
                color: #FF2F00
              }
            }
          }
        }
      }

      .separator {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #353535;
        margin-bottom: 25px;
      }
    }
  }
}
