.embed-list{
  position: relative;

  &:after {
    content: '';
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    transition: background .5s $easeOutCubic;
    pointer-events: none;
  }

  &.is-hidden {
    &:after{
      background: rgba(0, 0, 0, 0.5);
      pointer-events: auto;
    }
  }

  .podcast-list {
    max-width: calc(100% - 30px);
    margin: auto;

    &:first-child {
      border-top: 1px solid #DADADA;
      margin-top: 15px;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: none;
    }

    .podcast-list__inner {
      max-width: 100%;
      padding: 20px 0px;
      margin: 0px;
    }

    &__infos {
      flex-direction: column;
    }

    .podcast-list__serie {
      font-size: 17px;
      line-height: 20px;
    }

    .podcast-list__title {
      font-size: 13px;
      line-height: 15px;
      margin-top: 5px;
    }

    .podcast-list__buttons {
      align-self: center;

      .btn--play {
        width: 85px;
        padding: 5px 17px;
        margin-right: 0px;

        @include l-down {
          padding: 9px 12px;
          width: auto;
        }
      }

      .btn__duration {
        font-size: 15px;

        @include l-down {
          font-size: 13px;
        }
      }
    }
  }
}
