
.header .header__mobile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px;
  position: relative;

  @include xxs-down {
    padding: 25px 15px;
  }

  @include l-up {
    display: none;
  }

  .header__layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 120px;
    background-image: linear-gradient(#1c1c1c, rgba(28, 28, 28, 0));
    opacity: 0.5;
    pointer-events: none;
  }

  .mobile__home {
    margin-right: auto;
  }

  .mobile__home,
  .mobile__menu,
  .mobile__search {
    position: relative;
  }

  .mobile__menu {
    width: 20px;
    height: 14px;
    margin-top: 5px;
    margin-right: auto;

    @include l-up {
      height: 60px;
      width: 40px;
    }

    &:before, &:after {
      content: "";
      display: block;
      margin: 0 auto;
      width: 20px;
      height: 14px;
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url("../static/svg/utils/hamburger-mobile.svg");
      transition: all 0.7s $easeOutQuart;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include l-up {
        height: 30px;
        width: 20px;
      }
    }
  }

  .icon-arte-radio {
    width: 75px;
    height: auto;
  }
}
