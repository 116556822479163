.cover-classiques {
  position: relative;
  background-color: #1C1C1C;
  overflow: hidden;
  height: 60vh;
  display: flex;
  align-items: center;

  @include l-down {
    // display: none;
    height: 70vh;
  }

  .cover-classiques__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);
    }

    .cover-classiques__image-wrapper {
      width: 100%;
      height: 100%;
      overflow:hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .cover-classiques__image {
    opacity: 0.6;
    height: 100%;
    @include object-fit();
  }

  .cover-classiques__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 50%;

    @include l-down {
      width: 100%;
    }

    .cover-classiques__separator {
      margin: 30px auto;
    }

    .cover-classiques__description {
      margin-top: 15px;
      width: 50%;
      margin: auto;

      @include l-down {
        width: 80%;
      }
    }

    .cover-classiques__description {
      @include AdelleSansSemiBold();
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px;

      @include l-up {
        font-size: 15px;
        line-height: 25px;
      }

      @include xxxl-up {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
