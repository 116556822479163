.btn {
  border-radius: 50px;
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.20);
  padding: 7px 17px;
  transition: all 0.3s $easeOutExpo;
  z-index: 20;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  @include flexCentered();

  .btn__icon-container {
    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    margin-right: 10px;
  }

  .btn__label {
    @include AdelleSansExtraBold();
    font-size: 15px;
    text-align: center;
    line-height: 24px;

    @include xxs-down {
      font-size: 13px;
    }
  }

  .btn__duration {
    @include AdelleSansSemiBold();
    font-size: 15px;
    line-height: 24px;
    margin-right: 10px;

    @include xxs-down {
      font-size: 13px;
      margin-right: 5px;
    }
  }

  &.btn--xs {
    &.btn--play {
      .btn__icon-container {
        &:before {
          width: 9px;
          height: 12px;
        }
      }
    }

    @include l-down {
      padding: 10px 12px;

      .btn__icon-container {
        margin-right: 5px;
      }

      .btn__duration {
        font-size: 13px;
        line-height: 13px;
      }

      &.btn--play {
        .btn__icon-container {
          &:before {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }


  &.btn--xl {
    padding: 10px 25px;
  }

  &.btn--no-label {
    .btn__duration {
      margin-right: 0px;
    }
  }

  &.btn--orange {
    color: #FFF;
    background: #FF2F00;

    &:hover {
      background: #000;
    }
  }

  &.btn--black {
    color: #FFFFFF;
    background: #000;

    &:hover {
      background: #FF2F00;
    }
  }

  &.btn--light {
    &:hover {
      background: red;
    }
  }

  &.btn--play {
    background: #FF2F00;

    &:hover {
      background: #000;
    }

    .btn__icon-container {
      &:before {
        width: 10px;
        height: 11px;
        background-image: url("../static/svg/utils/play-xs.svg");
      }

      &.playing:before {
        width: 10px;
        height: 11px;
        background-image: url("../static/svg/utils/pause.svg");
      }

    }

    .btn__label {
      color: #F6F7F9;
    }

    .btn__duration {
      color: #FFFFFF;
    }
  }

  &.btn--play.btn--visual:hover {
    background: #FFFFFF;

    .btn__duration,
    .btn__label {
      color: #FF2F00;
    }

    .btn__icon-container {
      &:before {
        background-image: url("../static/svg/utils/play-xs-orange.svg")
      }

      &.playing:before {
        background-image: url("../static/svg/utils/pause-active.svg");
      }
    }
  }

  &.btn--share {

    @include xl-up {
      width: 65px;
    }

    @include xxxl-up {
      width: 90px
    }

    .btn__icon-container {
      margin-right: 0px;

      &:before {
        width: 15px;
        height: 13px;
        background-image: url("../static/svg/utils/share.svg");
      }
    }

    &.btn--playlist-white {
      .btn__icon-container {
        &:before {
          background-image: url("../static/svg/utils/share.svg");
        }
      }
    }

    &.btn--playlist-black-desktop {
      .btn__icon-container {
        &:before {
          @include l-up {
            background-image: url("../static/svg/utils/share.svg");
          }
        }
      }
    }
  }

  &.btn--playlist {

    @include xl-up {
      width: 65px;
    }

    @include xxxl-up {
      width: 90px;
    }

    .btn__icon-container {
      margin-right: 0px;

      &:before {
        width: 20px;
        height: 13px;
        background-image: url("../static/svg/utils/add-to-playlist.svg");
      }
    }

    &.btn--playlist-white {
      .btn__icon-container {

        &:before {
          background-image: url("../static/svg/utils/add-to-playlist-white.svg");
        }
      }
    }

    &.btn--playlist-light {
      background:#FFFFFF;
      border-color: #FFFFFF;
      box-shadow: 0 3px 7px 0 rgba(0,0,0,0.10);

      .btn__icon-container {
        &:before {
          background-image: url("../static/svg/utils/add-to-playlist.svg");
        }
      }
    }

    &.btn--playlist-black-desktop {
      .btn__icon-container {
        &:before {
          @include l-up {
            background-image: url("../static/svg/utils/add-to-playlist.svg");
          }
        }
      }
    }
  }

  &.btn--search {
    background-color: transparent;
    padding: 0;

    .btn__icon-container {
      margin-right: 0px;

      &:before {
        background-image: url("../static/svg/utils/arrow-right.svg")
      }
    }

    @include l-up {
      background-color: #FF2F00;

      .btn__icon-container {
        &:before {
          background-image: url("../static/svg/utils/search-icon-white.svg");
        }
      }
    }
  }


  &.btn--borders {
    box-shadow: none;
    border: 2px solid #DADADA;

    &:hover {
      background: rgba(236, 236, 236, 0.6);
      border: 2px solid rgba(236, 236, 236, 0.7);

      .btn__label {
        color: #FFF;
      }
    }

    .btn__label {
      color: #DADADA;
    }

    &.btn--white {

      &:hover {
        background: #FFF;
        border: 2px solid #FFF;

        .btn__label {
          color: #FF2F00;
        }
      }

      .btn__label {
        color: #FFF;
      }

    }

    &.btn--playlist-light {
      background: #FFFFFF;
      border-color: #FFFFFF;
      &:hover {
        .btn__icon-container {
          &:before {
            background-image: url("../static/svg/utils/add-to-playlist-active.svg");
          }
        }
      }
    }
  }

  &.btn--borders.btn--borders-orange {
    box-shadow: none;
    border: 2px solid #FF2F00;

    &:hover {
      background: #FF2F00;

      .btn__label {
        color: #FFF;
      }
    }

    .btn__label {
      color: #FF2F00;
    }
  }

  &.btn--facebook {
    color: #FFF;
    background-color: #3B5998;

    &:hover {
      background: rgb(37, 58, 104);
    }

    .btn__icon-container {
      &:before {
        width: 7px;
        height: 17px;
        background-image: url("../static/svg/socials/facebook-white.svg");
      }
    }
  }

  &.btn--twitter {
    color: #FFF;
    background-color: #6FAEDC;

    &:hover {
      background: rgb(70, 119, 153);
    }

    .btn__icon-container {
      &:before {
        width: 15px;
        height: 12px;
        background-image: url("../static/svg/socials/twitter-white.svg");
      }
    }
  }

  &.btn--mail {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 15px;
        height: 12px;
        background-image: url("../static/svg/socials/mail-icon.svg");
      }
    }
  }

  &.btn--copy {
    .btn__icon-container {
      &:before {
        width: 18px;
        height: 11px;
        background-image: url("../static/svg/utils/link.svg");
      }
    }
  }

  &.btn--dl {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 15px;
        height: 12px;
        background-image: url("../static/svg/socials/dl-icon.svg");
      }
    }
  }

  &.btn--itunes {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 20px;
        height: 21px;
        background-image: url("../static/svg/socials/itunes-icon.svg");
      }
    }
  }

  &.btn--spotify {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 24px;
        height: 24px;
        background-image: url("../static/svg/socials/spotify-icon.svg");
      }
    }
  }

  &.btn--deezer {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 34px;
        height: 19px;
        background-image: url("../static/svg/socials/deezer-icon.svg");
      }
    }
  }

  &.btn--rss {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 17px;
        height: 18px;
        background-image: url("../static/svg/socials/rss-icon.svg");
      }
    }
  }

  &.btn--google {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 20px;
        height: 20px;
        background-image: url("../static/svg/socials/google-icon.svg");
      }
    }
  }

  &.btn--stitcher {
    color: #FFF;

    .btn__icon-container {
      &:before {
        width: 32px;
        height: 15px;
        background-image: url("../static/svg/socials/stitcher-icon.svg");
      }
    }
  }

  &.btn--plus {
    color: #FFF;
    padding: 13px 38px;

    .btn__icon-container {
      margin-right: 0px;
      &:before {
        width: 12px;
        height: 12px;
        background-image: url("../static/svg/utils/plus.svg");
      }
    }
  }

  &--light {
    &.btn {
      &:hover {
        background: #ffffff;
        color: #FF2F00;
      }

      &--itunes {
        &:hover {
          .btn__icon-container:before {
            background-image: url("../static/svg/socials/itunes.svg");
          }
        }
      }
      &--spotify {
        &:hover {
          .btn__icon-container:before {
            background-image: url("../static/svg/socials/spotify.svg");
          }
        }
      }
      &--deezer {
        &:hover {
          .btn__icon-container:before {
            background-image: url("../static/svg/socials/deezer.svg");
          }
        }
      }
      &--rss {
        &:hover {
          .btn__icon-container:before {
            background-image: url("../static/svg/socials/rss.svg");
          }
        }
      }
      &--plus {
        &:hover {
          .btn__icon-container:before {
            background-image: url("../static/svg/utils/plus-orange.svg");
          }
        }
      }
    }
  }
}

.btn-sharing {
  white-space: nowrap;
  padding-right: 25px;
  text-align: center;
  text-decoration: none;

  @include xxs-down {
    padding-right: 15px;
  }

  &__img {
    width: 88px;
    height: 88px;
    border-radius: 88px;
    display: flex;
    margin-bottom: 10px;

    @include xxs-down {
      width: 78px;
      height: 78px;
      border-radius: 78px;
    }

    &:before {
      content: '';
      margin: auto;
    }
  }

  &--copy {
    .btn-sharing__img {
      background:#FF2F00;
      &:before {
        width: 40px;
        height: 40px;
        background-image: url("../static/svg/socials/copy-icon.svg");
        background-size: 40px 40px;

        @include xxs-down {
          width: 30px;
          height: 30px;
          background-size: 30px 30px;
        }
      }
    }
  }

  &--twitter {
    .btn-sharing__img {
      background:#6FAEDC;
      &:before {
        width: 27px;
        height: 22px;
        background-image: url("../static/svg/socials/tw-icon.svg");
        background-size: 27px 22px;
      }
    }
  }

  &--facebook {
    .btn-sharing__img {
      background: #3B5998;
      &:before {
        width: 11px;
        height: 21px;
        background-image: url("../static/svg/socials/fb-icon.svg");
        background-size: 11px 21px;
      }
    }
  }

  &--whatsapp {
    .btn-sharing__img {
      background: #45CC55;
      &:before {
        width: 30px;
        height: 30px;
        background-image: url("../static/svg/socials/whatsapp-icon.svg");
        background-size: 30px 30px;
      }
    }
  }

  &__text {
    @include AdelleSansSemiBold();
    font-size: 15px;
    line-height: 28px;
    color: #353535;

    @include xxs-down{
      font-size: 13px;
    }
  }
}
