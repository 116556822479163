.newsletter {
  display: flex;
  background-color: #F6F7F9;
  border-radius: 5px;

  @include l-down{
    flex-direction: column;
  }
}

.newsletter-image {
  margin: auto 40px;

  @include l-down{
    margin: 30px auto;
  }
}

.newsletter-subscription {
  padding: 0;

  @include l-up {
    padding: 40px 40px 40px 0;
    margin-bottom: 130px;
  }

  @include m-up {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
  }  
  
  form {
    margin-top: 30px;
  }

  input, button {
    margin: 20px 0;
    
    &[type=button]{
      border: none;
      color: white;
      @include AdelleSansBold();
      font-size: 15px;
      text-align: center;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .error, .result {
    color: #FF2F00;
    font-weight: bold;
  }
}

.newsletter-subscription__headline {
  .headline__title {
    margin: 5px 0;
    padding: 0;
    @include AdelleSansBold();
    font-size: 26px;
    color: #FF2F00;
    letter-spacing: 0.33px;
    text-align: left;
    line-height: 20px;
    text-transform: uppercase;

    @include l-up {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.49px;
    }
    
    @include l-down{
      padding-bottom: 30px;
      text-align: center;
    }
  }

  .headline__subtitle {
    padding: 0;
    @include AdelleSans();
    font-size: 14px;
    color: #353535;
    text-align: left;
    line-height: 20px;
  }
}

.newsletter-subscription__field {
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
  }

  input {
    width: 60%;
    margin: 10px 0 20px 0;
    @include newsletter-input-border-background();
    border-radius: 10px;
    
    @include l-down{
      width: 100%;
    }
  }
}

.newsletter-subscription__checkbox {
  display: flex;
  align-items: center;

  input {
    @include newsletter-input-border-background();
    border-radius: 5px;

    &:checked {
      background-color: #FF2F00;
    }
  }

  label {
    line-height: 20px;
  }
}

#newsletter_submit {
  margin: 30px 0 0 0;
  border-radius: 10px;
  
  @include l-down{
    margin: 40px auto;
  }
}
