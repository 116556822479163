.js-tabs {
  max-width: 100%;
}
.js-tabs__header {
  display: flex;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 30px;
}

.js-tabs__header li {
  flex-grow: 1;
  text-align: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;

  &:not(:last-child):after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 25px;
    background-color: #000;
  }
}

.js-tabs__title {
  cursor: pointer;
  text-decoration: none;
  @include AdelleSansSemiBold();
  font-size: 17px;
  color: #000000;
  line-height: 20px;
  transition: all 0.3s $easeOutExpo;
  display: block;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
  }
}
.js-tabs__title:not(.js-tabs__title-active):hover {
  text-decoration: none;
  color: #353535;
}
.js-tabs__title-active {
  color: #FF2F00;
  letter-spacing: 0;
  line-height: 20px;

  &:before {
    background-color: #FF2F00;
  }
}


.tabs {
  position: relative;
  display: flex;
  overflow: scroll;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  padding: 25px 15px 0px;

  @include xxs-up {
    padding: 25px 25px 0px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:after {
    content: '';
    padding-right: 25px;
  }

  @include l-up {
    display: none;
  }

  .tab {
    @include AdelleSansSemiBold();
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-left: 30px;
    padding-bottom: 10px;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0px;
    }

    &--current {
      border-bottom: solid 4px #FF2F00;
    }
  }
}
