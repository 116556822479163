.podcast-list {
  position: relative;
  border-bottom: 1px solid #DADADA;
  transition: background 0.3s $easeOutExpo;

  &:hover {
    @include l-up {
      background: #FFF;

      .podcast-list__serie,
      .podcast-list__title {
        text-decoration: underline;
      }
    }
  }


  .podcast-list__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .podcast-list__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    position: relative;

    @include xxs-down {
      padding: 30px 0;
    }

    @include xl-up {
      padding: 40px 0;
    }

  }

  .podcast-list__infos {
    @include m-down {
      margin-left: 0;
      margin-right: auto;
    }
    @include m-up {
      display: flex;
      flex: 1;
    }
  }

  .podcast-list__serie {
    @include AdelleSansExtraBold();
    font-size: 17px;
    letter-spacing: 0.3px;
    line-height: 20px;
    color: #353535;
    margin-bottom: 4px;

    @include m-up {
      flex: 1;
    }

    @include l-up {
      font-size: 20px;
      margin-bottom: 0px;
    }

    @include xxxl-up {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.39px;
      margin-right: 15px;
    }
  }

  .podcast-list__title {
    @include AdelleSansSemiBold();
    font-size: 15px;
    line-height: 20px;
    color: #353535;

    @include m-up {
      flex: 2;
    }

    @include l-up {
      font-size: 18px;
    }

    @include xxxl-up {
      font-size: 22px;
      line-height: 26px;
    }
  }


  .podcast-list__buttons {
    display: flex;
    margin-left: 10px;
    align-self: center;

    @include xxs-down {
      align-items: flex-start;
      flex-direction: column;
    }

    .btn--play {
      margin-right: 7px;

      @include xxs-down {
        margin-right: 0;
        margin-bottom: 9px;
      }

      @include l-up {
        width: 115px;
        margin-right: 15px;
      }
    }
  }
}


.podcast-list.podcast-list--in-selected {
  border-bottom: 1px solid rgba(#FFFFFF, 0.2);

  &:hover {
    background: none;
  }

  @include l-down {
    &:first-child {
      border-top: 1px solid rgba(#FFFFFF, 0.2);
    }
  }

  @include l-up {
    border-bottom: 1px solid #DADADA;
    max-width: calc(100% - 170px);
    margin:auto;
  }

  @include xl-up {
    max-width: 900px;
  }

  @include xxl-up {
    max-width: 1000px;
  }

  @include xxxl-up {
    max-width: 1100px;
  }

  @include xxxxl-up {
    max-width: 1500px;
  }



  .podcast-list__serie {
    color: #FFFFFF;

    @include l-up {
      color: #353535;
    }
  }

  .podcast-list__title {
    color: #FFFFFF;

    @include l-up {
      color: #353535;
    }
  }
}
