.embed-sharings {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #F6F7F9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;

  &__title {
    @include AdelleSansBold();
    font-size: 14px;
    line-height: 16px;
    color: #FF2F00;
    text-align: center;
    margin-bottom: 20px;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    @include s-down {
      justify-content: flex-start;
      padding: 0 20px;
      overflow-x: scroll;

      &:after {
        content: '';
        padding-right: 20px;
        display: block;
        width: 20px;
        height: 20px;
      }
    }

    .btn {
      @include xxs-down {
        padding: 5px 14px;
      }

      @include s-down {
        flex: 0 0 auto;
        box-shadow: none;
      }

      margin-right: 10px;

      @include m-up {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .btn--twitter {
      font-size: 5px;
    }
  }
}
