.cover-episodes {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  background-color: #1C1C1C;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;

  @include m-up {
    padding-bottom: 130px;
  }

  @include l-up {
    padding-top: 80px;
    padding-bottom: 150px;
  }

  @include xxxl-up {
    padding-top: 130px;
    padding-bottom: 200px;
  }

  @include xxxxxl-up {
    padding-bottom: 230px;
  }

  .cover-episodes__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .cover-episodes__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: blur(50px);

    @include m-up {
      filter: none;
    }


    &:after {
      content: "";
      position: absolute;
      top: 80%;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 1) 100%);
      height: 100%;
      width: 100%;
      transform: translateY(-100%);


      @include l-up {
        top: 102%;
        height: 100%;
        background-image: linear-gradient(-180deg, rgba(28, 28, 28, 0) 2%, rgba(28, 28, 28, 0.75) 100%);
      }
    }

    .cover-episodes__image-wrapper {
      height: 80%;
      overflow:hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include l-up {
        height: 100%;
      }
    }
  }

  .cover-episodes__image {
    opacity: 0.6;
    @include object-fit();

    @include l-up {
      height: 100%;
    }
  }

  .cover-episodes__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 50px);
  }

  .cover-episodes__illustration-container {
    box-shadow: 0 6px 64px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 110px);
    margin: auto;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-bottom: 77.5%;
      height: 0;
    }

    @include s-up {
      width: calc(100% - 250px);
    }

    @include m-up {
      margin: 0;
      width: calc(45% - 50px);
    }

    @include xxxxxl-up {
      width: calc(40% - 50px);
    }

    .cover-episodes__illustration {
      border-radius: 5px;
      @include object-fit();
    }
  }

  .cover-episodes__information {
    text-align: center;
    margin: 20px auto 0;
    width: calc(100%);
    display: flex;
    flex-direction: column;

    @include m-up {
      text-align: left;
      margin-left: 60px;
      width: 40%;
      align-self: flex-start;
    }

    @include xxxxxl-up {
      margin-top: 30px;
      margin-left: 120px;
      width: 40%;
    }
  }

  .cover-episodes__serie {
    order: 0;
    .serie__episode {
      margin-left: 5px;
    }
  }

  .cover-episodes__title {
    order: 1;
    margin-bottom: 10px;

    @include l-up {
      margin-bottom: 0px;
      max-width: 500px;
    }

    @include xxxl-up {
      max-width: 670px;
    }
  }

  .cover-episodes__authors {
    order: 2;
    z-index: 2;

    @include xxxl-up {
      font-size: 15px;
      line-height: 25px;
    }

  }

  .cover-episodes__chapo {
    order: 4;
  }

  .cover-episodes__description {
    @include AdelleSansSemiBold();;
    color: #F6F7F9;
    font-size: 15px;
    line-height: 24px;

    @include xxs-up {
      font-size: 17px;
      line-height: 28px;
    }

    @include l-up {
      font-size: 18px;
      line-height: 28px;
      text-shadow: 0 1px 11px rgba(0,0,0,0.50);
      max-width: 450px;
    }

    @include xxxl-up {
      max-width: 670px;
      font-size: 20px;
      line-height: 32px;
    }
  }

  .cover-episodes__separator {
    order: 3;
    margin: 20px auto;

    @include m-up {
      margin: 20px 0;
    }

    @include xxxxxl-up {
      margin: 30px 0;
    }
  }

  .cover-episodes__buttons {
    order: 5;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    @include m-up {
      justify-content: flex-start;
    }

    @include xxxxxl-up {
      margin-top: 40px;
    }
  }
}
