$xxs: 375px;
$xs: 410px;
$s: 500px;
$ms: 550px;
$m: 768px;
$l: 1024px;
$xl: 1280px;
$xxl: 1440px;
$xxxl: 1660px;
$xxxxl: 1921px;
$xxxxxl: 2160px;

@mixin xxs-up {
  @media (min-width: #{$xxs}) {
    @content;
  }
}

@mixin xxs-down {
  @media (max-width: #{$xxs - 1}) {
    @content;
  }
}

@mixin xs-up {
  @media (min-width: #{$xs}) {
    @content;
  }
}

@mixin xs-down {
  @media (max-width: #{$xs}) {
    @content;
  }
}

@mixin s-down {
  @media (max-width: #{$s - 1}) {
    @content;
  }
}

@mixin s-up {
  @media (min-width: #{$s}) {
    @content;
  }
}

@mixin ms-down {
  @media (max-width: #{$ms - 1}) {
    @content;
  }
}

@mixin ms-up {
  @media (min-width: #{$ms}) {
    @content;
  }
}

@mixin m-down {
  @media (max-width: #{$m - 1}) {
      @content;
  }
}

@mixin m-up {
    @media (min-width: #{$m}) {
        @content;
    }
}

@mixin l-down {
    @media (max-width: #{$l - 1}) {
        @content;
    }
}

@mixin l-up {
    @media (min-width: #{$l}) {
        @content;
    }
}

@mixin xl-up {
    @media (min-width: #{$xl}) {
        @content;
    }
}

@mixin xxl-up {
    @media (min-width: #{$xxl}) {
        @content;
    }
}

@mixin xxxl-down {
  @media (max-width: #{$xxxl}) {
      @content;
  }
}

@mixin xxxl-up {
    @media (min-width: #{$xxxl}) {
        @content;
    }
}

@mixin xxxxl-up {
    @media (min-width: #{$xxxxl}) {
        @content;
    }
}

@mixin xxxxxl-up {
  @media (min-width: #{$xxxxxl}) {
      @content;
  }
}
