.arrow-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include m-down {
    display: none;
  }

  .arrow {
    position: relative;
    display: flex;
    border: solid 2px #DADADA;
    border-radius: 50px;
    width: 50px;
    max-width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .3s ease-out;
    text-decoration: none;

    &:hover {
      width: auto;
      max-width: 350px;
      background: white;
      border: solid 2px #FFFFFF;

      .arrow__content {
        opacity: 1;
        visibility: visible;
      }
    }

    &--prev {
      position: absolute;
      left: 30px;

      .arrow__img-container {
        left: -2px;
      }

      .arrow__content {
        margin: 0 25px 0 50px;
      }
    }

    &--next {
      position: absolute;
      right: 30px;

      .arrow__img-container {
        right: -2px;
      }

      .arrow__content {
        margin: 0 50px 0 25px;
      }
    }

    .arrow__img-container {
      position: absolute;
      top: -2px;
      bottom: 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content  {
      opacity: 0;
      visibility: hidden;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: opacity .5s ease-out;
      transition-delay: 200ms;
    }

    .content__episode {
      @include AdelleSansSemiBold();
      color: #484848;
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .content__title {
      @include AdelleSansBold();
      color: #FF2F00;
      font-size: 16px;
    }

  }
}
